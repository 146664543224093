import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { handleGetResponse } from "./commonService";
import { toast } from "sonner";
import { handleApiError } from "../../components/helper/common-function";

// Action to get company users
export const actionGetCompanyUsers = createAsyncThunk(
  "actionGetCompanyUsers",
  async ({ offset, search, orderByColumn, order },{rejectWithValue}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/company-users?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      rejectWithValue(error.message);
      return handleApiError(error);
    }
  }
);

// Action for Add/invite users Profile
export const actionAddUser = createAsyncThunk(
  "actionAddUser",
  async ({ req, form, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/send-invitation`,
        { data: req }
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        navigate && navigate("/users");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      rejectWithValue(error.message);
    }
  }
);

export const actionClearUsersAllData = createAsyncThunk(
  "actionClearUsersAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//  Action to get All company users
export const actionGetCompanyAllUsers = createAsyncThunk(
  "actionGetCompanyAllUsers",
  async () => {
    return handleGetResponse(`company-users?getall=YES`);
  }
);

// Action for Get User DEtail
export const actionGetUserDetail = createAsyncThunk(
  "actionGetUserDetail",
  async (id) => {
    return handleGetResponse(`company-users/${id}`);
  }
);

// Action for Update User
export const actionUpdateUser = createAsyncThunk(
  "actionUpdateUser",
  async ({ req, Id, setType }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/company-users/${Id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setType && setType("VIEW");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// Action for Deactivate users single/multiple
export const actionDeactivateUser = createAsyncThunk(
  "actionDeactivateUser",
  async ({ req, setSelectedRowKeys }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/deactive`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setSelectedRowKeys && setSelectedRowKeys("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      rejectWithValue(error.message);
    }
  }
);
