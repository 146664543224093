import {
  SearchOutlined,
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Typography,
  Upload,
} from "antd";
import gallery from "../../../assets/img/gallery.svg";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusSvg from "../../../assets/img/plus.svg";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import productImage from "../../../assets/img/product-img.svg";
import SortIcon from "../../common/SortIcon";
import Slider from "react-slick";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import { toast } from "sonner";
import { IoMdClose } from "react-icons/io";

const Product = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    getSingleEventTypeData,
    getSingleEventTypeLoader,
    getEventTypeLoader,
    addEventTypeLoader,
    deleteEventTypeLoader,
    updateEventTypeLoader,
  } = useSelector((state) => state.masterData);
  const [search, setSearch] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [products, setProducts] = useState([
    "https://images.unsplash.com/photo-1484807352052-23338990c6c6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b25saW5lfGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG9ubGluZXxlbnwwfHwwfHx8MA%3D%3D",
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: 100,
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product Image",
      dataIndex: "product_image",
      key: "2",
      width: 200,
      ellipsis: true,
      render : (text) => <Image  src={text} alt="Product Image" width={40} height={40} className="rounded-full"/> 
    },
    {
      title: "Product Type",
      dataIndex: "product_type",
      key: "3",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.product_type.length - b.product_type.length,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Product Description",
      dataIndex: "description",
      key: "4",
      width: 300,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.description - b.description,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Base Unit of Measure",
      dataIndex: "unit",
      key: "5",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.unit - b.unit,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "6",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.brand - b.brand,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Product Group",
      dataIndex: "product_group",
      key: "7",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.product_group - b.product_group,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },

    {
      title: "Product Category",
      dataIndex: "product_category",
      key: "7",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.product_category - b.product_category,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Standard Weight Unit",
      dataIndex: "standard_weight_unit",
      key: "7",
      width: 200,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => a.standard_weight_unit - b.standard_weight_unit,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Action",
      key: "7",
      width: 150,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div className="flex items-center ">
            <div className="flex items-center gap-x-2 justify-center">
              <Typography.Link
                disabled={editingKey !== ""}
                //  onClick={() => edit(record)}
              >
                <img src={editevent} alt="" />
              </Typography.Link>
            </div>

            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteEventTypeLoader[record.event_type_master_id],
                }}
                description="Are you sure you want to delete?"
                // onConfirm={() =>
                //   dispatch(
                //     actionDeleteEventTypeMaster({
                //       id: record.event_type_master_id,
                //       setEditingKey,
                //     })
                //   )
                // }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const data = [];
  const placeholderImageBaseURL =
    "https://via.placeholder.com/150?text=Product+Image+";
  for (let i = 1; i <= 60; i++) {
    data.push({
      srno: i,
      product_image: `${placeholderImageBaseURL}${i}`,
      product_type: `Product Type ${i}`,
      description: `Product Description ${i}`,
      unit: `Base Unit ${i}`,
      brand: `Brand ${i}`,
      product_group: `Product Group ${i}`,
      product_category: `Product Category ${i}`,
      standard_weight_unit: `Standard Weight Unit ${i}`,
      operation: {},
    });
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };
  const typeSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const price1 = [
    {
      label: "price-1",
      value: "price1",
    },
  ];

  const productProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    showUploadList: false,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading");
        setLoading(true);
      } else {
        if (status === "done") {
          setProducts([info.file.response.url, ...products]); // Assuming response contains the uploaded file URL
          setLoading(false);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          setLoading(false);
          toast.error(`File upload failed.`);
        }
        setLoading(false);
      }
    },
    onDrop(e) {},
  };

  const handleRemoveProduct = (indexToRemove) => {
    const updatedProducts =
      products &&
      products.length > 0 &&
      products.filter((_, index) => index !== indexToRemove);
    setProducts(updatedProducts);
  };

  // Example array with defined types
  const productTypeData = [
    {
      id: "1",
      type: "Conference",
    },
    {
      id: "2",
      type: "Workshop",
    },
  ];

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">Product</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Product
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={1200}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={productImage} alt="" />
              <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
                Add Product
              </h1>
            </div>
            <Form
              form={form}
              // onFinish={handleAddEventType}
              layout="vertical"
              className="mt-[20px] px-1 mb-0"
            >
              <Row gutter={16}>
                <Col span={9}>
                  <Card
                    className=" my-5"
                    title={
                      <h1 className="font-popinsSemiBold">Product Image</h1>
                    }
                  >
                    <div className="slider-container">
                      {
                        <Slider {...settings}>
                          {products.length < 4 && (
                            <div className="mr-3 rounded-xl min-w-40 max-w-40 h-[150px] cursor-pointer">
                              <Upload.Dragger
                                accept=".png,.jpg,.jpeg"
                                {...productProps}
                                className="attendee-dragger flex items-center justify-center"
                                height={150}
                              >
                                <div className="flex items-center gap-x-5 justify-center">
                                  <Image
                                    src={gallery}
                                    preview={false}
                                    alt="Gallery"
                                  />
                                  <p className="text-left text-[10px]">
                                    Drop your File here or select <br />
                                    <span className="text-brand">
                                      Click to Browse
                                    </span>
                                  </p>
                                </div>
                              </Upload.Dragger>
                            </div>
                          )}

                          {products &&
                            products.map((data, index) => (
                              <div
                                key={index}
                                className={`mr-3 group rounded-xl relative border border-gray min-w-40 max-w-40 h-[150px] cursor-pointer`}
                                onClick={() => (isDragging ? "" : "")}
                                style={{
                                  cursor: isDragging ? "grabbing" : "grab",
                                }}
                              >
                                <img
                                  className="rounded-xl object-cover h-full w-full"
                                  src={data}
                                  alt="product-image"
                                />
                                <div
                                  onClick={() => handleRemoveProduct(index)}
                                  className="bg-red-500 cursor-pointer absolute top-0 right-0 w-[30px] rounded-tr-[9px] rounded-bl-xl h-[30px] flex items-center justify-center"
                                >
                                  <IoMdClose color="white" size={20} />
                                </div>
                              </div>
                            ))}
                        </Slider>
                      }
                    </div>
                  </Card>
                  <Card
                    className="my-5"
                    title={
                      <h1 className="font-popinsSemiBold">Price Information</h1>
                    }
                  >
                    <Form.Item
                      label={`Price Group 1`}
                      name={"price_group_1"}
                      rules={[
                        {
                          required: false,
                          message: `Price Group is required`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Price Group"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={price1}
                      />
                    </Form.Item>
                    <Form.Item
                      label={`Price Group 1`}
                      name={"price_group_1"}
                      rules={[
                        {
                          required: false,
                          message: `Price Group is required`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Price Group"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={price1}
                      />
                    </Form.Item>
                    <Form.Item
                      label={`Price Group 1`}
                      name={"price_group_1"}
                      rules={[
                        {
                          required: false,
                          message: `Price Group is required`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Price Group"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={price1}
                      />
                    </Form.Item>
                    <Form.Item
                      label={`Price Group 1`}
                      name={"price_group_1"}
                      rules={[
                        {
                          required: false,
                          message: `Price Group is required`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Price Group"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={price1}
                      />
                    </Form.Item>
                    <Form.Item
                      label={`Price Group 1`}
                      name={"price_group_1"}
                      rules={[
                        {
                          required: false,
                          message: `Price Group is required`,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Price Group"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={price1}
                      />
                    </Form.Item>
                  </Card>
                  <Card
                    className="description-card"
                    title={
                      <h1 className="font-popinsSemiBold">
                        Product Information
                      </h1>
                    }
                  >
                    <Form.Item name="description">
                      <Input.TextArea
                        variant="borderless"
                        placeholder="Enter description"
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={15}>
                  <Card
                    className="my-5"
                    title={
                      <div className="flex items-center justify-between">
                        <h1 className="font-popinsSemiBold">Product Type</h1>
                        <div
                          className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                          onClick={() => {
                            setIsIndustryPopoverOpen(!isIndustryPopoverOpen);
                          }}
                        >
                          <Image
                            src={plusSvg}
                            alt="image"
                            height={20}
                            width={20}
                            preview={false}
                          />
                          <h1
                            style={{ fontWeight: 400 }}
                            className="text-[14px] ml-1"
                          >
                            Add Type
                          </h1>
                        </div>
                      </div>
                    }
                  >
                    <div className="slider-container product-slider">
                      Product Type
                      <Slider {...typeSettings}>
                        {productTypeData &&
                          productTypeData.map((data, index) => (
                            <div key={index} className="py-3">
                              <div
                                className={`p-3 mr-3 rounded-xl border ${
                                  productType === data.id
                                    ? "border-[#6883FD] bg-custom-gradient"
                                    : "border-[#DDDAE9] bg-[#FCFBFD]"
                                } min-w-90 max-w-90 min-h-24 cursor-pointer`}
                                onClick={() =>
                                  isDragging ? "" : setProductType(data.id)
                                }
                                style={{
                                  cursor: isDragging ? "grabbing" : "grab",
                                }}
                              >
                                <h3 className="font-popinsMedium">
                                  {data.type}
                                </h3>
                                <Typography.Paragraph
                                  ellipsis={{ rows: 2 }}
                                  className="font-popinsRegular text-xs mt-1"
                                >
                                  {data.description}
                                </Typography.Paragraph>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </Card>

                  <Card
                    title={
                      <h1 className="font-popinsSemiBold">
                        Product Information
                      </h1>
                    }
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Product Name">
                          <Input placeholder="Headphone, Shoes" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="ERP Code">
                          <Input placeholder="54584" />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Product Code">
                          <Input placeholder="398-5783" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Category">
                          <Input placeholder="Sport" />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Sub Category">
                          <Input placeholder="Shoes" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Base Unit of Measure">
                          <Select placeholder="Kilogram (KG)">
                            <Option value="kg">Kilogram (KG)</Option>
                            <Option value="lb">Pound (LB)</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Product Group">
                          <Select placeholder="Product Group">
                            <Option value="group1">Group 1</Option>
                            <Option value="group2">Group 2</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Standard Weight">
                          <Input placeholder="Standard Weight" />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Standard Weight Unit">
                          <Select placeholder="Standard Weight Unit">
                            <Option value="unit1">Unit 1</Option>
                            <Option value="unit2">Unit 2</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="HSN/SAC Code">
                          <Select placeholder="4842189651">
                            <Option value="code1">4842189651</Option>
                            <Option value="code2">Another Code</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Batch required">
                          <Radio.Group className="border rounded-[10px] justify-between  border-[#DDDAE9] w-full flex">
                            <Radio
                              value="yes"
                              className=" w-1/2  py-3 border-r m-0 border-[#DDDAE9]  flex items-center justify-center"
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="no"
                              className=" w-1/2 py-3 m-0  flex items-center justify-center"
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Shelf Life Period">
                          <Select placeholder="Shelf Life Period">
                            <Option value="period1">Period 1</Option>
                            <Option value="period2">Period 2</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Shelf Life period indicator">
                          <Radio.Group size="large" buttonStyle="solid">
                            <Radio.Button value="week">Week</Radio.Button>
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <div className="flex justify-end  mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addEventTypeLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={form} component={false}>
          <Table
            virtual
            scroll={{ x: 1500, y: 500 }}
            pagination={false}
            loading={getEventTypeLoader}
            bordered
            dataSource={data}
            rowKey="srno"
            columns={columns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default Product;
