import { createSlice } from "@reduxjs/toolkit";
import { actionGetBeatPlanning } from "../services/salesService";

const salesSlice = createSlice({
  name: "setting",
  initialState: {
    beatPlanData: "",
    allBeatPlanData: "",
    getBeatPlanningLoader: false,
    beatPlansCounts: 0,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // get Role State
      .addCase(actionGetBeatPlanning.pending, (state) => {
        state.getBeatPlanningLoader = true;
      })
      .addCase(actionGetBeatPlanning.fulfilled, (state, action) => {
        state.getBeatPlanningLoader = false;
        state.beatPlanData = action.payload.data;
        state.beatPlansCounts = action.payload.total_records;
      })
      .addCase(actionGetBeatPlanning.rejected, (state) => {
        state.getBeatPlanningLoader = false;
      });
  },
});

export default salesSlice.reducer;
