import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleApiError } from "../../components/helper/common-function";

export const actionGetBeatPlanning = createAsyncThunk(
  "actionGetBeatPlanning",
  async (
    { offset = 0, search = "", orderByColumn = "", order = "" } = {},
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/beat-planning?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      const message = error.response?.data?.message;
      if (Array.isArray(message)) {
        toast.error(message[0]);
      } else if (message && message !== null) {
        toast.error(message);
      } else {
        toast.error(error.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);
