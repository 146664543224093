import {
  DeleteOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Upload,
  Typography,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import attechment from "../../../assets/img/attechment.svg";
import businessPartner from "../../../assets/img/businessPartner.svg";
import editPrimaryWhite from "../../../assets/img/editPrimaryWhite.svg";
import plusSvg from "../../../assets/img/plus.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddBusinessPartnerTypeMaster,
  actionAddIndustryMaster,
  actionAddTypeOfCompanyMaster,
  actionGetBeatMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetIndustryMaster,
  actionGetTypeOfCompanyMaster,
} from "../../../store/services/generalMasterService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import {
  actionAddBusinessPartner,
  actionGetSingleBusinessPartner,
  actionUpdateBusinessPartner,
} from "../../../store/services/masterDataService";
import CustomIconText from "../../common/CustomIconText";
import { extractFilename, renderFileIcon } from "../../helper/common-function";
import axios from "axios";
const { Dragger } = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateBusinessPartner = () => {
  const [form] = Form.useForm();
  const [addBusinessPartnerTypeForm] = Form.useForm();
  const [addIndustryForm] = Form.useForm();
  const [typeofCompanyForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [
    addBusinessPartnerTypePopoverOpen,
    setAddBusinessPartnerTypePopoverOpen,
  ] = useState(false);
  const [addIndustryPopoverOpen, setAddIndustryPopoverOpen] = useState(false);
  const [contractFile, setContractFile] = useState([]);
  const [otherCertificates, setOtherCertificates] = useState([]);
  const [gstnCertificate, setGstnCertificate] = useState("");
  const [panCard, setPanCard] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState("");
  const [msmeDetail, setMsmeDetail] = useState("");
  const [countrySelected, setCountrySelected] = useState(false);
  const [BusinessPartnerLogoPicUrl, setBusinessPartnerLogoPicUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [addTypeofCompanyPopoverOpen, setAddTypeofCompanyPopoverOpen] =
    useState(false);

  const { countries, states, getCountryLoader, statesLoader } = useSelector(
    (state) => state.location
  );
  const { businessPartnerId } = location.state || {};

  const {
    addBusinessPartnerLoader,
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
    updateBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);
  const {
    getIndustryLoader,
    addIndustryLoader,
    getBusinessPartnerTypeLoader,
    addBusinessPartnerTypeLoader,
    businessPartnerTypeData,
    industryData,
    getTypeOfCompanyLoader,
    typeOfCompanyData,
    addTypeOfCompanyLoader,
    BeatMasterData,
    getBeatMasterLoader,
  } = useSelector((state) => state.generalMaster);

  // get state and country
  useEffect(() => {
    dispatch(actionGetCountries());
    dispatch(actionGetIndustryMaster());
    dispatch(actionGetBusinessPartnerTypeMaster());
    dispatch(actionGetTypeOfCompanyMaster());
    dispatch(actionGetBeatMaster());

    if (businessPartnerId) {
      dispatch(actionGetSingleBusinessPartner(businessPartnerId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (businessPartnerDetail?.country?.id) {
      dispatch(actionGetStates(businessPartnerDetail?.country?.id));
    }
  }, [businessPartnerDetail]);

  useEffect(() => {
    if (businessPartnerId && businessPartnerDetail) {
      form.setFieldsValue({
        business_partner_name: businessPartnerDetail.business_partner_name,
        business_partner_type_master_id:
          businessPartnerDetail.business_partner_type
            ?.business_partner_type_master_id,
        email: businessPartnerDetail.email
          ? businessPartnerDetail.email
          : undefined,
        mobile: businessPartnerDetail.mobile
          ? businessPartnerDetail.mobile
          : undefined,
        address_1: businessPartnerDetail.address_1
          ? businessPartnerDetail.address_1
          : undefined,
        address_2: businessPartnerDetail.address_2
          ? businessPartnerDetail.address_2
          : undefined,
        country_id:
          businessPartnerDetail.country && businessPartnerDetail.country?.id
            ? businessPartnerDetail.country?.id
            : undefined,
        state_id:
          businessPartnerDetail.state && businessPartnerDetail.state?.id
            ? businessPartnerDetail.state?.id
            : undefined,
        city: businessPartnerDetail.city
          ? businessPartnerDetail.city
          : undefined,
        zip_code: businessPartnerDetail.zip_code
          ? businessPartnerDetail.zip_code
          : undefined,
        gst_number: businessPartnerDetail.gst_number
          ? businessPartnerDetail.gst_number
          : undefined,
        pan_card_no: businessPartnerDetail.pan_card_no
          ? businessPartnerDetail.pan_card_no
          : undefined,
        msme_number: businessPartnerDetail.msme_number
          ? businessPartnerDetail.msme_number
          : undefined,
        fssai: businessPartnerDetail.fssai
          ? businessPartnerDetail.fssai
          : undefined,
        cin_no: businessPartnerDetail.cin_no
          ? businessPartnerDetail.cin_no
          : undefined,
        type_of_company_id: businessPartnerDetail.type_of_company_id
          ? businessPartnerDetail.type_of_company_id
          : undefined,
        industry_master_id:
          businessPartnerDetail?.industry &&
          businessPartnerDetail?.industry?.industry_master_id
            ? businessPartnerDetail?.industry?.industry_master_id
            : undefined,
        beat_master_id:
          businessPartnerDetail?.beat_master &&
          businessPartnerDetail?.beat_master?.beat_master_id
            ? businessPartnerDetail?.beat_master?.beat_master_id
            : undefined,
        company_size: businessPartnerDetail.company_size
          ? businessPartnerDetail.company_size
          : undefined,
        revenue: businessPartnerDetail.revenue
          ? businessPartnerDetail.revenue
          : undefined,
        sales_volume: businessPartnerDetail.sales_volume
          ? businessPartnerDetail.sales_volume
          : undefined,
        agreement_duration: businessPartnerDetail.agreement_duration
          ? businessPartnerDetail.agreement_duration
          : undefined,
        commission_structure: businessPartnerDetail.commission_structure
          ? businessPartnerDetail.commission_structure
          : undefined,
        terms_and_conditions: businessPartnerDetail.terms_and_conditions
          ? businessPartnerDetail.terms_and_conditions
          : undefined,
        feedback_mechanism: businessPartnerDetail.feedback_mechanism
          ? businessPartnerDetail.feedback_mechanism
          : undefined,
        renewal_process: businessPartnerDetail.renewal_process
          ? businessPartnerDetail.renewal_process
          : undefined,
        termination_conditions: businessPartnerDetail.termination_conditions
          ? businessPartnerDetail.termination_conditions
          : undefined,
        bank_name: businessPartnerDetail.bank_name
          ? businessPartnerDetail.bank_name
          : undefined,
        branch_name: businessPartnerDetail.branch_name
          ? businessPartnerDetail.branch_name
          : undefined,
        account_type: businessPartnerDetail.account_type
          ? businessPartnerDetail.account_type
          : undefined,
        ifsc: businessPartnerDetail.ifsc
          ? businessPartnerDetail.ifsc
          : undefined,
        account_number: businessPartnerDetail.account_number
          ? businessPartnerDetail.account_number
          : undefined,
        primary_contact_name: businessPartnerDetail.primary_contact_name
          ? businessPartnerDetail.primary_contact_name
          : undefined,
        primary_contact_email: businessPartnerDetail.primary_contact_email
          ? businessPartnerDetail.primary_contact_email
          : undefined,
        primary_contact_phone: businessPartnerDetail.primary_contact_phone
          ? businessPartnerDetail.primary_contact_phone
          : undefined,
        secondary_contact_name: businessPartnerDetail.secondary_contact_name
          ? businessPartnerDetail.secondary_contact_name
          : undefined,
        secondary_contact_email: businessPartnerDetail.secondary_contact_email
          ? businessPartnerDetail.secondary_contact_email
          : undefined,
        secondary_contact_phone: businessPartnerDetail.secondary_contact_phone
          ? businessPartnerDetail.secondary_contact_phone
          : undefined,
      });
      if (businessPartnerDetail?.logo) {
        setBusinessPartnerLogoPicUrl(businessPartnerDetail?.logo);
      }
      if (businessPartnerDetail?.gstn_certificate_attachment !== null) {
        setGstnCertificate(businessPartnerDetail?.gstn_certificate_attachment);
      }
      if (businessPartnerDetail?.pan_card_attachment !== null) {
        setPanCard(businessPartnerDetail?.pan_card_attachment);
      }

      if (businessPartnerDetail?.cancelled_cheque_attachment !== null) {
        setCancelledCheque(businessPartnerDetail.cancelled_cheque_attachment);
      }

      if (businessPartnerDetail?.msme_details_attachment !== null) {
        setMsmeDetail(businessPartnerDetail.msme_details_attachment);
      }

      const contractAttachmentArray = [];
      const otherCertificateAttachmentArray = [];

      businessPartnerDetail &&
        businessPartnerDetail.other_certificates_attachments &&
        businessPartnerDetail.other_certificates_attachments.length > 0 &&
        businessPartnerDetail.other_certificates_attachments.map(
          (data, index) => {
            otherCertificateAttachmentArray.push({
              id: index,
              url: data,
              name: extractFilename(data),
            });
          }
        );

      businessPartnerDetail &&
        businessPartnerDetail.contract_attachements &&
        businessPartnerDetail.contract_attachements.length > 0 &&
        businessPartnerDetail.contract_attachements.map((data, index) => {
          contractAttachmentArray.push({
            id: index,
            url: data,
            name: extractFilename(data),
          });
        });

      setContractFile(contractAttachmentArray);
      setOtherCertificates(otherCertificateAttachmentArray);

      if (businessPartnerDetail?.country?.id) {
        setCountrySelected(true);
      }
    }
  }, [form, businessPartnerId, businessPartnerDetail]);

  const handleRemoveContact = (file) => {
    const updatedList = contractFile.filter((item) => item.uid !== file.uid);
    setContractFile(updatedList);
  };

  const contractProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    fileList: contractFile,
    showUploadList: true,
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setContractFile(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: handleRemoveContact,

    onDrop(e) {
      console.log("Files dropped:", e.dataTransfer.files);
    },
  };

  const handleRemoveCertificates = (file) => {
    // Filter out the file from the fileList
    const updatedList = otherCertificates.filter(
      (item) => item.uid !== file.uid
    );
    setOtherCertificates(updatedList);
  };

  const otherCertificatesProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    fileList: otherCertificates,
    showUploadList: true,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setOtherCertificates(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: handleRemoveCertificates,
    onDrop(e) {},
  };

  const gstnCertificateProps = {
    name: "file",
    multiple: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading");
      } else {
        if (status === "done") {
          setGstnCertificate(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setGstnCertificate(""),
    onDrop(e) {},
  };

  const panCardProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading");
      } else {
        if (status === "done") {
          setPanCard(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setPanCard(""),
    onDrop(e) {},
  };

  const cancelledChequeProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        setCancelledCheque(info.file.response.url);
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: () => setCancelledCheque(""),
    onDrop(e) {},
  };

  const msmeProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    showUploadList: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading");
      } else {
        if (status === "done") {
          setMsmeDetail(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setMsmeDetail(""),
    onDrop(e) {},
  };

  const handleChangeBusinessPartnerLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setBusinessPartnerLogoPicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // add Business partner type
  const handleCancelAddBusinessPartnerTypePopover = () => {
    setAddBusinessPartnerTypePopoverOpen(false);
    addBusinessPartnerTypeForm.resetFields();
  };

  const showAddBusinessPartnerTypePopoverOpen = () => {
    setAddBusinessPartnerTypePopoverOpen(true);
  };

  const addBusinessPartnerTypePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addBusinessPartnerTypeForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartnerTypeMaster({
                values,
                handleCancel: handleCancelAddBusinessPartnerTypePopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_type_name"
            label="Business Partner type name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input business partner type!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter business partner type name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            className="mb-[12px]"
            size="small"
          >
            <Input.TextArea
              style={{ background: "white" }}
              placeholder="Enter description"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddBusinessPartnerTypePopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerTypeLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //add Industry
  const handleCancelAddIndustryPopover = () => {
    setAddIndustryPopoverOpen(false);
    addIndustryForm.resetFields();
  };

  const handleCancelTypeofCompanyPopover = () => {
    setAddTypeofCompanyPopoverOpen(false);
    typeofCompanyForm.resetFields();
  };

  const showAddIndustryPopoverOpen = () => {
    setAddIndustryPopoverOpen(!addIndustryPopoverOpen);
  };

  const addIndustryPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addIndustryForm}
          onFinish={(values) =>
            dispatch(
              actionAddIndustryMaster({
                values,
                handleIndustryPopoverClose: handleCancelAddIndustryPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="industry"
            label="Industry"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input industry!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Industry"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddIndustryPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addIndustryLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const addTypeofCompanyPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={typeofCompanyForm}
          onFinish={(values) =>
            dispatch(
              actionAddTypeOfCompanyMaster({
                values,
                handleCancel: handleCancelTypeofCompanyPopover,
                form,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="company_type"
            label="Type of company"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter type of comapany!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Type of company"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelTypeofCompanyPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addTypeOfCompanyLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    setCountrySelected(true);
    form.setFieldsValue({
      state_id: null,
    });
  };

  const handleClearState = () => {
    setCountrySelected(false);
    form.setFieldsValue({
      state_id: null,
    });
  };

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
      }))) ||
    [];

  const industryOptions =
    industryData &&
    industryData.length > 0 &&
    industryData.map((status) => ({
      label: status.industry,
      value: status.industry_master_id,
    }));

  const beatOptions =
    BeatMasterData &&
    BeatMasterData.length > 0 &&
    BeatMasterData.map((data) => ({
      label: data.beat_name,
      value: data.beat_master_id,
    }));

  const typeofCompanies =
    typeOfCompanyData &&
    typeOfCompanyData.length > 0 &&
    typeOfCompanyData.map(({ type_of_company_id, company_type }) => ({
      label: company_type,
      value: type_of_company_id,
    }));

  const businessTypeOptions =
    businessPartnerTypeData &&
    businessPartnerTypeData.length > 0 &&
    businessPartnerTypeData.map((status) => ({
      label: status.business_partner_type_name,
      value: status.business_partner_type_master_id,
    }));

  const handleSubmit = (values) => {
    const othersArr = [];
    otherCertificates &&
      otherCertificates.length > 0 &&
      otherCertificates?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          othersArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          othersArr.push(parts);
        } else {
          othersArr.push(d.path);
        }
      });
    const contractsArr = [];

    contractFile &&
      contractFile.length > 0 &&
      contractFile?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          contractsArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          contractsArr.push(parts);
        } else {
          contractsArr.push(d.path);
        }
      });

    const data = {
      ...values,
      pan_card_attachment: extractFilename(panCard),
      logo: BusinessPartnerLogoPicUrl
        ? extractFilename(BusinessPartnerLogoPicUrl)
        : "",
      gstn_certificate_attachment: gstnCertificate
        ? extractFilename(gstnCertificate)
        : "",
      cancelled_cheque_attachment: cancelledCheque
        ? extractFilename(cancelledCheque)
        : "",

      msme_details_attachment: msmeDetail ? extractFilename(msmeDetail) : "",
      other_certificates_attachments: othersArr,
      contract_attachements: contractsArr,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    businessPartnerId
      ? dispatch(
          actionUpdateBusinessPartner({ id: businessPartnerId, req, navigate })
        )
      : dispatch(actionAddBusinessPartner({ req, navigate }));
  };


  /* const renderFileIcon = (file) => {
    const extractFileName = extractFilename(file);
    if (
      extractFileName.endsWith("png") ||
      extractFileName.endsWith("jpg") ||
      extractFileName.endsWith("jpeg")
    ) {
      return <Image src={file} alt="docs" height={24} width={24} />;
    } else if (extractFileName.endsWith("pdf")) {
      return <FilePdfOutlined style={{ fontSize: "24px", color: "#ff6347" }} />;
    } else if (
      extractFileName.endsWith("doc") ||
      extractFileName.endsWith("docx")
    ) {
      return (
        <FileWordOutlined style={{ fontSize: "24px", color: "#1e90ff" }} />
      );
    } else {
      return (
        <FileTextOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
      );
    }
  }; */

  return (
    <Spin spinning={false}>
      <section className="main-wrapper">
        <CustomIconText
          image={businessPartner}
          title={
            businessPartnerId
              ? "Update Business Partner"
              : "Create Business Partner"
          }
          backArrow={true}
        />
        <Spin spinning={getBusinessPartnerDetailLoader}>
          <Form
            scrollToFirstError={true}
            form={form}
            layout="vertical"
            name="basic"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
                Basic Details
              </h1>
              <Row gutter={24}>
                <Col span={24} align="middle">
                  <Form.Item className="mb-[12px]" size="small">
                    {BusinessPartnerLogoPicUrl ? (
                      <div className="relative border border-dashed border-brand  h-[102px]  w-[102px] rounded-[6px] mb-2">
                        <img
                          src={BusinessPartnerLogoPicUrl}
                          alt="avatar"
                          className="h-full w-full rounded-[6px]"
                        />

                        <ImgCrop
                          rotationSlider
                          aspect={1 / 1}
                          fillColor="white"
                        >
                          <Upload
                            id=""
                            accept=".png,.jpg,.jpeg,.pdf,.doc"
                            name="file"
                            listType="picture-card"
                            className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeBusinessPartnerLogo}
                          >
                            <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editPrimaryWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </div>
                    ) : (
                      <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                        <Upload
                          accept=".png,.jpg,.jpeg,.pdf,.doc"
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader mb-2"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeBusinessPartnerLogo}
                        >
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Logo
                            </div>
                          </button>
                        </Upload>
                      </ImgCrop>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Business Partner Name"
                    name="business_partner_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input business partner name!",
                      },
                    ]}
                  >
                    <Input placeholder="XYZ Company" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addBusinessPartnerTypePopover}
                    trigger="click"
                    open={addBusinessPartnerTypePopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddBusinessPartnerTypePopoverOpen(false);
                        addBusinessPartnerTypeForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showAddBusinessPartnerTypePopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Business Partner Type
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Business Partner Type"
                    name="business_partner_type_master_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select business partner type!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Vendor, Client"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={businessTypeOptions}
                      disabled={getBusinessPartnerTypeLoader}
                      loading={getBusinessPartnerTypeLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: false,
                        message: "Please input valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="example@email.com" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mobile"
                    normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                    label="Mobile"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        max: 15,
                        message:
                          "mobile number cannot be longer than 15 digits",
                      },
                    ]}
                  >
                    <Input
                      // rows={3}
                      style={{ background: "white" }}
                      placeholder="+91 1234567890"
                      className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Address 1"
                    name="address_1"
                    rules={[
                      {
                        required: false,
                        message: "Please input address!",
                      },
                    ]}
                  >
                    <Input placeholder="123 Main St" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Address 2"
                    name="address_2"
                    rules={[
                      {
                        required: false,
                        message: "Please input address!",
                      },
                    ]}
                  >
                    <Input placeholder="Suite 101" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Country"
                    name="country_id"
                    rules={[
                      {
                        required: false,
                        message: "Please input country!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={getCountryLoader}
                      onClear={handleClearState}
                      onSelect={handleGetState}
                      placeholder="Select Country"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={country}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state_id"
                    rules={[
                      {
                        required: false,
                        message: "Please input state!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={statesLoader}
                      disabled={!countrySelected}
                      placeholder="Select State"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={state}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: false,
                        message: "Please input city!",
                      },
                    ]}
                  >
                    <Input placeholder="New York" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Pin Code"
                    name="zip_code"
                    rules={[
                      {
                        required: false,
                        message: "Please input pin code!",
                      },
                    ]}
                  >
                    <Input placeholder="12345" />
                  </Form.Item>
                </Col>

                {/* TODO: Add Beat Dropdown here */}
                <Col span={12}>
                  <Form.Item
                    label="Beat"
                    name="beat_master_id"
                    rules={[
                      {
                        required: false,
                        message: "Please select beat!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={getBeatMasterLoader}
                      placeholder="Select Beat"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={beatOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-4">
                Company Details
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="GST Number"
                    name="gst_number"
                    rules={[
                      {
                        required: false,
                        pattern:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                        message: "Please enter valid gst number!",
                      },
                    ]}
                  >
                    <Input placeholder="22AAAAA0000A1Z5" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="PAN Card No"
                    name="pan_card_no"
                    rules={[
                      {
                        required: false,
                        pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Please input valid pan card no!",
                      },
                    ]}
                  >
                    <Input placeholder="ABCDE1234F" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="MSME Number"
                    name="msme_number"
                    rules={[
                      {
                        required: false,
                        message: "Please input msme number!",
                      },
                    ]}
                  >
                    <Input placeholder="UDYAM-XXXX-XXXX" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="FSSAI"
                    name="fssai"
                    rules={[
                      {
                        required: false,
                        message: "Please input fssai!",
                      },
                    ]}
                  >
                    <Input placeholder="1234567890" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="CIN No"
                    name="cin_no"
                    rules={[
                      {
                        required: false,
                        message: "Please input cin no!",
                      },
                    ]}
                  >
                    <Input placeholder="UXXXXXXYYYYY" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addTypeofCompanyPopover}
                    trigger="click"
                    open={addTypeofCompanyPopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddTypeofCompanyPopoverOpen(false);
                        typeofCompanyForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={() =>
                        setAddTypeofCompanyPopoverOpen(
                          !addTypeofCompanyPopoverOpen
                        )
                      }
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add type of company
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item label="Type of Company" name="type_of_company_id">
                    <Select
                      allowClear
                      showSearch
                      // mode="multiple"
                      placeholder="Pvt Ltd, LLP"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={getTypeOfCompanyLoader}
                      disabled={getTypeOfCompanyLoader}
                      options={typeofCompanies}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addIndustryPopover}
                    trigger="click"
                    open={addIndustryPopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddIndustryPopoverOpen(false);
                        addIndustryForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showAddIndustryPopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Industry
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Industry"
                    name="industry_master_id"
                    rules={[
                      {
                        required: false,
                        message: "Please select industry!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Technology, Healthcare"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={industryOptions}
                      disabled={getIndustryLoader}
                      loading={getIndustryLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Company Size"
                    name="company_size"
                    rules={[
                      {
                        required: false,
                        message: "Please input company size!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Medium"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Small",
                          label: "Small",
                        },
                        {
                          value: "Medium",
                          label: "Medium",
                        },
                        {
                          value: "Large",
                          label: "Large",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Revenue"
                    name="revenue"
                    rules={[
                      {
                        required: false,
                        message: "Please input revenue!",
                      },
                    ]}
                  >
                    <Input placeholder="₹1,000,000" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Sales Volume"
                    name="sales_volume"
                    rules={[
                      {
                        required: false,
                        message: "Please input sales volume!",
                      },
                    ]}
                  >
                    <Input placeholder="10,000 units" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
                Agreement Details
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Agreement Duration"
                    name="agreement_duration"
                    rules={[
                      {
                        required: false,
                        message: "Please input agreement duration!",
                      },
                    ]}
                  >
                    <Input placeholder="1 year" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Commission Structure"
                    name="commission_structure"
                    rules={[
                      {
                        required: false,
                        message: "Please input commission structure!",
                      },
                    ]}
                  >
                    <Input placeholder="10% per sale" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Terms and Conditions"
                    name="terms_and_conditions"
                    rules={[
                      {
                        required: false,
                        message: "Please input terms and conditions!",
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="As per contract" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Feedback Mechanism"
                    name="feedback_mechanism"
                    rules={[
                      {
                        required: false,
                        message: "Please input feedback mechanism!",
                      },
                    ]}
                  >
                    <Input placeholder="Survey form link" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Renewal Process"
                    name="renewal_process"
                    rules={[
                      {
                        required: false,
                        message: "Please input renewal process!",
                      },
                    ]}
                  >
                    <Input placeholder="Annual renewal" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Termination Conditions"
                    name="termination_conditions"
                    rules={[
                      {
                        required: false,
                        message: "Please input termination conditions!",
                      },
                    ]}
                  >
                    <Input placeholder="30 days notice" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
                Bank Details
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Bank Name"
                    name="bank_name"
                    rules={[
                      {
                        required: false,
                        message: "Please input bank name!",
                      },
                    ]}
                  >
                    <Input placeholder="HDFC Bank" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Branch Name"
                    name="branch_name"
                    rules={[
                      {
                        required: false,
                        message: "Please input branch_name!",
                      },
                    ]}
                  >
                    <Input placeholder="Main Street Branch" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Account Type"
                    name="account_type"
                    rules={[
                      {
                        required: false,
                        message: "Please input account type!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Savings"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Savings Account",
                          label: "Savings Account",
                        },
                        {
                          value: "Current Account",
                          label: "Current Account",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="IFSC"
                    name="ifsc"
                    rules={[
                      {
                        required: false,
                        message: "Please input IFSC!",
                      },
                    ]}
                  >
                    <Input placeholder="HDFC00001234" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Account Number"
                    name="account_number"
                    rules={[
                      {
                        required: false,
                        message: "Please input account number!",
                      },
                    ]}
                  >
                    <Input placeholder="1234567890" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
                Documents / Attachments
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="text-end"
                    label="GSTN Certificate"
                    name="gstn_certificate_attachment"
                    layout="horizontal"
                  >
                    {gstnCertificate ? (
                      <div className="h-[50px] flex items-center ml-auto w-7/12 p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                        {/*  {gstnCertificate.endsWith("png") ||
                        gstnCertificate.endsWith("jpg") ||
                        gstnCertificate.endsWith("jpeg") ? (
                          <img
                            src={gstnCertificate}
                            alt="Uploaded File"
                            style={{
                              maxWidth: "40px",
                              borderRadius: 6,
                              maxHeight: "40px",
                            }}
                          />
                        ) : gstnCertificate.endsWith("pdf") ? (
                          <FilePdfOutlined
                            style={{ fontSize: "24px", color: "#ff6347" }}
                          />
                        ) : gstnCertificate.endsWith("doc") ||
                          gstnCertificate.endsWith("docx") ? (
                          <FileWordOutlined
                            style={{ fontSize: "24px", color: "#1e90ff" }}
                          />
                        ) : (
                          <FileTextOutlined
                            style={{ fontSize: "24px", color: "#1890ff" }}
                          />
                        )} */}
                        {renderFileIcon(gstnCertificate)}

                        <Typography.Paragraph
                          style={{ marginBottom: 0 }}
                          ellipsis={{ rows: 1 }}
                        >
                          {extractFilename(gstnCertificate)}
                        </Typography.Paragraph>

                        <Button
                          onClick={() => setGstnCertificate("")}
                          danger
                          size="small"
                          style={{
                            height: 30,
                            width: 30,
                            marginLeft: "auto",
                            border: "none",
                          }}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </div>
                    ) : (
                      <Upload
                        className="custom-upload show-remove-icon"
                        maxCount={1}
                        {...gstnCertificateProps}
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                      >
                        <Button
                          className="upload-button text-end"
                          icon={
                            <TbFileUpload
                              color="#4567ff"
                              className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                            />
                          }
                        >
                          <p className="text-[#4567ff]">Upload</p>
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="text-end"
                    label="PAN Card"
                    name="pan_card_attachment"
                    layout="horizontal"
                    rules={[
                      {
                        required: false,
                        message: "Please input pan card!",
                      },
                    ]}
                  >
                    {panCard ? (
                      <div className="h-[50px] flex items-center ml-auto w-7/12 p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                        {panCard.endsWith("png") ||
                        panCard.endsWith("jpg") ||
                        panCard.endsWith("jpeg") ? (
                          <img
                            src={panCard}
                            alt="Uploaded File"
                            style={{
                              maxWidth: "40px",
                              borderRadius: 6,
                              maxHeight: "40px",
                            }}
                          />
                        ) : panCard.endsWith("pdf") ? (
                          <FilePdfOutlined
                            style={{ fontSize: "24px", color: "#ff6347" }}
                          />
                        ) : panCard.endsWith("doc") ||
                          panCard.endsWith("docx") ? (
                          <FileWordOutlined
                            style={{ fontSize: "24px", color: "#1e90ff" }}
                          />
                        ) : (
                          <FileTextOutlined
                            style={{ fontSize: "24px", color: "#1890ff" }}
                          />
                        )}

                        <Typography.Paragraph
                          style={{ marginBottom: 0 }}
                          ellipsis={{ rows: 1 }}
                        >
                          {extractFilename(panCard)}
                        </Typography.Paragraph>

                        <Button
                          onClick={() => setPanCard("")}
                          danger
                          size="small"
                          style={{
                            height: 30,
                            width: 30,
                            marginLeft: "auto",
                            border: "none",
                          }}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </div>
                    ) : (
                      <Upload
                        className="custom-upload show-remove-icon"
                        maxCount={1}
                        {...panCardProps}
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                      >
                        <Button
                          className="upload-button text-end"
                          icon={
                            <TbFileUpload
                              color="#4567ff"
                              className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                            />
                          }
                        >
                          <p className="text-[#4567ff]">Upload</p>
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="text-end"
                    label="Cancelled Cheque"
                    name="cancelled_cheque_attachment"
                    layout="horizontal"
                    rules={[
                      {
                        required: false,
                        message: "Please input cancelled cheque!",
                      },
                    ]}
                  >
                    {cancelledCheque ? (
                      <div className="h-[50px] flex items-center ml-auto w-[60%] p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                        {cancelledCheque.endsWith("png") ||
                        cancelledCheque.endsWith("jpg") ||
                        cancelledCheque.endsWith("jpeg") ? (
                          <img
                            src={cancelledCheque}
                            alt="Uploaded File"
                            style={{
                              maxWidth: "40px",
                              borderRadius: 6,
                              maxHeight: "40px",
                            }}
                          />
                        ) : cancelledCheque.endsWith("pdf") ? (
                          <FilePdfOutlined
                            style={{ fontSize: "24px", color: "#ff6347" }}
                          />
                        ) : cancelledCheque.endsWith("doc") ||
                          cancelledCheque.endsWith("docx") ? (
                          <FileWordOutlined
                            style={{ fontSize: "24px", color: "#1e90ff" }}
                          />
                        ) : (
                          <FileTextOutlined
                            style={{ fontSize: "24px", color: "#1890ff" }}
                          />
                        )}

                        <Typography.Paragraph
                          style={{ marginBottom: 0 }}
                          ellipsis={{ rows: 1 }}
                        >
                          {extractFilename(cancelledCheque)}
                        </Typography.Paragraph>

                        <Button
                          onClick={() => setCancelledCheque("")}
                          danger
                          size="small"
                          style={{
                            height: 30,
                            width: 30,
                            marginLeft: "auto",
                            border: "none",
                          }}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </div>
                    ) : (
                      <Upload
                        className="custom-upload show-remove-icon"
                        maxCount={1}
                        {...cancelledChequeProps}
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                      >
                        <Button
                          className="upload-button text-end"
                          icon={
                            <TbFileUpload
                              color="#4567ff"
                              className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                            />
                          }
                        >
                          <p className="text-[#4567ff]">Upload</p>
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="text-end"
                    label="MSME Details"
                    name="msme_details_attachment"
                    layout="horizontal"
                    rules={[
                      {
                        required: false,
                        message: "Please input msme details!",
                      },
                    ]}
                  >
                    {msmeDetail ? (
                      <div className="h-[50px] flex items-center ml-auto w-[60%] p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                        {msmeDetail.endsWith("png") ||
                        msmeDetail.endsWith("jpg") ||
                        msmeDetail.endsWith("jpeg") ? (
                          <img
                            src={msmeDetail}
                            alt="Uploaded File"
                            style={{
                              maxWidth: "40px",
                              borderRadius: 6,
                              maxHeight: "40px",
                            }}
                          />
                        ) : msmeDetail.endsWith("pdf") ? (
                          <FilePdfOutlined
                            style={{ fontSize: "24px", color: "#ff6347" }}
                          />
                        ) : msmeDetail.endsWith("doc") ||
                          msmeDetail.endsWith("docx") ? (
                          <FileWordOutlined
                            style={{ fontSize: "24px", color: "#1e90ff" }}
                          />
                        ) : (
                          <FileTextOutlined
                            style={{ fontSize: "24px", color: "#1890ff" }}
                          />
                        )}

                        <Typography.Paragraph
                          style={{ marginBottom: 0 }}
                          ellipsis={{ rows: 1 }}
                        >
                          {extractFilename(msmeDetail)}
                        </Typography.Paragraph>

                        <Button
                          onClick={() => setMsmeDetail("")}
                          danger
                          size="small"
                          style={{
                            height: 30,
                            width: 30,
                            marginLeft: "auto",
                            border: "none",
                          }}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </div>
                    ) : (
                      <Upload
                        className="custom-upload show-remove-icon"
                        maxCount={1}
                        {...msmeProps}
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                      >
                        <Button
                          className="upload-button text-end"
                          icon={
                            <TbFileUpload
                              color="#4567ff"
                              className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                            />
                          }
                        >
                          <p className="text-[#4567ff]">Upload</p>
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Contract"
                    name="contract_attachements"
                    rules={[
                      {
                        required: false,
                        message: "Please select contract!",
                      },
                    ]}
                  >
                    <Dragger
                      className="dragger-custom show-remove-icon"
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                      {...contractProps}
                    >
                      <div className="py-[30px]">
                        <Image src={attechment} alt="image" preview={false} />
                        <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                          Facility to Attach Documents
                        </p>
                      </div>
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Other Certificates"
                    name="other_certificates_attachments"
                    rules={[
                      {
                        required: false,
                        message: "Please select other certificates!",
                      },
                    ]}
                  >
                    <Dragger
                      {...otherCertificatesProps}
                      className="dragger-custom show-remove-icon"
                    >
                      <div className="py-[30px]">
                        <Image src={attechment} alt="image" preview={false} />
                        <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                          Facility to Attach Documents
                        </p>
                      </div>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card className="main-card mt-5 w-full">
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mb-2">
                Contact Information
              </h1>
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mt-4 mb-2">
                Primary Contact
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Primary Contact Name"
                    name="primary_contact_name"
                    rules={[
                      {
                        required: false,
                        message: "Please input primary contact name!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.length > 15) {
                            return Promise.reject(
                              "Please enter a valid phone number!"
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="John Doe" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Primary Contact Email"
                    name="primary_contact_email"
                    rules={[
                      {
                        type: "email",
                        required: false,
                        message: "Please input valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="john.doe@email.com" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="primary_contact_phone"
                    normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                    label="Primary Contact Phone"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        max: 15,
                        message: "Phone number cannot be longer than 15 digits",
                      },
                    ]}
                  >
                    <Input
                      // rows={3}
                      style={{ background: "white" }}
                      placeholder="+91 1234567890"
                      className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h1 className="lg:text-lg text-base font-popinsRegular text-brand mt-4 mb-2">
                Secondary Contact
              </h1>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Secondary Contact Name  "
                    name="secondary_contact_name"
                    rules={[
                      {
                        required: false,
                        message: "Please input secondary contact name!",
                      },
                    ]}
                  >
                    <Input placeholder="Jane Smith" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Secondary Contact Email"
                    name="secondary_contact_email"
                    rules={[
                      {
                        type: "email",
                        required: false,
                        message: "Please input valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="jane.smith@email.com" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="secondary_contact_phone"
                    normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                    label="secondary Contact Phone"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Contact!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.length > 15) {
                            return Promise.reject(
                              "Please enter a valid contact number!"
                            );
                          }

                          // You can add additional conditions if needed
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      // rows={3}
                      style={{ background: "white" }}
                      placeholder="+91 9876543210"
                      className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row gutter={24}>
              <Col
                span={24}
                className="flex items-center my-4 justify-center gap-x-5"
              >
                <Form.Item>
                  <Button
                    onClick={async () => {
                      navigate(-1);
                    }}
                    type="button"
                    className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={
                      addBusinessPartnerLoader || updateBusinessPartnerLoader
                    }
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {businessPartnerId ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </section>
    </Spin>
  );
};

export default CreateBusinessPartner;
