import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeatActiveIcon from "../../assets/img/beat-active-icon.svg";
import calendar from "../../assets/img/beat-calendar.svg";
import DetailActiveIcon from "../../assets/img/beat-detail-active-icon.svg";
import DetailIcon from "../../assets/img/beat-detail-icon.svg";
import EmployeeIcon from "../../assets/img/beat-employee.svg";
import BeatIcon from "../../assets/img/beat-icon.svg";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { getFullName } from "../helper/common-function";
import { actionGetBeatMaster } from "../../store/services/generalMasterService";

// Reusable SearchInput Component
const SearchInput = ({ value, onChange, placeholder }) => (
  <Input
    className="ml-2 flex-1"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    prefix={<SearchOutlined className="text-[#7C7C7C]" />}
  />
);

const EmployeeSelector = ({ userSelected, setUserSelected }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(undefined);

  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSelect = (val) => {
    setUser(val);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, data) => getFullName(data.first_name, data.last_name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setUserSelected(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: userSelected,
    onChange: onSelectChange,
  };

  const userOptions = [
    { value: "john_doe", label: "John Doe" },
    { value: "jane_smith", label: "Jane Smith" },
    { value: "michael_jordan", label: "Michael Jordan" },
    { value: "sarah_conner", label: "Sarah Conner" },
  ];

  return (
    <>
      <div className="flex w-[75%] items-center gap-2">
        <Select
          className="ml-2  flex-1"
          showSearch
          placeholder="Search user"
          allowClear
          value={user}
          onClear={() => setUser(undefined)}
          onChange={onSelect}
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={userOptions}
          disabled={false}
        />
        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder="Search"
        />
        <br />
      </div>
      <div className="mt-8 mb-3 flex flex-col justify-between gap-y-2">
        <p className="text-end text-base text-brand">
          {userSelected?.length} User Selected
        </p>
        <Table
          rowKey={"id"}
          rowSelection={rowSelection}
          size="middle"
          columns={columns}
          dataSource={companyAllUsers}
          loading={getCompanyAllUserLoader}
          className=" rounded-md small-table"
          pagination={false}
        />
      </div>
    </>
  );
};

const BeatSelector = ({ setBeatSelected, beatSelected }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [territory, setTerritory] = useState(undefined);
  const [beatGrade, setBeatGrade] = useState(undefined);

  const { getBeatMasterLoader, BeatMasterData } = useSelector(
    (state) => state.generalMaster
  );

  useEffect(() => {
    dispatch(actionGetBeatMaster());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectTerritory = (val) => {
    setTerritory(val);
  };

  const handleSelectBeatGrade = (val) => {
    setBeatGrade(val);
  };

  const columns = [
    {
      title: "Beat Name",
      dataIndex: "beat_name",
      key: "beat_name",
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setBeatSelected(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: beatSelected,
    onChange: onSelectChange,
  };

  const beatGradeOptions = [
    { value: "grade_A", label: "Grade A" },
    { value: "grade_B", label: "Grade B" },
    { value: "grade_C", label: "Grade C" },
    { value: "grade_D", label: "Grade D" },
  ];

  const territoryOptions = [
    { value: "north_region", label: "North Region" },
    { value: "south_region", label: "South Region" },
    { value: "east_region", label: "East Region" },
    { value: "west_region", label: "West Region" },
  ];

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <Select
          className="ml-2  flex-1"
          showSearch
          placeholder="Search territory"
          allowClear
          value={territory}
          onClear={() => setTerritory(undefined)}
          onChange={handleSelectTerritory}
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={territoryOptions}
          disabled={false}
        />

        <Select
          className="ml-2  flex-1"
          showSearch
          placeholder="Search beat grade"
          allowClear
          value={beatGrade}
          onClear={() => setBeatGrade(undefined)}
          onChange={handleSelectBeatGrade}
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={beatGradeOptions}
          disabled={false}
        />

        <SearchInput
          value={search}
          onChange={handleSearch}
          placeholder="Search"
        />
        <br />
      </div>
      <div className="mt-8 mb-3 flex flex-col justify-between gap-y-2">
        <p className="text-end text-base text-brand">
          {beatSelected && beatSelected?.length} Beat Selected
        </p>
        <Table
          rowSelection={rowSelection}
          size="middle"
          columns={columns}
          dataSource={BeatMasterData}
          loading={getBeatMasterLoader}
          className=" rounded-md small-table"
          pagination={false}
        />
      </div>
    </>
  );
};

const DetailComp = ({
  userSelected,
  beatSelected,
  form,
  setCurrent,
  setClearDataRef,
  setCreateModalOpen,
}) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [startDate, setStartDate] = useState(null);
  const [recurrence, setRecurrence] = useState("not-repeat");
  const [selectedDays, setSelectedDays] = useState([]);
  const [endOption, setEndOption] = useState("never");
  const [endDate, setEndDate] = useState(dayjs());
  const [afterOccurrence, setAfterOccurrence] = useState(1);
  const [repeatValue, setRepeatValue] = useState(1);
  const [repeatUnit, setRepeatUnit] = useState("days");

  const handleEndOptions = (e) => {
    setEndOption(e.target.value);
  };

  const handleRecurrenceChange = (value) => {
    setRecurrence(value);
  };

  const handleRepeatValueChange = (newValue) => {
    setRepeatValue(newValue);
  };

  const handleRepeatUnitChange = (newUnit) => {
    setRepeatUnit(newUnit);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const clearData = async () => {
    await setCreateModalOpen(false);
    form.resetFields();
    setStartDate(null);
    setRecurrence("not-repeat");
    setSelectedDays([]);
    setEndOption("never");
    setEndDate(dayjs());
    setAfterOccurrence(1);
    setCurrent(0);
  };

  useEffect(() => {
    if (setClearDataRef) {
      setClearDataRef(clearData);
    }
  }, [setClearDataRef]);

  const onFinish = (values) => {
    const req = {
      ...values,
      beat: beatSelected,
      user: userSelected,
      end_date_occurrence:
        endOption === "after" ? values.end_date_occurrence : undefined,
    };
    if (req) {
      console.log({ req });
      message.success("Processing complete!");
      clearData();
    }
  };

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const recurrenceOptions = [
    { value: "not-repeat", label: "Does not repeat daily" },
    { value: "daily", label: "Daily" },
    { value: "weekly_on_day", label: `Weekly on ${dayjs().format("dddd")}` },
    { value: "monthly", label: "Monthly on the third today" },
    { value: "annually", label: "Annual on today's date" },
    { value: "every_weekday", label: "Every weekday (Monday to Friday)" },
    { value: "custom", label: "Custom" },
  ];

  const RepeatInput = ({
    repeatValue,
    repeatUnit,
    onRepeatValueChange,
    onRepeatUnitChange,
    id,
  }) => {
    const onNumberChange = (value) => {
      const newNumber = parseInt(value || 1, 10);
      if (!Number.isNaN(newNumber)) {
        onRepeatValueChange(newNumber);
      }
    };

    const onSelectChange = (value) => {
      onRepeatUnitChange(value);
    };

    return (
      <Space id={id} size={"middle"} className="ml-2 flex repeat-unit">
        <InputNumber min={1} value={repeatValue} onChange={onNumberChange} />
        <Select
          className="repeat-unit-select"
          value={repeatUnit}
          style={{ margin: "0 8px" }}
          onChange={onSelectChange}
        >
          <Select.Option value="days">days</Select.Option>
          <Select.Option value="weeks">weeks</Select.Option>
          <Select.Option value="months">months</Select.Option>
          <Select.Option value="years">years</Select.Option>
        </Select>
      </Space>
    );
  };

  const repeatSelection = form.getFieldValue("repeat_every")?.repeatUnit;

  return (
    <Form
      className="py-2"
      form={form}
      initialValues={{
        repeat_every: {
          repeatUnit: "days",
          repeatValue: 1,
        },
        start_date: dayjs(),
        repeat_month: "monthly-day-today",
        recurrence: "not-repeat",
        end_date_occurrence: 1,
      }}
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={[16, 8]}>
        <Col span={10}>
          <Form.Item
            name="start_date"
            label="Start Date"
            layout="vertical"
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <DatePicker
              suffixIcon={<Image src={calendar} height={15} alt="calendar" />}
              style={{ width: "100%" }}
              onChange={handleStartDateChange}
              value={startDate}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <p className="opacity-0 py-[5px]">Label</p>
          <Form.Item
            name="recurrence"
            rules={[
              {
                required: true,
                message: "Please select start date!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select recurrence"
              allowClear
              onClear={() => setRecurrence(undefined)}
              value={recurrence}
              onChange={handleRecurrenceChange}
              options={recurrenceOptions}
              disabled={false}
            />
          </Form.Item>
        </Col>

        {recurrence === "custom" && (
          <Col span={20}>
            <RepeatInput
              repeatValue={repeatValue}
              repeatUnit={repeatUnit}
              onRepeatValueChange={handleRepeatValueChange}
              onRepeatUnitChange={handleRepeatUnitChange}
            />
          </Col>
        )}

        {repeatUnit === "weeks" && (
          <Col span={14}>
            <div className="repeat-days flex-items-center gap-x-2 mt-2 ml-2">
              <span className="pr-5 font-popinsMedium">Repeat on</span>
              <Space>
                {daysOfWeek.map((day, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedDays.includes(day)
                        ? "bg-brand text-white"
                        : "bg-[#F2F2F2] text-black"
                    }  cursor-pointer rounded-full  flex items-center justify-center h-7 w-7`}
                    onClick={() => toggleDay(day)}
                  >
                    {day?.charAt(0)}
                  </div>
                ))}
              </Space>
            </div>
          </Col>
        )}

        {repeatSelection === "months" && (
          <Col span={14}>
            <Form.Item
              className="mt-2"
              name="repeat_month"
              rules={[
                {
                  required: true,
                  message: "Please select item!",
                },
              ]}
            >
              <Select style={{ width: 250 }}>
                <Option value="monthly-day-today">
                  Monthly on day {dayjs().date()}
                </Option>
                <Option value="monthly-second-thursday">
                  Monthly on the second Thursday
                </Option>
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={20}>
          <Space size={"middle"} className="ml-2 mt-4" id="repeat-unit">
            <span className="font-popinsMedium">Ends</span>

            <Radio.Group onChange={handleEndOptions} value={endOption}>
              <Radio value={"never"}>Never</Radio>
              <Radio value={"on"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  On
                  <DatePicker
                    disabled={endOption === "on" ? false : true}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              </Radio>
              <Radio value={"after"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="mr-3">After</span>

                  <Form.Item
                    style={{
                      display: "flex",
                      marginBottom: 0,
                      alignItems: "center",
                    }}
                    name="end_date_occurrence"
                  >
                    <InputNumber
                      disabled={endOption === "after" ? false : true}
                      min={1}
                      value={afterOccurrence}
                      onChange={(e) => setAfterOccurrence(e)}
                      formatter={(value) => `${value} occurrences`}
                      parser={(value) => value.replace(" occurrences", "")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </Radio>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const CreateBeatPlanning = ({ setClearDataRef, setCreateModalOpen }) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [userSelected, setUserSelected] = useState([]);
  const [beatSelected, setBeatSelected] = useState([]);

  const steps = [
    {
      title: "Employee",
      icon: <Image preview={false} src={EmployeeIcon} alt="employee icon" />,
      content: (
        <EmployeeSelector
          userSelected={userSelected}
          setUserSelected={setUserSelected}
        />
      ),
    },
    {
      title: "Select Beat",
      icon: (
        <Image
          preview={false}
          src={current === 1 || current === 2 ? BeatActiveIcon : BeatIcon}
          alt="beat icon"
        />
      ),
      content: (
        <BeatSelector
          setBeatSelected={setBeatSelected}
          beatSelected={beatSelected}
        />
      ),
    },
    {
      title: "Details",

      icon: (
        <Image
          preview={false}
          src={current === 2 ? DetailActiveIcon : DetailIcon}
          alt="employee icon"
        />
      ),
      content: (
        <DetailComp
          setCreateModalOpen={setCreateModalOpen}
          setClearDataRef={setClearDataRef}
          setCurrent={setCurrent}
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          beatSelected={beatSelected}
          setBeatSelected={setBeatSelected}
          form={form}
        />
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  const handleFinish = () => {
    form.submit();
  };

  return (
    <div className="px-4 py-7 beat-planning-steps">
      <Steps
        responsive={true}
        labelPlacement="vertical"
        current={current}
        items={items}
      />
      <div className="mt-4">{steps[current].content}</div>
      <div className="flex items-center mt-5 justify-between">
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            className="ml-auto root-button min-w-[100px] min-h-[40px]"
            icon={<LeftOutlined />}
            iconPosition="start"
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            className="root-btn min-w-[100px] py-5 ml-auto flex-center radius"
            type="primary"
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={() => next()}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            className="root-btn min-w-[100px] py-5 ml-auto flex-center radius"
            onClick={() => {
              handleFinish();
            }}
          >
            Finish
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateBeatPlanning;
