import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCompanyUsers,
  actionAddUser,
  actionGetCompanyAllUsers,
  actionGetUserDetail,
  actionUpdateUser,
  actionDeactivateUser,
  actionClearUsersAllData,
} from "../services/usersService";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    getCompanyUserLoader: false,
    addUserLoader: false,
    companyUsers: [],
    companyUsersCount: 0,
    getCompanyAllUserLoader: false,
    companyAllUsers: [],
    getUserDetailLoader: false,
    userDetail: "",
    updateUserLoader: false,
    deactivateUsersLoader: false,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // actionAddUser State
      .addCase(actionAddUser.pending, (state) => {
        state.addUserLoader = true;
      })
      .addCase(actionAddUser.fulfilled, (state, action) => {
        state.addUserLoader = false;
      })
      .addCase(actionAddUser.rejected, (state) => {
        state.addUserLoader = false;
      })

      // actionGetCompanyUsers State
      .addCase(actionGetCompanyUsers.pending, (state) => {
        state.getCompanyUserLoader = true;
      })
      .addCase(actionGetCompanyUsers.fulfilled, (state, action) => {
        state.companyUsers = action.payload.data;
        state.companyUsersCount = action.payload.total_records;
        state.getCompanyUserLoader = false;
      })
      .addCase(actionGetCompanyUsers.rejected, (state) => {
        state.getCompanyUserLoader = false;
      })

      // actionGetCompanyAllUsers State
      .addCase(actionGetCompanyAllUsers.pending, (state) => {
        state.getCompanyAllUserLoader = true;
      })
      .addCase(actionGetCompanyAllUsers.fulfilled, (state, action) => {
        state.companyAllUsers = action.payload.data;
        state.getCompanyAllUserLoader = false;
      })
      .addCase(actionGetCompanyAllUsers.rejected, (state) => {
        state.getCompanyAllUserLoader = false;
      })
      // Clear All Users Data
      .addCase(actionClearUsersAllData.fulfilled, (state, action) => {
        state.companyAllUsers = action.payload;
      })

      // actionUpdateUser State
      .addCase(actionUpdateUser.pending, (state) => {
        state.updateUserLoader = true;
      })
      .addCase(actionUpdateUser.fulfilled, (state, action) => {
        state.userDetail = action.payload;
        state.updateUserLoader = false;
      })
      .addCase(actionUpdateUser.rejected, (state) => {
        state.updateUserLoader = false;
      })

      // actionGetUserDetail State
      .addCase(actionGetUserDetail.pending, (state) => {
        state.getUserDetailLoader = true;
      })
      .addCase(actionGetUserDetail.fulfilled, (state, action) => {
        state.userDetail = action.payload.data;
        state.getUserDetailLoader = false;
      })
      .addCase(actionGetUserDetail.rejected, (state) => {
        state.getUserDetailLoader = false;
      })

      // actionDeactivateUser State
      .addCase(actionDeactivateUser.pending, (state) => {
        state.deactivateUsersLoader = true;
      })
      .addCase(actionDeactivateUser.fulfilled, (state, action) => {
        const updatedUsers = state.companyUsers.map((user) => {
          const updatedUser = action.payload.find(
            (updated) => updated.id === user.id
          );
          if (updatedUser) {
            return {
              ...user,
              status: updatedUser.status,
            };
          }
          return user;
        });

        const DeactiveStatusChange =
          state.userDetail &&
          action.payload.find((updated) => updated.id === state.userDetail.id);
        // If found, update the user's status,
        if (DeactiveStatusChange && state.userDetail) {
          const req = {
            ...state.userDetail,
            status: action.payload[0].status,
          };
          state.userDetail = req;
        }
        state.companyUsers = updatedUsers;
        state.deactivateUsersLoader = false;
      })
      .addCase(actionDeactivateUser.rejected, (state) => {
        state.deactivateUsersLoader = false;
      });
  },
});
export default usersSlice.reducer;
