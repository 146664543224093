import React from "react";
import { useSelector } from "react-redux";
import Dashboard from "../dashboard/Dashboard";
import Login from "./Login";
import CompanyName from "../business/CompanyName";

const MainScreen = () => {
  const { user, usersLoader } = useSelector((state) => state.auth);

  if (usersLoader) {
    return <Spin />;
  }

  if (user && user.first_name) {

    if (user.company_users && user.company_users.length > 0) {
      const hasActiveUser = user.company_users.some(
        (companyUser) => companyUser.status === "ACTIVE"
      );

      console.log({ hasActiveUser });

      if (hasActiveUser) {
        console.log("Dashboard");
        return <Dashboard />;
      } else {
        return <CompanyName />;
      }
    } else {
      console.log("Called");
      return <CompanyName />;
    }
  } else {
    return <Login />;
  }
};

export default MainScreen;
