import {
  DeleteOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Form,
  Image,
  Pagination,
  Popconfirm,
  Segmented,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  actionCancleEvent,
  actionClearAllEventsData,
  actionDeleteEvent,
  actionGetAllEvents,
  actionGetEvents,
} from "../../../store/services/eventService";
import plusIcon from "../../../assets/img/add-button.svg";
import EventsIcon from "../../../assets/img/eventsIcon.svg";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import SortIcon from "../../common/SortIcon";

const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);

  const {
    eventsData,
    getEventsLoader,
    eventsDataCount,
    allEventsData,
    getAllEventsLoader,
    deleteEventsLoader,
    cancleEventLoader,
  } = useSelector((state) => state.events);

  const [search, setSearch] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("All");

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        search,
        eventStatus: label !== "All" ? label : "",
      })
    );
  };

  useEffect(() => {
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        search,
        eventStatus: selectedLabel !== "All" ? selectedLabel : "",
      })
    );
  }, [dispatch]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Running":
        return "text-green-500";
      case "Upcoming":
        return "text-blue-500";
      case "Past":
        return "text-slate-500";
      case "Cancelled":
        return "text-red-500";
      case "All":
        return "text-black";
      default:
        return "text-black";
    }
  };

  const initialColumns = [
    {
      title: "Event Name",
      key: "1",
      dataIndex: "event_name",
      fixed: "left",
      sorter: (a, b) =>true,
      sortIcon: () => <SortIcon />,
      width: "15%",
      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            localStorage.setItem("event_id", data.event_id);
            navigate(`/event-overview`, { state: { eventId: data.event_id } });
          }}
        >
          <p className="text-brand">
            {data.event_name.charAt(0).toUpperCase() + data.event_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Events Type",
      dataIndex: "event_type",
      key: "2",
      width: "10%",
      render: (text, data) => (
        <span>{data.event_type ? data.event_type.event_type : "-"}</span>
      ),
    },
    {
      title: "Start Date & Time",
      dataIndex: "start_date",
      key: "4",
      width: "15%",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>
          {`${
            data.start_date ? dayjs(data.start_date).format("DD MMM YYYY") : ""
          } 
          ${
            data.start_time
              ? dayjs(data.start_time, "HH:mm").format("h:mm A")
              : ""
          }`}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "event_status",
      key: "3",
      width: "10%",
      render: (text, data) => (
        <span className={getStatusColor(data.event_status)}>
          {data.event_status ? data.event_status : "-"}
        </span>
      ),
    },

    {
      title: "Location",
      dataIndex: "address",
      // ellipsis:true,
      key: "5",
      width: "15%",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => <span>{data.address ? data.address : "-"}</span>,
    },
    {
      title: "Organizer",
      dataIndex: "event_organizer",
      key: "6",
      width: "15%",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>{data.event_organizer ? data.event_organizer : "-"}</span>
      ),
    },
    {
      title: "Created by",
      key: "7",
      dataIndex: "created_by",
      width: "15%",
      render: (text, data) => (
        <div className="flex items-center">
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data.created_by.profile_pic}
          />
          <p className="ml-2">
            {data.created_by.first_name && data.created_by.last_name
              ? data.created_by.first_name + " " + data.created_by.last_name
              : data.created_by.first_name}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "8",
      width: "10%",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-event", {
                      state: { eventId: data.event_id },
                    }),
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: cancleEventLoader,
                      }}
                      placement="topLeft"
                      title="Cancle Event"
                      description={`Are you sure to cancle this event?`}
                      onConfirm={() => {
                        dispatch(
                          actionCancleEvent({
                            id: data.event_id,
                            eventStatus: "Cancelled",
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span>Cancle Event</span>
                    </Popconfirm>
                  ),
                  key: "cancle",
                  icon: <CalendarOutlined />,
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteEventsLoader,
                      }}
                      placement="topLeft"
                      title="Delete"
                      description={`Are you sure to delete this event?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEvent({
                            id: data.event_id,
                            offset: offsetRef.current,
                            search: search,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",
               
                  onClick: () => {
                    console.log("delete click");
                  },
                },
              ],
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];
  const [exportAll, setExportAll] = useState(false);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEvents({
          offset: offsetRef.current,
          search: e.target.value,
          eventStatus: selectedLabel !== "All" ? selectedLabel : "",
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEvents({
          offset: offsetRef.current,
          search: e.target.value,
          eventStatus: selectedLabel !== "All" ? selectedLabel : "",
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearch(null);
    setSelectedLabel("All");
    offsetRef.current = 0;
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventsData) &&
      allEventsData.length > 0 &&
      allEventsData.map((data) => ({
        "Event Name": data.event_name ? data.event_name : "-",
        "Event Type": data.event_type ? data.event_type.event_type : "-",
        "Event Category": data.event_category
          ? data.event_category.event_category
          : "-",
        "Start Date and Time": `${
          data.start_date ? dayjs(data.start_date).format("DD MMM YYYY") : ""
        } ${
          data.start_time
            ? dayjs(data.start_time, "HH:mm").format("h:mm A")
            : ""
        }`,
        Location: data.address ? data.address : "-",
        "Events Organizer": data.event_organizer ? data.event_organizer : "-",
        "Created by":
          data.created_by.first_name && data.created_by.last_name
            ? data.created_by.first_name + " " + data.created_by.last_name
            : data.created_by.first_name,
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Events.xlsx");
    dispatch(actionClearAllEventsData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allEventsData && allEventsData.length > 0) {
      exportAllToExcel();
    }
  }, [allEventsData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEvents());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventStatus: selectedLabel !== "All" ? selectedLabel : "",
      })
    );
    // setSortedInfo(sorterParams);
  };

  return (
    <section className="main-wrapper">
      <Flex gap="middle" justify="space-between" className="mb-4">
        <div className="text-brand flex items-center gap-x-3">
          <Image
            src={EventsIcon}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base  font-popinsRegular">Events</h1>
        </div>

        <div className="flex items-center">
          <div className="segmented-custom flex items-center">
            {["All", "Running", "Upcoming", "Past", "Cancelled"].map(
              (label) => (
                <div
                  key={label}
                  className={`${
                    selectedLabel === label
                      ? "segmented-label-selected"
                      : "segmented-label"
                  }`}
                  onClick={() => handleLabelClick(label)}
                >
                  {label}
                </div>
              )
            )}
          </div>
          <Link to={"/create-event"}>
            <Button
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              Create Event
            </Button>
          </Link>
        </div>
      </Flex>

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
        /* isAllowDownload={
          allEventsData && allEventsData.length === 0 ? false : true
        } */
      />

      <Table
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        rowKey={(record) => record.event_id}
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={initialColumns}
        dataSource={eventsData}
        loading={getEventsLoader || getAllEventsLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={eventsDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetEvents({
                offset: offsetRef.current,
                search,
                eventStatus: selectedLabel !== "All" ? selectedLabel : "",
              })
            );
          }}
        />
      </div>
    </section>
  );
};

export default Events;
