import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddEvent,
  actionClearAllEventsData,
  actionDeleteEvent,
  actionGetAllEvents,
  actionGetEventDetail,
  actionGetEvents,
  actionUpdateEvent,
  actionGetEventSpeakers,
  actionEventSpeakerDetail,
  actionAddEventSpeaker,
  actionUpdateEventSpeaker,
  actionDeleteEventSpeaker,
  actionClearAllEventSpeakerData,
  actionGetAllEventSpeakers,
  actionGetEventOverview,
  actionGetEventAttendees,
  actionClearAllEventAttendeeData,
  actionGetAllEventAttendees,
  actionEventAttendeeDetail,
  actionAddEventAttendee,
  actionUpdateEventAttendee,
  actionDeleteEventAttendee,
  actionCancleEvent,
  actionAttendeeChangeStatus,
  actionGetEventExhibitors,
  actionClearAllEventExhibitorsData,
  actionGetAllEventExhibitors,
  actionEventExhibitorDetail,
  actionAddEventExhibitor,
  actionDeleteEventExhibitor,
  actionUpdateEventExhibitor,
  actionAddSession,
  actionGetEventSessions,
  actionEventSessionDetail,
  actionUpdateEventSession,
  actionDeleteEventSession,
  clearEventOverview,
  clearEventId,
  actionGetAllEventInventory,
  actionGetEventInventory,
  actionClearAllEventInventory,
  actionUpdateEventInventory,
  actionEventInventoryDetail,
  actionAddEventInventory,
  actionDeleteEventInventory,
  actionDownloadFile,
  actionGetEventSponsor,
  actionClearAllEventSponsor,
  actionGetAllEventSponsor,
  actionEventSponsorDetail,
  actionAddEventSponsor,
  actionDeleteEventSponsor,
  actionUpdateEventSponsor,
} from "../services/eventService";

const eventSlice = createSlice({
  name: "events",
  initialState: {
    getEventsLoader: false,
    eventsData: "",
    eventsDataCount: "",
    getAllEventsLoader: false,
    // Speakers
    getEventSpeakersLoader: false,
    getEventSpeakerDetailLoader: false,
    addEventSpeakerLoader: false,
    getAllEventSpeakersLoader: false,
    updateEventSpeakerLoader: false,
    deleteEventSpeakerLoader: false,
    eventsOverviewLoader: false,
    eventsOverview: "",
    getEventSpeakerDetail: "",
    allEventsData: "",
    addEventsLoader: false,
    deleteEventsLoader: false,
    eventsDetailLoader: false,
    eventDetailData: "",
    updateEventsLoader: false,
    allEventSpeakersData: "",
    eventSpeakersDataCount: "",
    eventSpeakersData: [],
    // Attenedees
    getEventAttendeesLoader: false,
    eventAttendeesData: [],
    eventAttendeesDataCount: 0,
    allEventAttendeesData: [],
    getAllEventAttendeesLoader: false,
    getEventAttendeeDetailLoader: false,
    getEventAttendeeDetail: null,
    addEventAttendeeLoader: false,
    updateEventAttendeeLoader: false,
    deleteEventAttendeeLoader: false,
    cancleEventLoader: false,
    //exhibitors
    getExhibitorsLoader: false,
    getExhibitorDetailLoader: false,
    addExhibitorLoader: false,
    getAllExhibitorsLoader: false,
    updateExhibitorLoader: false,
    deleteExhibitorLoader: false,
    exhibitorsOverviewLoader: false,
    exhibitorsOverview: "",
    getExhibitorDetail: "",
    addExhibitorsLoader: false,
    deleteExhibitorsLoader: false,
    exhibitorsDetailLoader: false,
    exhibitorDetailData: "",
    updateExhibitorsLoader: false,
    allExhibitorsData: "",
    exhibitorsDataCount: "",
    eventExhibitorsData: [],
    //session
    addSessionLoader: false,
    getEventSessionsLoader: false,
    allEventSessionsData: "",
    getEventSessionDetailLoader: false,
    getEventSessionDetail: "",
    updateEventSessionLoader: false,
    deleteEventSessionLoader: false,
    getAllEventInventoryLoader: false,
    allEventInventoryData: [],
    getEventInventoryLoader: false,
    addEventInventoryLoader: false,
    deleteEventInventoryLoader: false,
    getEventInventoryDetailLoader: false,
    updateEventInventoryLoader: false,
    eventInventoryData: [],
    eventInventoryCount: 0,
    getEventInventoryDetail: "",
    downloadFileLoader: false,
    //Sponsor
    getSponsorLoader: false,
    getSponsorDetailLoader: false,
    addSponsorLoader: false,
    getAllSponsorLoader: false,
    updateSponsorLoader: false,
    deleteSponsorLoader: false,
    getSponsorDetail: "",
    addSponsorLoader: false,
    deleteSponsorLoader: false,
    sponsorDetailLoader: false,
    sponsorDetailData: "",
    updateSponsorLoader: false,
    allSponsorData: "",
    sponsorDataCount: "",
    eventSponsorData: [],
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // actionGetEvents State
      .addCase(actionGetEvents.pending, (state) => {
        state.getEventsLoader = true;
      })
      .addCase(actionGetEvents.fulfilled, (state, action) => {
        state.getEventsLoader = false;
        state.eventsData = action.payload.data;
        state.eventsDataCount = action.payload.total_records;
      })
      .addCase(actionGetEvents.rejected, (state) => {
        state.getEventsLoader = false;
      })
      // Clear All Users Data
      .addCase(actionClearAllEventsData.fulfilled, (state, action) => {
        state.allEventsData = action.payload;
      })
      // actionGetAllEvents State
      .addCase(actionGetAllEvents.pending, (state) => {
        state.getAllEventsLoader = true;
      })
      .addCase(actionGetAllEvents.fulfilled, (state, action) => {
        state.getAllEventsLoader = false;
        state.allEventsData = action.payload.data;
      })
      .addCase(actionGetAllEvents.rejected, (state) => {
        state.getAllEventsLoader = false;
      })
      // actionAddEvent State
      .addCase(actionAddEvent.pending, (state) => {
        state.addEventsLoader = true;
      })
      .addCase(actionAddEvent.fulfilled, (state, action) => {
        state.addEventsLoader = false;
      })
      .addCase(actionAddEvent.rejected, (state) => {
        state.addEventsLoader = false;
      })

      // actionUpdateEvent State
      .addCase(actionUpdateEvent.pending, (state) => {
        state.updateEventsLoader = true;
      })
      .addCase(actionUpdateEvent.fulfilled, (state, action) => {
        state.updateEventsLoader = false;
      })
      .addCase(actionUpdateEvent.rejected, (state) => {
        state.updateEventsLoader = false;
      })

      // actionDeleteEvent State
      .addCase(actionDeleteEvent.pending, (state) => {
        state.deleteEventsLoader = true;
      })
      .addCase(actionDeleteEvent.fulfilled, (state, action) => {
        state.deleteEventsLoader = false;
        state.eventsDataCount = state.eventsDataCount - 1;
      })
      .addCase(actionDeleteEvent.rejected, (state) => {
        state.deleteEventsLoader = false;
      })

      // actionGetEventDetail State
      .addCase(actionGetEventDetail.pending, (state) => {
        state.eventsDetailLoader = true;
      })
      .addCase(actionGetEventDetail.fulfilled, (state, action) => {
        state.eventsDetailLoader = false;
        state.eventDetailData = action.payload.data;
      })
      .addCase(actionGetEventDetail.rejected, (state) => {
        state.eventsDetailLoader = false;
      })
      // Event Overview State
      .addCase(actionGetEventOverview.pending, (state) => {
        state.eventsOverviewLoader = true;
      })
      .addCase(actionGetEventOverview.fulfilled, (state, action) => {
        state.eventsOverviewLoader = false;
        state.eventsOverview = action.payload.data;
      })
      .addCase(actionGetEventOverview.rejected, (state) => {
        state.eventsOverviewLoader = false;
      })

      // get event speaker states
      .addCase(actionGetEventSpeakers.pending, (state) => {
        state.getEventSpeakersLoader = true;
      })
      .addCase(actionGetEventSpeakers.fulfilled, (state, action) => {
        state.getEventSpeakersLoader = false;
        state.eventSpeakersData = action.payload.data;
        state.eventSpeakersDataCount = action.payload.total_records;
      })

      .addCase(actionGetEventSpeakers.rejected, (state) => {
        state.getEventSpeakersLoader = false;
      })

      // Clear All Event Speaker Data
      .addCase(actionClearAllEventSpeakerData.fulfilled, (state, action) => {
        state.allEventSpeakersData = action.payload;
      })
      // actionGetAllEvents State
      .addCase(actionGetAllEventSpeakers.pending, (state) => {
        state.getAllEventSpeakersLoader = true;
      })
      .addCase(actionGetAllEventSpeakers.fulfilled, (state, action) => {
        state.getAllEventSpeakersLoader = false;
        state.allEventSpeakersData = action.payload.data;
      })
      .addCase(actionGetAllEventSpeakers.rejected, (state) => {
        state.getAllEventSpeakersLoader = false;
      })

      // get Single event speaker states
      .addCase(actionEventSpeakerDetail.pending, (state) => {
        state.getEventSpeakerDetailLoader = true;
      })
      .addCase(actionEventSpeakerDetail.fulfilled, (state, action) => {
        state.getEventSpeakerDetailLoader = false;
        state.getEventSpeakerDetail = action.payload.data;
      })

      .addCase(actionEventSpeakerDetail.rejected, (state) => {
        state.getEventSpeakerDetailLoader = false;
      })

      // add  event speaker states
      .addCase(actionAddEventSpeaker.pending, (state) => {
        state.addEventSpeakerLoader = true;
      })
      .addCase(actionAddEventSpeaker.fulfilled, (state, action) => {
        state.addEventSpeakerLoader = false;
        /* if (state.eventSpeakersData && state.eventSpeakersData.length > 0) {
          state.eventSpeakersData.unshift(action.payload);
        } else {
          state.eventSpeakersData = [action.payload];
        } */
      })

      .addCase(actionAddEventSpeaker.rejected, (state) => {
        state.addEventSpeakerLoader = false;
      })

      // Update  event speaker states
      .addCase(actionUpdateEventSpeaker.pending, (state) => {
        state.updateEventSpeakerLoader = true;
      })
      .addCase(actionUpdateEventSpeaker.fulfilled, (state, action) => {
        state.updateEventSpeakerLoader = false;
        state.eventSpeakersData = state.eventSpeakersData.map((data) =>
          data.event_speaker_id === action.payload.event_speaker_id
            ? { ...data, ...action.payload }
            : data
        );
      })

      .addCase(actionUpdateEventSpeaker.rejected, (state) => {
        state.updateEventSpeakerLoader = false;
      })

      //delete event speaker
      .addCase(actionDeleteEventSpeaker.pending, (state) => {
        state.deleteEventSpeakerLoader = true;
      })

      .addCase(actionDeleteEventSpeaker.fulfilled, (state, action) => {
        state.deleteEventSpeakerLoader = false;
        state.eventSpeakersData = state.eventSpeakersData.filter(
          (data) => data.event_speaker_id !== action.payload
        );
        state.eventSpeakersDataCount = state.eventSpeakersDataCount - 1;
      })

      .addCase(actionDeleteEventSpeaker.rejected, (state) => {
        state.deleteEventSpeakerLoader = false;
      })

      //TOOD: Event Attendee Starts Here
      // get event attendee states
      .addCase(actionGetEventAttendees.pending, (state) => {
        state.getEventAttendeesLoader = true;
      })
      .addCase(actionGetEventAttendees.fulfilled, (state, action) => {
        state.getEventAttendeesLoader = false;
        state.eventAttendeesData = action.payload?.data;
        state.eventAttendeesDataCount = action.payload?.total_records;
      })
      .addCase(actionGetEventAttendees.rejected, (state) => {
        state.getEventAttendeesLoader = false;
      })

      // Clear All Event Attendee Data
      .addCase(actionClearAllEventAttendeeData.fulfilled, (state, action) => {
        state.allEventAttendeesData = action.payload;
      })

      // actionGetAllEvents State
      .addCase(actionGetAllEventAttendees.pending, (state) => {
        state.getAllEventAttendeesLoader = true;
      })
      .addCase(actionGetAllEventAttendees.fulfilled, (state, action) => {
        state.getAllEventAttendeesLoader = false;
        state.allEventAttendeesData = action.payload.data;
      })
      .addCase(actionGetAllEventAttendees.rejected, (state) => {
        state.getAllEventAttendeesLoader = false;
      })

      // get Single event attendee states
      .addCase(actionEventAttendeeDetail.pending, (state) => {
        state.getEventAttendeeDetailLoader = true;
      })
      .addCase(actionEventAttendeeDetail.fulfilled, (state, action) => {
        state.getEventAttendeeDetailLoader = false;
        state.getEventAttendeeDetail = action.payload.data;
      })
      .addCase(actionEventAttendeeDetail.rejected, (state) => {
        state.getEventAttendeeDetailLoader = false;
      })

      // add event attendee states
      .addCase(actionAddEventAttendee.pending, (state) => {
        state.addEventAttendeeLoader = true;
      })
      .addCase(actionAddEventAttendee.fulfilled, (state, action) => {
        state.addEventAttendeeLoader = false;
        if (action.payload) {
          if (
            !state.eventAttendeesData ||
            !Array.isArray(state.eventAttendeesData)
          ) {
            state.eventAttendeesData = [action.payload];
          } else {
            state.eventAttendeesData.unshift(action.payload);
          }
          state.eventAttendeesDataCount = state.eventAttendeesDataCount + 1;
        }
      })
      .addCase(actionAddEventAttendee.rejected, (state) => {
        state.addEventAttendeeLoader = false;
      })

      // Update event attendee states
      .addCase(actionUpdateEventAttendee.pending, (state) => {
        state.updateEventAttendeeLoader = true;
      })
      .addCase(actionUpdateEventAttendee.fulfilled, (state, action) => {
        state.updateEventAttendeeLoader = false;
        state.eventAttendeesData = state.eventAttendeesData.map((data) =>
          data.event_attendee_id === action.payload.event_attendee_id
            ? { ...data, ...action.payload }
            : data
        );
      })
      .addCase(actionUpdateEventAttendee.rejected, (state) => {
        state.updateEventAttendeeLoader = false;
      })

      // delete event attendee states
      .addCase(actionDeleteEventAttendee.pending, (state) => {
        state.deleteEventAttendeeLoader = true;
      })
      .addCase(actionDeleteEventAttendee.fulfilled, (state, action) => {
        state.deleteEventAttendeeLoader = false;
        state.eventAttendeesData = state.eventAttendeesData.filter(
          (data) => data.event_attendee_id !== action.payload
        );
        state.eventAttendeesDataCount = state.eventAttendeesDataCount - 1;
      })
      .addCase(actionDeleteEventAttendee.rejected, (state) => {
        state.deleteEventAttendeeLoader = false;
      })

      // change event attendee status
      .addCase(actionAttendeeChangeStatus.pending, (state) => {
        state.changeAttendeeStatusLoader = true;
      })
      .addCase(actionAttendeeChangeStatus.fulfilled, (state, action) => {
        state.changeAttendeeStatusLoader = false;
        state.eventAttendeesData =
          state.eventAttendeesData &&
          state.eventAttendeesData.map((data) =>
            data.event_attendee_id === action.payload.event_attendee_id
              ? {
                  ...data,
                  attendee_status: action.payload.attendee_status,
                }
              : data
          );
      })
      .addCase(actionAttendeeChangeStatus.rejected, (state) => {
        state.changeAttendeeStatusLoader = false;
      })

      // change event states
      .addCase(actionCancleEvent.pending, (state) => {
        state.cancleEventLoader = true;
      })
      .addCase(actionCancleEvent.fulfilled, (state, action) => {
        state.cancleEventLoader = false;
        state.eventsData =
          state.eventsData &&
          state.eventsData.map((data) =>
            data.event_id === action.payload.event_id
              ? {
                  ...data,
                  event_status: action.payload.event_status,
                }
              : data
          );

        state.eventsOverview = {
          ...state.eventsOverview,
          event: {
            ...state.eventsOverview.event,
            event_status: action.payload.event_status,
          },
        };
      })
      .addCase(actionCancleEvent.rejected, (state) => {
        state.cancleEventLoader = false;
      })

      // get event exhibitors
      .addCase(actionGetEventExhibitors.pending, (state) => {
        state.getExhibitorsLoader = true;
      })
      .addCase(actionGetEventExhibitors.fulfilled, (state, action) => {
        state.getExhibitorsLoader = false;
        state.eventExhibitorsData = action.payload.data;
        state.exhibitorsDataCount = action.payload.total_records;
      })

      .addCase(actionGetEventExhibitors.rejected, (state) => {
        state.getExhibitorsLoader = false;
      })

      // Clear All Exhibitors data
      .addCase(actionClearAllEventExhibitorsData.fulfilled, (state, action) => {
        state.allExhibitorsData = action.payload;
      })

      // actionGetAllEvents exhibitor
      .addCase(actionGetAllEventExhibitors.pending, (state) => {
        state.getAllExhibitorsLoader = true;
      })
      .addCase(actionGetAllEventExhibitors.fulfilled, (state, action) => {
        state.getAllExhibitorsLoader = false;
        state.allExhibitorsData = action.payload.data;
      })
      .addCase(actionGetAllEventExhibitors.rejected, (state) => {
        state.getAllExhibitorsLoader = false;
      })

      // get Single event Exhibitors
      .addCase(actionEventExhibitorDetail.pending, (state) => {
        state.getExhibitorDetailLoader = true;
      })
      .addCase(actionEventExhibitorDetail.fulfilled, (state, action) => {
        state.getExhibitorDetailLoader = false;
        state.getExhibitorDetail = action.payload.data;
      })
      .addCase(actionEventExhibitorDetail.rejected, (state) => {
        state.getExhibitorDetailLoader = false;
      })

      // add  event exhibitor states
      .addCase(actionAddEventExhibitor.pending, (state) => {
        state.addExhibitorLoader = true;
      })
      .addCase(actionAddEventExhibitor.fulfilled, (state, action) => {
        state.addExhibitorLoader = false;
        if (action.payload) {
          if (
            !state.eventExhibitorsData ||
            !Array.isArray(state.eventExhibitorsData)
          ) {
            state.eventExhibitorsData = [action.payload];
          } else {
            state.eventExhibitorsData.unshift(action.payload);
          }
          state.exhibitorsDataCount = state.exhibitorsDataCount + 1;
        }
      })

      .addCase(actionAddEventExhibitor.rejected, (state) => {
        state.addExhibitorLoader = false;
      })

      //delete event exhibitor
      .addCase(actionDeleteEventExhibitor.pending, (state) => {
        state.deleteExhibitorLoader = true;
      })

      .addCase(actionDeleteEventExhibitor.fulfilled, (state, action) => {
        state.deleteExhibitorLoader = false;
        state.eventExhibitorsData = state.eventExhibitorsData.filter(
          (data) => data.event_exhibitor_id !== action.payload
        );
        state.exhibitorsDataCount = state.exhibitorsDataCount - 1;
      })

      .addCase(actionDeleteEventExhibitor.rejected, (state) => {
        state.deleteExhibitorLoader = false;
      })

      // Update  event exhibitor
      .addCase(actionUpdateEventExhibitor.pending, (state) => {
        state.updateExhibitorLoader = true;
      })
      .addCase(actionUpdateEventExhibitor.fulfilled, (state, action) => {
        state.updateExhibitorLoader = false;
        state.eventExhibitorsData = state.eventExhibitorsData.map((data) =>
          data.event_exhibitor_id === action.payload.event_exhibitor_id
            ? { ...data, ...action.payload }
            : data
        );
      })

      .addCase(actionUpdateEventExhibitor.rejected, (state) => {
        state.updateExhibitorLoader = false;
      })

      //session
      .addCase(actionAddSession.pending, (state) => {
        state.addSessionLoader = true;
      })

      .addCase(actionAddSession.fulfilled, (state, action) => {
        state.addSessionLoader = false;
        if (action.payload) {
          if (
            !state.allEventSessionsData ||
            !Array.isArray(state.allEventSessionsData)
          ) {
            state.allEventSessionsData = [action.payload];
          } else {
            state.allEventSessionsData.push(action.payload);
          }
        }
      })
      .addCase(actionAddSession.rejected, (state) => {
        state.addSessionLoader = false;
      })

      .addCase(actionGetEventSessions.pending, (state) => {
        state.getEventSessionsLoader = true;
      })
      .addCase(actionGetEventSessions.fulfilled, (state, action) => {
        state.getEventSessionsLoader = false;
        state.allEventSessionsData = action.payload.data;
      })
      .addCase(actionGetEventSessions.rejected, (state) => {
        state.getEventSessionsLoader = false;
      })
      // get Single event session states
      .addCase(actionEventSessionDetail.pending, (state) => {
        state.getEventSessionDetailLoader = true;
      })
      .addCase(actionEventSessionDetail.fulfilled, (state, action) => {
        state.getEventSessionDetailLoader = false;
        state.getEventSessionDetail = action.payload.data;
      })

      .addCase(actionEventSessionDetail.rejected, (state) => {
        state.getEventSessionDetailLoader = false;
      })

      // Update  event session states
      .addCase(actionUpdateEventSession.pending, (state) => {
        state.updateEventSessionLoader = true;
      })
      .addCase(actionUpdateEventSession.fulfilled, (state, action) => {
        state.updateEventSessionLoader = false;
        state.allEventSessionsData = state.allEventSessionsData.map((data) =>
          data.event_session_id === action.payload.event_session_id
            ? { ...data, ...action.payload }
            : data
        );
      })
      .addCase(actionUpdateEventSession.rejected, (state) => {
        state.updateEventSessionLoader = false;
      })

      // Delete  event session states
      .addCase(actionDeleteEventSession.pending, (state) => {
        state.deleteEventSessionLoader = true;
      })

      .addCase(actionDeleteEventSession.fulfilled, (state, action) => {
        state.deleteEventSessionLoader = false;
        state.allEventSessionsData = state.allEventSessionsData.filter(
          (data) => data.event_session_id !== action.payload
        );
      })

      .addCase(actionDeleteEventSession.rejected, (state) => {
        state.deleteEventSessionLoader = false;
      })

      //clear overview data and eventid from local storage
      .addCase(clearEventId.fulfilled, (state) => {
        state.eventsOverview = "";
      })

      // event inventory
      .addCase(actionGetAllEventInventory.pending, (state) => {
        state.getAllEventInventoryLoader = true;
      })
      .addCase(actionGetAllEventInventory.fulfilled, (state, action) => {
        state.getAllEventInventoryLoader = false;
        state.allEventInventoryData = action.payload.data;
      })
      .addCase(actionGetAllEventInventory.rejected, (state) => {
        state.getAllEventInventoryLoader = false;
      })

      // get event inventory states
      .addCase(actionGetEventInventory.pending, (state) => {
        state.getEventInventoryLoader = true;
      })
      .addCase(actionGetEventInventory.fulfilled, (state, action) => {
        state.getEventInventoryLoader = false;
        state.eventInventoryData = action.payload.data;
        state.eventInventoryCount = action.payload.total_records;
      })

      .addCase(actionGetEventInventory.rejected, (state) => {
        state.getEventInventoryLoader = false;
      })

      // Clear All Event Speaker Data
      .addCase(actionClearAllEventInventory.fulfilled, (state, action) => {
        state.allEventSpeakersData = action.payload;
      })

      // get Single event speaker states
      .addCase(actionEventInventoryDetail.pending, (state) => {
        state.getEventInventoryDetailLoader = true;
      })
      .addCase(actionEventInventoryDetail.fulfilled, (state, action) => {
        state.getEventInventoryDetailLoader = false;
        state.getEventInventoryDetail = action.payload.data;
      })

      .addCase(actionEventInventoryDetail.rejected, (state) => {
        state.getEventInventoryDetailLoader = false;
      })

      // add  event speaker states
      .addCase(actionAddEventInventory.pending, (state) => {
        state.addEventInventoryLoader = true;
      })
      .addCase(actionAddEventInventory.fulfilled, (state, action) => {
        state.addEventInventoryLoader = false;
        /* if (state.eventSpeakersData && state.eventSpeakersData.length > 0) {
          state.eventSpeakersData.unshift(action.payload);
        } else {
          state.eventSpeakersData = [action.payload];
        } */
      })

      .addCase(actionAddEventInventory.rejected, (state) => {
        state.addEventInventoryLoader = false;
      })

      // Update  event speaker states
      .addCase(actionUpdateEventInventory.pending, (state) => {
        state.updateEventInventoryLoader = true;
      })
      .addCase(actionUpdateEventInventory.fulfilled, (state, action) => {
        state.updateEventInventoryLoader = false;
        state.eventInventoryData = state.eventInventoryData.map((data) =>
          data.event_inventory_id === action.payload.event_inventory_id
            ? { ...data, ...action.payload }
            : data
        );
      })

      .addCase(actionUpdateEventInventory.rejected, (state) => {
        state.updateEventInventoryLoader = false;
      })

      //delete event speaker
      .addCase(actionDeleteEventInventory.pending, (state) => {
        state.deleteEventInventoryLoader = true;
      })

      .addCase(actionDeleteEventInventory.fulfilled, (state, action) => {
        state.deleteEventInventoryLoader = false;
        if (action.payload) {
          state.eventInventoryData = state.eventInventoryData.filter(
            (data) => data.event_inventory_id !== action.payload
          );
          state.eventInventoryCount = state.eventInventoryCount - 1;
        }
      })

      .addCase(actionDeleteEventInventory.rejected, (state) => {
        state.deleteEventInventoryLoader = false;
      })

      //Download File Slices
      .addCase(actionDownloadFile.pending, (state) => {
        state.downloadFileLoader = true;
      })
      .addCase(actionDownloadFile.fulfilled, (state, action) => {
        state.downloadFileLoader = false;
      })
      .addCase(actionDownloadFile.rejected, (state) => {
        state.downloadFileLoader = false;
      })

      //Sponsor start here
      // get event Sponsor
      .addCase(actionGetEventSponsor.pending, (state) => {
        state.getSponsorLoader = true;
      })
      .addCase(actionGetEventSponsor.fulfilled, (state, action) => {
        state.getSponsorLoader = false;
        if (action.payload.data) {
          state.eventSponsorData = action.payload.data;
        }
        state.sponsorDataCount = action.payload.total_records;
      })

      .addCase(actionGetEventSponsor.rejected, (state) => {
        state.getSponsorLoader = false;
      })

      // Clear All Sponsor data
      .addCase(actionClearAllEventSponsor.fulfilled, (state, action) => {
        state.allSponsorData = action.payload;
      })

      // action GetAll Events Sponsor
      .addCase(actionGetAllEventSponsor.pending, (state) => {
        state.getAllSponsorLoader = true;
      })
      .addCase(actionGetAllEventSponsor.fulfilled, (state, action) => {
        state.getAllSponsorLoader = false;
        state.allSponsorData = action.payload.data;
      })
      .addCase(actionGetAllEventSponsor.rejected, (state) => {
        state.getAllSponsorLoader = false;
      })

      // get Single event Sponsor
      .addCase(actionEventSponsorDetail.pending, (state) => {
        state.getSponsorDetailLoader = true;
      })
      .addCase(actionEventSponsorDetail.fulfilled, (state, action) => {
        state.getSponsorDetailLoader = false;
        state.getSponsorDetail = action.payload.data;
      })
      .addCase(actionEventSponsorDetail.rejected, (state) => {
        state.getSponsorDetailLoader = false;
      })

      // add  event Sponsor
      .addCase(actionAddEventSponsor.pending, (state) => {
        state.addSponsorLoader = true;
      })
      .addCase(actionAddEventSponsor.fulfilled, (state, action) => {
        state.addSponsorLoader = false;
      })

      .addCase(actionAddEventSponsor.rejected, (state) => {
        state.addSponsorLoader = false;
      })

      //delete event Sponsor
      .addCase(actionDeleteEventSponsor.pending, (state) => {
        state.deleteSponsorLoader = true;
      })

      .addCase(actionDeleteEventSponsor.fulfilled, (state, action) => {
        state.deleteSponsorLoader = false;
        state.eventSponsorData = state.eventSponsorData.filter(
          (data) => data.event_sponsor_id !== action.payload
        );
        state.sponsorDataCount = state.sponsorDataCount - 1;
      })

      .addCase(actionDeleteEventSponsor.rejected, (state) => {
        state.deleteSponsorLoader = false;
      })

      // Update  event Sponsor
      .addCase(actionUpdateEventSponsor.pending, (state) => {
        state.updateSponsorLoader = true;
      })

      .addCase(actionUpdateEventSponsor.fulfilled, (state, action) => {
        state.updateSponsorLoader = false;
        state.eventSponsorData = state.eventSponsorData.map((data) =>
          data.event_sponsor_id === action.payload.event_sponsor_id
            ? { ...data, ...action.payload }
            : data
        );
      })

      .addCase(actionUpdateEventSponsor.rejected, (state) => {
        state.updateSponsorLoader = false;
      });
  },
});
export default eventSlice.reducer;
