import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

// product category start here
//  Action to get All product category
export const actionGetProductCategoryMaster = createAsyncThunk(
  "actionGetProductCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "", status = "" } = {}) => {
    return handleGetResponse(
      `product-category-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}${status ? `&status=${status}` : ""}`
    );
  }
);

//add product category
export const actionAddProductCategoryMaster = createAsyncThunk(
  "actionAddProductCategoryMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-category-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product category
export const actionDeleteProductCategoryMaster = createAsyncThunk(
  "actionDeleteProductCategoryMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `product-category-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//get single product category
export const actionGetSingleProductCategoryMaster = createAsyncThunk(
  "actionGetSingleProductCategoryMaster",
  (id) => {
    return handleGetResponse(`product-category-master/${id}`);
  }
);

//get product sub category
export const actionGetProductSubCategoryMaster = createAsyncThunk(
  "actionGetProductSubCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `product-sub-category-master?getall=YES${
        search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
        order ? `&order=${order}` : ""
      }`
    );
  }
);

//add product sub category
export const actionAddProductSubCategoryMaster = createAsyncThunk(
  "actionAddProductSubCategoryMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-sub-category-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product  sub category
export const actionDeleteProductSubCategoryMaster = createAsyncThunk(
  "actionDeleteProductSubCategoryMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `product-sub-category-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//edit product category master
export const actionEditProductCategoryMaster = createAsyncThunk(
  "actionEditProductCategoryMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single product  sub category
export const actionGetSingleProductSubCategoryMaster = createAsyncThunk(
  "actionGetSingleProductSubCategoryMaster",
  (id) => {
    return handleGetResponse(`product-sub-category-master/${id}`);
  }
);

//edit product sub category master
export const actionEditProductSubCategoryMaster = createAsyncThunk(
  "actionEditProductSubCategoryMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-sub-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//product type start here
//get all product type
export const actionGetProductTypeMaster = createAsyncThunk(
  "actionGetProductTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `product-type-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add product type
export const actionAddProductTypeMaster = createAsyncThunk(
  "actionAddProductTypeMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product type
export const actionDeleteProductTypeMaster = createAsyncThunk(
  "actionDeleteProductTypeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`product-type-master/${id}`, id, setEditingKey);
  }
);

//edit product type
export const actionEditProductTypeMaster = createAsyncThunk(
  "actionEditProductTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single product type
export const actionGetSingleProductTypeMaster = createAsyncThunk(
  "actionGetSingleProductTypeMaster",
  (id) => {
    return handleGetResponse(`product-type-master/${id}`);
  }
);

//hsn/sac code start here
export const actionGetHsnSacMaster = createAsyncThunk(
  "actionGetHsnSacMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `hsn-code-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add hsn/sac
export const actionAddHsnSacCodeMaster = createAsyncThunk(
  "actionAddHsnSacCodeMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(`${BASE_URL}/hsn-code-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete hsn/sac code
export const actionDeleteHsnSacCodeMaster = createAsyncThunk(
  "actionDeleteHsnSacCodeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`hsn-code-master/${id}`, id, setEditingKey);
  }
);

//edit Hsn/sac code
export const actionEditHsnSacMaster = createAsyncThunk(
  "actionEditHsnSacMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/hsn-code-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single hsn/sac
export const actionGetSingleHsnSacCodeMaster = createAsyncThunk(
  "actionGetSingleHsnSacCodeMaster",
  (id) => {
    return handleGetResponse(`hsn-code-master/${id}`);
  }
);

//get all unit
export const actionGetUnitMaster = createAsyncThunk(
  "actionGetUnitMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `unit-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add unit
export const actionAddUnitMaster = createAsyncThunk(
  "actionAddUnitMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(`${BASE_URL}/unit-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete unit master
export const actionDeleteUnitMaster = createAsyncThunk(
  "actionDeleteUnitMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`unit-master/${id}`, id, setEditingKey);
  }
);

//get single unit
export const actionGetSingleUnitMaster = createAsyncThunk(
  "actionGetSingleUnitMaster",
  (id) => {
    return handleGetResponse(`unit-master/${id}`);
  }
);

//edit unit master
export const actionEditUnitMaster = createAsyncThunk(
  "actionEditUnitMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/unit-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//product sub category start here
