import {
  EditOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, Modal, Result, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { GoDot } from "react-icons/go";
import leftLogo from "../../assets/img/left-logo.svg";
import mainLogo from "../../assets/img/logo.svg";
import hrActive from "../../assets/img/sidebar/HR-active.svg";
import hr from "../../assets/img/sidebar/HR.svg";
import beatActive from "../../assets/img/sidebar/beat-active.svg";
import beat from "../../assets/img/sidebar/beat.svg";
import creditActive from "../../assets/img/sidebar/credit-active.svg";
import credit from "../../assets/img/sidebar/credit.svg";
import crm from "../../assets/img/sidebar/crm.svg";
import dashboardActive from "../../assets/img/sidebar/dashboard-active.svg";
import dashboard from "../../assets/img/sidebar/dashboard.svg";
import deliveryActive from "../../assets/img/sidebar/delivery-active.svg";
import delivery from "../../assets/img/sidebar/delivery.svg";
import financeActive from "../../assets/img/sidebar/finance-active.svg";
import finance from "../../assets/img/sidebar/finance.svg";
import inventoryActive from "../../assets/img/sidebar/inventory-active.svg";
import inventory from "../../assets/img/sidebar/inventory.svg";
import masterDataActive from "../../assets/img/sidebar/master-data-active.svg";
import masterData from "../../assets/img/sidebar/master-data.svg";
import purchaseOrdersActive from "../../assets/img/sidebar/purchase-orders-active.svg";
import purchaseOrders from "../../assets/img/sidebar/purchase-orders.svg";
import salesActive from "../../assets/img/sidebar/sales-orders-active.svg";
import sales from "../../assets/img/sidebar/sales-orders.svg";
import settingActive from "../../assets/img/sidebar/setting-active.svg";
import setting from "../../assets/img/sidebar/setting.svg";
import taskActive from "../../assets/img/sidebar/task-active.svg";
import task from "../../assets/img/sidebar/task.svg";

import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
const { confirm } = Modal;

import { useDispatch, useSelector } from "react-redux";
import { actionLogout } from "../../store/services/authService";
import { clearEventId } from "../../store/services/eventService";
import { getEventId } from "../helper/common-function";

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Root = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("collapsed");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("1");
  const history = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const currentPath = history.pathname;
  useMemo(() => {
    const routeKeyMapping = {
      "": "1",
      inventory: "2",
      "purchase-orders": "3",
      "my-sales-orders": "4",
      "sales-invoices": "4",
      task: "5",
      delivery: "6",
      finance: "7",
      "credit-notes": "8",
      hr: "10",
      "beat-planning": "11",
      "master-data": "12",
      "event-masters": "12",
      "product-masters": "12",
      "view-business-partner": "12",
      "business-partner": "12",
      "create-business-partner": "12",
      "update-business-partner": "12",
      "general-masters": "12",
      setting: "13",
      "update-user": "13",
      "update-role-profile": "13",
      "role-profile": "13",
      "add-role-profile": "13",
      "role-hierarchy": "13",
      "role-hierarchy": "13",
      users: "13",
      "create-user": "13",
      "module-permissions": "13",
      "event-overview": "14",
      exhibitors: "14",
      events: "14",
      "lead-management": "14",
      "create-lead": "14",
      speakers: "14",
      "create-event": "14",
      attendees: "14",
      sponsor: "14",
      speakers: "14",
      "event-inventory": "14",
      sessions: "14",
      attendees: "14",
      "opportunity-management": "14",
      "create-opportunity": "14",
      "update-opportunity": "14",
      "business-partners": "12",
      "create-business-partner": "12",
      "update-business-partner": "12",
      contacts: "14",
      "create-contact": "14",
      "update-contact": "14",
    };
    const pathParts = currentPath && currentPath.split("/");
    const pathKey = routeKeyMapping[pathParts[1]] || "1";
    setKey(pathKey);
  }, [currentPath]);

  const currentCompanyUser =
    user &&
    user.company_users &&
    user.company_users.length > 0 &&
    user.company_users.find((company) => company.status === "ACTIVE");

  // menu items
  const itemData = [
    {
      label: "Dashboard",
      icon: key === "1" ? dashboardActive : dashboard,
      key: "1",
      link: "/",
    },
    {
      label: "Inventory",
      icon: key === "2" ? inventoryActive : inventory,
      key: "2",
      link: "/inventory",
    },
    {
      label: "CRM",
      icon: key === "14" ? crm : crm,
      key: "14",
      subItems: [
        {
          key: "14.1",
          label: "Lead Management",
          link: "/lead-management",
        },
        {
          key: "14.2",
          label: "Opportunities",
          link: "/opportunity-management",
        },
        {
          key: "14.3",
          label: "Events",
          link: "/events",
        },
        {
          key: "14.4",
          label: "Contacts",
          link: "/contacts",
        },
      ],
    },
    {
      label: "Purchase Orders",
      icon: key === "3" ? purchaseOrdersActive : purchaseOrders,
      key: "3",
      link: "/purchase-orders",
    },
    {
      label: "Sales Orders",
      icon: key === "4" ? salesActive : sales,
      key: "4",
      subItems: [
        {
          key: "4.1",
          label: "My Sales Orders",
          link: "/my-sales-orders",
        },
        {
          key: "4.2",
          label: "Sales Invoices",
          link: "/sales-invoices",
        },
      ],
    },
    {
      label: "Task",
      icon: key === "5" ? taskActive : task,
      key: "5",
      link: "/task",
    },
    {
      label: "Delivery",
      icon: key === "6" ? deliveryActive : delivery,
      key: "6",
      link: "/delivery",
    },
    {
      label: "Finance",
      icon: key === "7" ? financeActive : finance,
      key: "7",
      link: "/finance",
    },
    {
      label: "Credit / Debit Notes",
      icon: key === "8" ? creditActive : credit,
      key: "8",
      link: "/credit-notes",
    },

    {
      label: "HR",
      icon: key === "10" ? hrActive : hr,
      key: "10",
      link: "/hr",
    },
    {
      label: "Sales Force",
      icon: key === "11" ? beatActive : beat,
      key: "11",

      subItems: [
        {
          key: "11.2",
          label: "Beat Planning",
          link: "/beat-planning",
        },
      ],
    },
    {
      label: "Master Data",
      icon: key === "12" ? masterDataActive : masterData,
      key: "12",
      link: "/master-data",
      subItems: [
        {
          key: "12.1",
          label: "Event Masters",
          link: "/event-masters",
        },
        {
          key: "12.4",
          label: "Business Partner",
          link: "/business-partners",
        },
        {
          key: "12.2",
          label: "Product Masters",
          link: "/product-masters",
        },
        {
          key: "12.3",
          label: "General Masters",
          link: "/general-masters",
        },
      ],
    },
    {
      label: "Preference",
      icon: key === "13" ? settingActive : setting,
      key: "13",
      link: "/setting",
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (user && user.company_users) {
      const shouldHide =
        user.company_users.length === 0 ||
        user.company_users.every(
          (companyUser) => companyUser.status !== "ACTIVE"
        );
      setHide(shouldHide);
    } else {
      setHide(true);
    }
  }, [user]);


  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const { pathname } = useLocation();

  const isActive = (path) => pathname.startsWith(path);

  const items = [];
  itemData.forEach((data) => {
    const isActiveItem =
      isActive(data.link) ||
      (data &&
        data.subItems &&
        data.subItems.length > 0 &&
        data.subItems.find((subItem) => isActive(subItem.link)));
    const item = getItem(
      data.subItems ? (
        <div
          className={`${key === "4" && "text-black/90 font-semibold"} ${
            isActive ? "sub-active" : ""
          }`}
          onClick={() => console.log("Purchase Orders")}
        >
          {data.label}
        </div>
      ) : (
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "sidebar-active" : ""
          }
          to={data.link}
        >
          {data.label}
        </NavLink>
      ),
      data.key,
      <img width={20} src={data.icon} alt={data.label} />,
      data.subItems && data.subItems.length > 0
        ? data.subItems.map((subItem) =>
            getItem(
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active-link" : ""
                }
                to={subItem.link}
              >
                {subItem.label}
              </NavLink>,
              `${data.key}.${subItem.key}`,
              <GoDot
                color={`${currentPath === subItem.link ? "#6883FD" : "black"}`}
              />
            )
          )
        : null
    );
    items.push(item);
  });

  //  profile itmes
  const menuItems = [
    {
      key: "1",
      label: <p>Edit Profile</p>,
      icon: <EditOutlined />,
      onClick: () =>
        navigate("/update-user", {
          state: {
            userId: currentCompanyUser?.id,
            accountType: "OWN_ACCOUNT",
          },
        }),
    },

    {
      key: "3",
      label: (
        <div className="flex items-center" onClick={showModal}>
          <LogoutOutlined className="pr-2.5" />
          Logout
        </div>
      ),
    },
  ];

  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  //TODO: Fix Bug for navigating

  const arr = [
    "/event-overview",
    "/attendees",
    "/exhibitors",
    "/sessions",
    "/speakers",
    "/sponsor",
    "/event-inventory",
  ];

  const isOverview = useMemo(() => arr.includes(currentPath), [currentPath]);
  const eventId = useMemo(() => getEventId(), []);

  useEffect(() => {
    if (!isOverview) {
      if (eventId) {
        dispatch(clearEventId({ navigate, currentPath, arr }));
      }
    }
  }, [isOverview, dispatch, navigate]);

  return (
    <>
      <Layout className="overflow-hidden max-h-screen">
        <Sider
          breakpoint="xxl"
          trigger={null}
          width={250}
          collapsible
          collapsed={collapsed}
          onCollapse={(c) => {
            setCollapsed(collapsed);
          }}
          className={`transition-all ${
            hide ? "hidden" : "block"
          } pb-6 border-r border-bColor py-3 side-menu duration-1000 max-h-screen fixed overflow-auto ease-in`}
          style={{
            background: "#fff",
            height: "100vh",
          }}
        >
          <div
            className={` py-4 pb-2 mx-auto ${
              collapsed ? "px-[8px]" : "px-[24px]"
            }`}
          >
            <div
              className={`flex items-center gap-x-4 ${
                collapsed ? "justify-center" : ""
              }`}
            >
              <img
                alt="Logo"
                className="rounded-xl"
                src={collapsed ? leftLogo : mainLogo}
              />
            </div>
          </div>
          <Menu
            className="pt-7 pb-5"
            theme={"light"}
            mode="inline"
            selectedKeys={[key]}
            items={items}
          />
        </Sider>
        <Layout className="layout-main custom-scrollbar max-h-screen">
          <Header
            style={{
              display: hide ? "none" : "flex",
            }}
            className="p-0 border-b border-bColor flex justify-between items-center px-2 bg-white 2xl:h-[60px] xl:h-[50px] h-[50px] !important"
          >
            <div className="flex items-center">
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </div>
            <Dropdown
              className="pr-4 cursor-pointer flex items-center justify-center"
              menu={{
                items: menuItems,
              }}
              trigger={"hover"}
            >
              <div className="hover:text-black">
                <Space>
                  <div className="flex items-center justify-center h-[35px] w-[35px] rounded-full">
                    <img
                      className="rounded-full h-full w-full"
                      src={currentCompanyUser?.profile_pic}
                      alt="User"
                    />
                  </div>
                </Space>
              </div>
            </Dropdown>
          </Header>
          <Content
            className={`${hide ? "bg-white" : "main-bg"}   min-h-64 ${
              currentPath !== "/" && !isOverview && "px-4 py-6"
            }  overflow-auto`}
          >
            <div
              className={`my-0  ${
                currentPath !== "/" && !isOverview
                  ? "2xl:mx-6 xl:mx-3 lg:mx-3 mx-3"
                  : "relative"
              }`}
            >
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
      <Modal
        centered
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Result
          className="pb-7"
          status="warning"
          title="Are you sure you want to logout?"
          extra={[
            <Button
              className="outline-button-border"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              className="mx-2 filled-button"
              key="logout"
              onClick={() => {
                dispatch(actionLogout({ navigate, setVisible }));
              }}
            >
              Logout
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
};
export default Root;
