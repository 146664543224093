import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { handleGetResponse, handlePostResponse } from "./commonService";
import { toast } from "sonner";
import {
  extractFilename,
  handleErrorMessage,
} from "../../components/helper/common-function";

export const actionGetEvents = createAsyncThunk(
  "actionGetEvents",
  async ({
    offset = 0,
    search = "",
    orderByColumn = "",
    order = "",
    eventStatus = "",
  } = {}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/events?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn && order ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }${eventStatus ? `&event_status=${eventStatus}` : ""}`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

export const actionAddEvent = createAsyncThunk(
  "actionAddEvent",
  async ({ req, navigate }) => {
    try {
      const res = await axios.post(`${BASE_URL}/events`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/events");
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateEvent = createAsyncThunk(
  "actionUpdateEvent",
  async ({ id, req, navigate }) => {
    try {
      const res = await axios.patch(`${BASE_URL}/events/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/events");
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteEvent = createAsyncThunk(
  "actionDeleteEvent",
  async ({ id, offset, search }, { dispatch }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/events/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        dispatch(actionGetEvents({ offset, search }));
        return id;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionCancleEvent = createAsyncThunk(
  "actionCancleEvent",
  async ({ id, eventStatus }) => {
    try {
      const res = await axios.put(`${BASE_URL}/events/${id}`, {
        event_status: eventStatus,
      });
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//  Action to get All Event
export const actionGetAllEvents = createAsyncThunk(
  "actionGetAllEvents",
  async () => {
    return handleGetResponse(`events?getall=YES`);
  }
);
export const actionGetEventDetail = createAsyncThunk(
  "actionGetEventDetail",
  async ({ id }) => {
    return handleGetResponse(`events/${id}`);
  }
);

export const actionClearAllEventsData = createAsyncThunk(
  "actionClearAllEventsData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

// Event Overview
export const actionGetEventOverview = createAsyncThunk(
  "actionGetEventOverview",
  async (eventId) => {
    return handleGetResponse(`events/overview/${eventId}`);
  }
);

//TODO: Event Speaker Starts

// event speakers
//  Action to get  Event Speaker
export const actionGetEventSpeakers = createAsyncThunk(
  "actionGetEventSpeakers",
  async ({ eventId, offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-speaker/${eventId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Clear all Event Speakers
export const actionClearAllEventSpeakerData = createAsyncThunk(
  "actionClearAllEventSpeakerData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//  Action to get All Event Speakers
export const actionGetAllEventSpeakers = createAsyncThunk(
  "actionGetAllEventSpeakers",
  async ({ eventId }) => {
    return handleGetResponse(`event-speaker/${eventId}?getall=YES`);
  }
);

//  Action to get Single Event Speaker
export const actionEventSpeakerDetail = createAsyncThunk(
  "actionEventSpeakerDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-speaker/findone/${id}/${eventId}`);
  }
);

//  Action to add Event Speaker
export const actionAddEventSpeaker = createAsyncThunk(
  "actionAddEventSpeaker",
  async ({ req, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-speaker`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        dispatch(actionGetEventSpeakers({ offset: 0, eventId: req.event_id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Event Speaker
export const actionUpdateEventSpeaker = createAsyncThunk(
  "actionUpdateEventSpeaker",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-speaker/${id}/${req.event_id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();

        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Event Speaker
export const actionDeleteEventSpeaker = createAsyncThunk(
  "actionDeleteEventSpeaker",
  async ({ id, event_id }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-speaker/${id}/${event_id}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
//TODO: Event Speaker Ends

//TODO: Attendees Start
// Action to get Event Attendees
export const actionGetEventAttendees = createAsyncThunk(
  "actionGetEventAttendees",
  async ({ eventId, offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-attendee/event-attendees/${eventId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Clear all Event Attendee Data
export const actionClearAllEventAttendeeData = createAsyncThunk(
  "actionClearAllEventAttendeeData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

// Action to get All Event Attendees
export const actionGetAllEventAttendees = createAsyncThunk(
  "actionGetAllEventAttendees",
  async ({ eventId }) => {
    return handleGetResponse(
      `event-attendee/event-attendees/${eventId}?getall=YES`
    );
  }
);

// Action to get Single Event Attendee
export const actionEventAttendeeDetail = createAsyncThunk(
  "actionEventAttendeeDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-attendee/${id}/${eventId}`);
  }
);

// Action to add Event Attendee
export const actionAddEventAttendee = createAsyncThunk(
  "actionAddEventAttendee",
  async ({ req, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-attendee`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // dispatch(actionGetEventAttendees({ offset: 0, eventId: req.event_id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        // throw new Error(customMsg);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to update Event Attendee
export const actionUpdateEventAttendee = createAsyncThunk(
  "actionUpdateEventAttendee",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-attendee/${id}/${req.event_id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Event Attendee
export const actionDeleteEventAttendee = createAsyncThunk(
  "actionDeleteEventAttendee",
  async ({ id, eventId }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-attendee/${id}/${eventId}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Change Status
export const actionAttendeeChangeStatus = createAsyncThunk(
  "actionAttendeeChangeStatus",
  async ({ id, values, eventId, handleCancelAttendeeStatus }) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/event-attendee/update-status/${id}/${eventId}`,
        values
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancelAttendeeStatus && handleCancelAttendeeStatus();
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//TODO: Attendees Ends

//TODO: Event Exhibitors Start
// event exhibitors
//  Action to get  exhibitors
export const actionGetEventExhibitors = createAsyncThunk(
  "actionGetEventExhibitors",
  async ({ eventId, offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-exhibitor/${eventId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Clear all Exhibitors
export const actionClearAllEventExhibitorsData = createAsyncThunk(
  "actionClearAllEventExhibitorsData",
  async ({ setExportAll }) => {
    if (setExportAll) {
      setExportAll(false);
    }
    return "";
  }
);

// Action to get All Exhibitors
export const actionGetAllEventExhibitors = createAsyncThunk(
  "actionGetAllEventExhibitors",
  async ({ eventId }) => {
    return handleGetResponse(`event-exhibitor/${eventId}?getall=YES`);
  }
);

// Action to get Single Event exhibitor data
export const actionEventExhibitorDetail = createAsyncThunk(
  "actionEventExhibitorDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-exhibitor/${id}/${eventId}`);
  }
);

//  Action to add Event Exhibitors
export const actionAddEventExhibitor = createAsyncThunk(
  "actionAddEventExhibitor",
  async ({ req, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-exhibitor`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // dispatch(
        //   actionGetEventExhibitors({ offset: 0, eventId: req.event_id })
        // );
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Event exhibitor
export const actionDeleteEventExhibitor = createAsyncThunk(
  "actionDeleteEventExhibitor",
  async ({ id, event_id }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-exhibitor/${id}/${event_id}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Event exhibitor
export const actionUpdateEventExhibitor = createAsyncThunk(
  "actionUpdateEventExhibitor",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-exhibitor/${id}/${req.event_id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();

        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// clear Event Id
export const clearEventId = createAsyncThunk(
  "clearEventId",
  async ({ navigate }, { dispatch }) => {
    localStorage.removeItem("event_id");
    return true;
  }
);

// event all  inventory
export const actionGetAllEventInventory = createAsyncThunk(
  "actionGetAllEventInventory",
  async (eventId) => {
    return handleGetResponse(`event-inventory/${eventId}?getall=YES`);
  }
);

// event inventory
export const actionGetEventInventory = createAsyncThunk(
  "actionGetEventInventory",
  async ({ eventId, offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-inventory/${eventId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//  Action to get Single Event Speaker
export const actionEventInventoryDetail = createAsyncThunk(
  "actionEventInventoryDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-inventory/${id}/${eventId}`);
  }
);

//  Action to add Event Speaker
export const actionAddEventInventory = createAsyncThunk(
  "actionAddEventInventory",
  async ({ req, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-inventory`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        dispatch(actionGetEventInventory({ offset: 0, eventId: req.event_id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Event Speaker
export const actionUpdateEventInventory = createAsyncThunk(
  "actionUpdateEventInventory",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-inventory/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
// Action to delete Event Inventory
export const actionDeleteEventInventory = createAsyncThunk(
  "actionDeleteEventInventory",
  async ({ id, event_id, handleCancel }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-inventory/${id}/${event_id}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // if (error?.response?.data?.message) {
      //   toast.error(error?.response?.data?.message, 5);
      // } else {
      //   toast.error(error?.message, 5);
      // }
      // return false;
      handleErrorMessage(error);
    }
  }
);

//TODO: Session Start
//  Action to add Session
export const actionAddSession = createAsyncThunk(
  "actionAddSession",
  async ({ req, handleCancel }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-session`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetEventSessions = createAsyncThunk(
  "actionGetEventSessions",
  async ({ id }) => {
    return handleGetResponse(`event-session/${id}?getall=YES`);
  }
);

//  Action to get Single Event Session
export const actionEventSessionDetail = createAsyncThunk(
  "actionEventSessionDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-session/${id}/${eventId}`);
  }
);

// Action to Update Event Session
export const actionUpdateEventSession = createAsyncThunk(
  "actionUpdateEventSession",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-session/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();

        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Event Session
export const actionDeleteEventSession = createAsyncThunk(
  "actionDeleteEventSession",
  async ({ id, event_id, handleCancel }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-session/${id}/${event_id}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
//TODO: Session End

export const actionClearAllEventInventory = createAsyncThunk(
  "actionClearAllEventInventory",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionDownloadFile = createAsyncThunk(
  "actionDownloadFile",
  async (blobFile) => {
    const newBlobFile = extractFilename(blobFile);
    try {
      axios({
        url: `${BASE_URL}/uploads/download/${newBlobFile}`, // replace with your server-side endpoint
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", newBlobFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const descreaseCount = createAsyncThunk(
  "descreaseCount",
  async (count) => {
    return count - 1;
  }
);

//TODO: Event Sponsor Start
// event Sponsor
//  Action to get  Sponsor
export const actionGetEventSponsor = createAsyncThunk(
  "actionGetEventSponsor",
  async ({ eventId, offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-sponsor/${eventId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Clear all Sponsor
export const actionClearAllEventSponsor = createAsyncThunk(
  "actionClearAllEventSponsor",
  async ({ setExportAll }) => {
    if (setExportAll) {
      setExportAll(false);
    }
    return "";
  }
);

// Action to get All Sponsor
export const actionGetAllEventSponsor = createAsyncThunk(
  "actionGetAllEventSponsor",
  async ({ eventId }) => {
    return handleGetResponse(`event-sponsor/${eventId}?getall=YES`);
  }
);

// Action to get Single Event Sponsor data
export const actionEventSponsorDetail = createAsyncThunk(
  "actionEventSponsorDetail",
  async ({ id, eventId }) => {
    return handleGetResponse(`event-sponsor/${id}/${eventId}`);
  }
);

//  Action to add Event Sponsor
export const actionAddEventSponsor = createAsyncThunk(
  "actionAddEventSponsor",
  async ({ req, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/event-sponsor`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        dispatch(actionGetEventSponsor({ offset: 0, eventId: req.event_id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to delete Event Sponsor
export const actionDeleteEventSponsor = createAsyncThunk(
  "actionDeleteEventSponsor",
  async ({ id, event_id }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/event-sponsor/${id}/${event_id}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Event Sponsor
export const actionUpdateEventSponsor = createAsyncThunk(
  "actionUpdateEventSponsor",
  async ({ id, req, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-sponsor/${id}/${req.event_id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
