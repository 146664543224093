import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  TimePicker,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import plusIcon from "../../../assets/img/add-button.svg";
import editPrimaryWhite from "../../../assets/img/editPrimaryWhite.svg";
import sessionIcon from "../../../assets/img/sessionIcon.svg";
import modalSpeakers from "../../../assets/img/modalSpeakers.svg";
import plusSvg from "../../../assets/img/plus.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddEventSpeaker,
  actionAddSession,
  actionDeleteEventSession,
  actionEventSessionDetail,
  actionGetAllEventSpeakers,
  actionUpdateEventSession,
} from "../../../store/services/eventService";
import { extractFilename, getEventId } from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";
import SessionCalenderView from "./SessionCalenderView";
import { RxCalendar } from "react-icons/rx";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Sessions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddSpeakerModalOpen, setIsAddSpeakerModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [speakerProfilePicUrl, setSpeakerProfilePicUrl] = useState();
  const [description, setDescription] = useState("");
  const [sessionDate, setSessionDate] = useState(null);
  const [sessionSpeaker, setSessionSpeaker] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [sessionData, setSessionData] = useState(false);
  const [selectedDay, setSelectedDay] = useState("2024-07-01");
  const [disableSessionEndTime, setDisableSessionEndTime] = useState(true);
  const [eventSessionId, setEventSessionId] = useState(null);
  const [form] = Form.useForm();
  const [addSpeakerModalForm] = Form.useForm();
  const dispatch = useDispatch();
  const eventId = getEventId();
  let editorRef = useRef(null);
  let sliderRef = useRef(null);

  const {
    getAllEventSpeakersLoader,
    allEventSpeakersData,
    addSessionLoader,
    addEventSpeakerLoader,
    eventsOverview,
    getEventSessionDetail,
    getEventSessionDetailLoader,
    updateEventSessionLoader,
    deleteEventSessionLoader,
  } = useSelector((state) => state.events);

  const eventStartDate = dayjs(eventsOverview?.event?.start_date);
  const eventEndDate = dayjs(eventsOverview?.event?.end_date);
  const eventStartTime = dayjs(eventsOverview?.event?.start_time);
  const eventEndTime = dayjs(eventsOverview?.event?.end_time);
  const combinedStartDateTime = `${eventsOverview?.event?.start_date}T${eventsOverview?.event?.start_time}`;
  const combinedEndDateTime = `${eventsOverview?.event?.end_date}T${eventsOverview?.event?.end_time}`;

  useEffect(() => {
    if (eventsOverview?.event?.start_date) {
      setSelectedDay(
        dayjs(eventsOverview.event.start_date).format("YYYY-MM-DD")
      );
    }
  }, [eventsOverview?.event?.start_date]);

  // Generate array of dates from start to end, including start and end dates
  const datesArray = Array.from(
    { length: eventEndDate.diff(eventStartDate, "day") + 1 },
    (_, index) => eventStartDate.add(index, "day").format("YYYY-MM-DD")
  );

  const showModal = (date, time) => {
    if (date && time) {
      const selectedTime = dayjs(time, "HH:mm");
      const selectedDate = dayjs(date, "YYYY-MM-DD");
      form.setFieldsValue({
        session_date: dayjs(date, "YYYY-MM-DD"),
        session_start_time: dayjs(time, "HH:mm"),
      });
      setStartTime(selectedTime);
      setSessionDate(selectedDate);
    }
    setDisableSessionEndTime(true);
    setIsModalOpen(true);
    dispatch(actionGetAllEventSpeakers({ eventId }));
  };

  useEffect(() => {
    if (startTime) {
      setDisableSessionEndTime(false);
    }
  }, [startTime]);

  const showEditSessionModal = (id) => {
    setIsModalOpen(true);
    dispatch(actionGetAllEventSpeakers({ eventId }));
    setSessionData(true);
    setEventSessionId(id);
  };

  useEffect(() => {
    if (sessionData) {
      dispatch(actionEventSessionDetail({ id: eventSessionId, eventId }));
    }
  }, [dispatch, sessionData]);

  useEffect(() => {
    if (sessionData && getEventSessionDetail) {
      form.setFieldsValue({
        session_name: getEventSessionDetail.session_name,
        session_date: dayjs(getEventSessionDetail.session_date, "YYYY-MM-DD"),
        session_start_time: dayjs(
          getEventSessionDetail.session_start_time,
          "HH:mm"
        ),
        session_end_time: dayjs(
          getEventSessionDetail.session_end_time,
          "HH:mm"
        ),
        session_location: getEventSessionDetail.session_location
          ? getEventSessionDetail.session_location
          : "",
        // session_speakers: speakerOptions,
        session_speakers:
          getEventSessionDetail.event_speakers &&
          getEventSessionDetail.event_speakers.length
            ? getEventSessionDetail.event_speakers.map(
                (data) => data.event_speaker_id
              )
            : [],
      });
    }
  }, [getEventSessionDetail]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setDescription("");
    if (editorRef.current) {
      editorRef.current.setContent("");
    }
    if (sessionData) {
      setSessionData(false);
    }
    setDisableSessionEndTime(true);
  };
  const onSessionDateChange = (value) => {
    setSessionDate(value);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    setDisableSessionEndTime(false);
    form.setFieldsValue({
      session_end_time: "",
    });
  };

  const disabledEndTime = () => {
    if (!startTime) {
      return {};
    }

    const startHour = dayjs(startTime).hour();
    const startMinute = dayjs(startTime).minute();

    return {
      disabledHours: () => {
        let hours = [];
        for (let i = 0; i < startHour; i++) {
          hours.push(i);
        }
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === startHour) {
          let minutes = [];
          for (let i = 0; i <= startMinute; i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      },
    };
  };

  const showAddSpeakerModal = () => {
    setIsAddSpeakerModalOpen(true);
    setIsModalOpen(false);
  };

  const handleCancelAddSpeakerModal = () => {
    setIsAddSpeakerModalOpen(false);
    addSpeakerModalForm.resetFields();
    setSpeakerProfilePicUrl();
    setIsModalOpen(true);
    dispatch(actionGetAllEventSpeakers({ eventId }));
  };

  const SpeakerDataAll = [];
  allEventSpeakersData &&
    allEventSpeakersData.map((data) =>
      SpeakerDataAll.push({
        label: data.speaker_name,
        value: data.event_speaker_id,
      })
    );

  const handleChangeSpeaker = (value) => {
    setSessionSpeaker(value);
  };

  const handleEditorChange = (newContent, editor) => {
    editorRef.current = editor;
    setDescription(newContent);
  };

  const handleAddSession = (values) => {
    const req = {
      ...values,
      event_id: eventId,
      session_date: dayjs(sessionDate).format("YYYY-MM-DD"),
      session_start_time: dayjs(values.session_start_time).format("HH:mm"),
      session_end_time: dayjs(values.session_end_time).format("HH:mm"),
      session_description: description,
    };
    dispatch(
      actionAddSession({
        req,
        handleCancel: handleCancel,
      })
    );
  };

  const handleUpdateSession = (values) => {
    const req = {
      ...values,
      event_id: eventId,
      session_date: dayjs(values.session_date).format("YYYY-MM-DD"),
      session_start_time: dayjs(values.session_start_time).format("HH:mm"),
      session_end_time: dayjs(values.session_end_time).format("HH:mm"),
      session_description: description
        ? description
        : getEventSessionDetail?.session_description,
    };
    dispatch(
      actionUpdateEventSession({
        id: getEventSessionDetail.event_session_id,
        req,
        handleCancel,
      })
    );
  };

  const handleChangeSpeakerPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setSpeakerProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleAddEventSpeaker = (values) => {
    const data = {
      ...values,
      speaker_image: speakerProfilePicUrl
        ? extractFilename(speakerProfilePicUrl)
        : "",
      event_id: eventId,
    };
    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };
    dispatch(
      actionAddEventSpeaker({
        req,
        handleCancel: handleCancelAddSpeakerModal,
      })
    );
  };

  const handleDeleteSession = () => {
    dispatch(
      actionDeleteEventSession({
        id: getEventSessionDetail?.event_session_id,
        event_id: eventId,
        handleCancel,
      })
    );
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <>
      <EventDetailWrapper>
        <Flex gap="middle" justify="space-between" className="mb-4">
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={sessionIcon}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">
              Sessions
            </h1>
          </div>

          <div className="flex items-center gap-x-5">
            <div className="mx-2 flex items-center ">
              <RxCalendar size={20} />{" "}
              <div className="min-w-32 text-center">
                {dayjs(selectedDay).format("ddd MMM DD, YYYY")}
              </div>
            </div>
            {datesArray && datesArray.length > 3 ? (
              <div className="flex items-center gap-x-3">
                <FaAngleLeft
                  size={15}
                  className="cursor-pointer text-[#787878]"
                  onClick={previous}
                />
                <div className="slider-container text-center max-w-72 bg-white my-10 border border-[#dddae9]">
                  <Slider
                    ref={(slider) => {
                      sliderRef = slider;
                    }}
                    {...settings}
                  >
                    {datesArray &&
                      datesArray.map((date, index) => (
                        <div
                          key={index}
                          className={`border-r border-[#dddae9] p-2 hover:cursor-pointer hover:bg-[#f4f6fa] ${
                            selectedDay == date && "bg-[#f4f6fa]"
                          }`}
                          onClick={() => setSelectedDay(date)}
                        >
                          <h3>Day {index + 1}</h3>
                        </div>
                      ))}
                  </Slider>
                </div>
                <FaAngleRight
                  size={15}
                  className="cursor-pointer text-[#787878]"
                  onClick={next}
                />
              </div>
            ) : (
              <div className="slider-container flex text-center max-w-72 bg-white my-10 border border-[#dddae9]">
                {datesArray &&
                  datesArray.map((date, index) => (
                    <div
                      key={index}
                      className={`border-r border-[#dddae9] p-2 hover:cursor-pointer hover:bg-[#f4f6fa] ${
                        selectedDay === date ? "bg-[#f4f6fa]" : ""
                      } ${index === datesArray.length - 1 ? "border-r-0" : ""}`}
                      onClick={() => setSelectedDay(date)}
                    >
                      <h3>Day {index + 1}</h3>
                    </div>
                  ))}
              </div>
            )}

            {/* <div className="segmented-custom flex items-center">
              {[
                "2024-07-01",
                "2024-07-02",
                "2024-07-03",
                "2024-07-04",
                "2024-07-05",
              ].map((date, index) => (
                <div
                  key={index}
                  className={`${
                    selectedDay === date
                      ? "segmented-label-selected"
                      : "segmented-label"
                  }`}
                  onClick={() => setSelectedDay(date)}
                >
                  Day {index + 1}
                </div>
              ))}
            </div> */}
            <Button
              onClick={showModal}
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            >
              Add Session
            </Button>
            <Modal
              loading={sessionData ? getEventSessionDetailLoader : false}
              centered
              onOk={() => handleCancel()}
              onCancel={() => handleCancel()}
              footer={false}
              width={700}
              title=""
              open={isModalOpen}
            >
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={sessionIcon}
                  alt="image"
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
                  {sessionData ? "Update Session" : "Add Session"}
                </h1>
              </div>

              <Form
                form={form}
                onFinish={sessionData ? handleUpdateSession : handleAddSession}
                layout="vertical"
                className="mt-[20px] mb-0"
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      name="session_name"
                      label="Session Name"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please input session name!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Session"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Session Date"
                      name="session_date"
                      rules={[
                        {
                          required: true,
                          message: "Please select session date!",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-full"
                        placeholder="DD/MM/YYYY"
                        format={"DD/MM/YYYY"}
                        onChange={onSessionDateChange}
                        minDate={eventStartDate}
                        maxDate={eventEndDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Session Start Time"
                      name="session_start_time"
                      rules={[
                        {
                          required: true,
                          message: "Please select session start time!",
                        },
                      ]}
                    >
                      <TimePicker
                        className="w-full"
                        format="HH:mm"
                        onChange={handleStartTimeChange}
                        // disabledTime={disabledStartTime}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Session End Time"
                      name="session_end_time"
                      rules={[
                        {
                          required: true,
                          message: "Please select session end time!",
                        },
                      ]}
                    >
                      <TimePicker
                        className="w-full"
                        format={"HH:mm"}
                        disabled={sessionData ? "" : disableSessionEndTime}
                        disabledTime={disabledEndTime}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="session_location"
                      label="Session Address"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please input session address!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ background: "white" }}
                        placeholder="Enter Address"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 mt-2 z-10 text-brand"
                      onClick={showAddSpeakerModal}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Speaker
                      </h1>
                    </div>
                    <Form.Item
                      className="mt-2"
                      label="Speaker Selection"
                      name="session_speakers"
                    >
                      <Select
                        allowClear
                        showSearch
                        mode="multiple"
                        placeholder="Speaker Selection"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={handleChangeSpeaker}
                        loading={getAllEventSpeakersLoader}
                        disabled={getAllEventSpeakersLoader}
                        options={SpeakerDataAll}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <p className="ant-form-item-label mb-2">
                      Session Description
                    </p>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      initialValue={
                        sessionData
                          ? getEventSessionDetail?.session_description
                          : ""
                      }
                      init={{
                        // plugins:
                        //   "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate  mentions  tableofcontents footnotes autocorrect typography inlinecss markdown",
                        // toolbar:
                        //   "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                        plugins: "link lists spellchecker",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough |  link | bullist numlist checklist",
                        tinycomments_mode: "embedded",
                        tinycomments_author: "CRM HARBOR",
                        branding: false,
                        selector: "textarea",
                        menubar: false,
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  </Col>
                  <Col span={24}>
                    <div className=" flex justify-center mt-[40px]">
                      <Form.Item className="mb-0">
                        <Button
                          onClick={handleCancel}
                          type="button"
                          className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                        >
                          Cancel
                        </Button>
                      </Form.Item>

                      {sessionData ? (
                        <div className="ml-4">
                          <Form.Item className="mb-0">
                            <Popconfirm
                              okButtonProps={{
                                loading: deleteEventSessionLoader,
                              }}
                              placement="top"
                              description="Are you sure to delete this session?"
                              onConfirm={handleDeleteSession}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                loading={deleteEventSessionLoader}
                                className="delete-btn min-h-[45px] min-w-[120px]"
                              >
                                Delete
                              </Button>
                            </Popconfirm>
                          </Form.Item>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="ml-4">
                        <Form.Item className="mb-0">
                          <Button
                            loading={
                              addSessionLoader || updateEventSessionLoader
                            }
                            type="primary"
                            className="root-btn min-h-[45px] min-w-[120px]"
                            htmlType="submit"
                          >
                            {sessionData ? "Update" : "Save"}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Modal>
            {/* add speaker modal */}
            <Modal
              centered
              destroyOnClose={() => form.resetFields()}
              onOk={() => handleCancelAddSpeakerModal()}
              onCancel={() => handleCancelAddSpeakerModal()}
              footer={false}
              width={500}
              open={isAddSpeakerModalOpen}
            >
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={modalSpeakers}
                  alt="image"
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
                  Add Speaker
                </h1>
              </div>

              <Form
                form={addSpeakerModalForm}
                onFinish={handleAddEventSpeaker}
                layout="vertical"
                className="mt-[20px] mb-0"
              >
                <Row gutter={24}>
                  <Col span={24} align="middle">
                    <Form.Item
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Speaker Image!",
                        },
                      ]}
                    >
                      {speakerProfilePicUrl ? (
                        <div className="relative border-brand  h-[102px]  w-[102px] rounded-[6px] ">
                          <img
                            src={speakerProfilePicUrl}
                            alt="avatar"
                            className="h-full w-full rounded-[6px]"
                          />

                          <ImgCrop
                            rotationSlider
                            aspect={1 / 1}
                            fillColor="white"
                          >
                            <Upload
                              id=""
                              name="file"
                              listType="picture-card"
                              className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              openFileDialogOnClick={true}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("crmWebToken"),
                              }}
                              onChange={handleChangeSpeakerPic}
                            >
                              <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                                <Image
                                  width={15}
                                  height={15}
                                  src={editPrimaryWhite}
                                  className="rounded-lg"
                                  preview={false}
                                />
                              </div>
                            </Upload>
                          </ImgCrop>
                        </div>
                      ) : (
                        <ImgCrop
                          aspect={1 / 1}
                          rotationSlider
                          fillColor="white"
                        >
                          <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeSpeakerPic}
                          >
                            <button
                              style={{
                                border: 0,
                                background: "none",
                              }}
                              type="button"
                            >
                              {loading ? <LoadingOutlined /> : <PlusOutlined />}
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </button>
                          </Upload>
                        </ImgCrop>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="speaker_name"
                      label="Speaker Name"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please input speaker name!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Speaker Name"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="speaker_bio"
                      label="Speaker Bio"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please input speaker bio!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Speaker Bio"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="speaker_company"
                      label="Speaker Company"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please input speaker company!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Speaker Company"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      normalize={(value) =>
                        value && value.replace(/[^+\d]/g, "")
                      }
                      name="speaker_contact"
                      label="Speaker Contact"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please input speaker contact!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Speaker Contact"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Speaker Email"
                      name="speaker_email"
                      rules={[
                        {
                          type: "email",
                          required: false,
                          message: "Please input speaker email!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Speaker Email" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div className="flex justify-center mt-[40px]">
                      <Form.Item className="mb-0">
                        <Button
                          onClick={() => handleCancelAddSpeakerModal()}
                          type="button"
                          className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                        >
                          Cancel
                        </Button>
                      </Form.Item>

                      <div className="ml-4">
                        <Form.Item className="mb-0">
                          <Button
                            loading={addEventSpeakerLoader}
                            type="primary"
                            className="root-btn min-h-[45px] min-w-[120px]"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </div>
        </Flex>

        <SessionCalenderView
          selectedDay={selectedDay}
          showModal={showModal}
          showEditSessionModal={showEditSessionModal}
        />
      </EventDetailWrapper>
    </>
  );
};

export default Sessions;
