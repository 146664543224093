import {
  DeleteOutlined,
  EditOutlined,
  AntDesignOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Image,
  Modal,
  Pagination,
  Popover,
  Space,
  Table,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import beatIcon from "../../assets/img/beatIcon.svg";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital } from "../helper/common-function";
import EventDescriptionCard from "../common/EventDescriptionCard";
import CreateBeatPlanning from "./CreateBeatPlanning";
import { actionGetBeatPlanning } from "../../store/services/salesService";

const BeatPlanning = () => {
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [beatPlanId, setBeatPlanId] = useState("");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const clearDataRef = useRef(null);

  const { getBeatPlanningLoader, beatPlanData, beatPlansCounts } = useSelector(
    (state) => state.sales
  );

  const deleteLeadLoader = false;

  /* useEffect(() => {
    dispatch(actionGetBeatPlanning({ search }));
  }, [dispatch]); */

  const profiles = [
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
    "https://via.placeholder.com/50",
  ];

  /* const beatPlanData = Array.from({ length: 20 }, (_, index) => {
    const startDate = dayjs().add(index, "day").format("D MMM, YYYY");
    const endDate = dayjs()
      .add(index + 1, "day")
      .format("D MMM, YYYY");

    return {
      beat_planning_id: index + 1,
      beat_name: `Beat Name ${index + 1}`,
      start_date: startDate,
      end_date: endDate,
      employee: profiles,
      plan: `Plan ${index + 1}`,
    };
  });
 */
  const allBeatPlanData = beatPlanData;


  const columns = [
    {
      title: "Beat Name",
      key: "title",
      fixed: "left",
      dataIndex: "title",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,

      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setViewModalOpen(true);
            setBeatPlanId(data.beat_planning_id);
          }}
        >
          <p className="ml-2 text-brand">
            {getFirstLetterCapital(text)}
          </p>
        </div>
      ),
    },
    {
      title: "Plan Start",
      key: "start_date",
      dataIndex: "start_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <span className="ml-2">{dayjs(text).format("D MMM YYYY")}</span>
      ),
    },
    {
      title: "Plan",
      key: "plan",
      dataIndex: "plan",
      sorter: true,
      ellipsis: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Plan End",
      key: "end_date",
      dataIndex: "end_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) =>(
        <span className="ml-2">{dayjs(text).format("D MMM YYYY")}</span>
      ),
    },
    /*  {
      title: "Employee",
      key: "employee",
      dataIndex: "employee",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => (
        <Avatar.Group
          size={50}
          max={{
            count: 3,
            style: {
              color: "white",
              backgroundColor: "#6883FD",
              cursor: "pointer",
              fontSize: 15,
            },
            popover: { trigger: "click" },
          }}
        >
          {text.map((item, index) => (
            <Avatar key={index} src={item} />
          ))}
        </Avatar.Group>
      ),
    }, */
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      fixed: "right",
      width: 120,
      render: (text, data) => (
        <Dropdown.Button
          id="custom-drop"
          menu={{
            items: [
              {
                label: <span>Edit</span>,
                key: "Edit",
                icon: <EditOutlined />,
                /*    onClick: () => {
                  navigate("/create-lead", {
                    state: { beatDetailData: data, beatPlanId: data.lead_id },
                  });
                }, */
              },

              {
                label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                key: "Delete",
                icon: <DeleteOutlined style={{ color: "#ff4d4f" }} />,
                onClick: () => {
                  setIsDeleteModalOpen(data.beat_planning_id);
                  setBeatPlanId(data.beat_planning_id);
                },
              },
            ].filter(Boolean),
          }}
        >
          Action
        </Dropdown.Button>
      ),
    },
  ];

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    /*    dispatch(
        actionGetBeatPlanning({
          offset: offsetRef.current,
        })
      ); */
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // * TODO Call Api here
      /*  dispatch(
          actionGetBeatPlanning({
            offset: offsetRef.current,
            search: e.target.value,
  
          })
        ); */
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      // * TODO Call Api here
      /*  dispatch(
          actionGetBeatPlanning({
            offset: offsetRef.current,
            search: e.target.value,
  
          })
        ); */
    }
  };

  const onClickDownloadMenu = () => {
    // dispatch(actionGetAllBeatPlanning());
    // setExportAll(true);
  };

  const exportAllToExcel = async () => {
    /*    const data =
      ((await allBeatPlanData) &&
        allBeatPlanData.length > 0 &&
        allBeatPlanData.map((data) => ({
          "Beat Name": data.beat_name ? data.beat_name : "-",
          "Plan start date": data.start_date ? data.start_date : "-",
          Plan: data.plan ? data.plan : "-",
          "Plan end date": data.end_date ? data.end_date : "-",
          Company: data.company ? data.company : "-",
        }))) ||
      [];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "beat-planning.xlsx");
    dispatch(actionClearAllLeads({ setExportAll })); */

    console.log("export");
  };

  /* useEffect(() => {
    if (exportAll && allBeatPlanData && allBeatPlanData.length > 0) {
      exportAllToExcel();
    }
  }, [allBeatPlanData]); */

  const handleCloseModal = () => {
    setViewModalOpen(false);
    setBeatPlanId("");
  };

  const handleCloseDeleteModal = () => {
    setBeatPlanId("");
    setIsDeleteModalOpen(false);
  };

  const setClearDataRef = (clearDataFunction) => {
    clearDataRef.current = clearDataFunction;
  };

  const handleCloseCreateModal = () => {
    if (clearDataRef.current) {
      clearDataRef.current();
    }
    setCreateModalOpen(false);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };
/* 
    dispatch(
      actionGetBeatPlanning({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    ); */
  };

  const beatPlanDetail = {};

  return (
    <section className="main-wrapper">
      <PageTitle
        image={beatIcon}
        title="Beat Planning"
        buttonText="Create Beat Planning"
        handleClick={() => setCreateModalOpen(true)}
        type="btn"
      />

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
        // isAllowDownload={beatPlanDataCount !== undefined && beatPlanDataCount > 0}
      />

      <Table
        sticky={true}
        rowKey={(record) => record.beat_planning_id}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={beatPlanData}
        // dataSource={beatPlanData}
        // loading={getBeatPlanLoader || getAllBeatPlanLoader}
      />

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          // total={eventsDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            /*  dispatch(
                actionGetEvents({
                  offset: offsetRef.current,
                  search,
                })
              ); */
          }}
        />
      </div>

      {/* View Modal */}
      <Modal
        centered={true}
        // loading={getBeatPlanDetailLoader}
        footer={false}
        open={viewModalOpen}
        title=""
        width={600}
        destroyOnClose={handleCloseModal}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
      >
        {beatPlanDetail && (
          <>
            <div className="text-brand flex items-center gap-x-3">
              <Image
                src={beatIcon}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />

              <h1 className="lg:text-lg text-base  font-popinsRegular">
                View Beat Planning
              </h1>
            </div>
            <div className="flex flex-col pt-6 gap-y-4">
              <EventDescriptionCard
                title="Beat Name"
                value={beatPlanDetail.beat_name}
              />
              <EventDescriptionCard
                title="Plan Start Date"
                value={beatPlanDetail.start_date}
              />
              <EventDescriptionCard title="Plan" value={beatPlanDetail.plan} />
              <EventDescriptionCard
                title="Plan End Date"
                value={beatPlanDetail.end_date}
              />
            </div>
          </>
        )}
      </Modal>

      {/* ! Delete Modal */}
      <Modal
        className="delete-modal"
        centered
        onOk={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        afterClose={handleCloseDeleteModal}
        footer={false}
        width={600}
        title=""
        open={isDeleteModalOpen}
      >
        <div className="">
          <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
            Are you sure you want to Delete this beat planning?
          </h1>
          <div className="flex items-center pt-8 justify-end w-full gap-x-3">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCloseDeleteModal}
                type="button"
                className="gray-button gray-hover text-white hover:text-white  min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Cancel
              </Button>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                loading={deleteLeadLoader}
                type="primary"
                className="danger-btn  min-h-[45px] min-w-[120px]"
                htmlType="submit"
                onClick={() => {
                  dispatch(
                    actionDeleteLead({
                      id: beatPlanId,
                      handleCloseDeleteModal,
                    })
                  );
                }}
              >
                Delete
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>
      {/* Create Modal */}
      <Modal
        className="create-modal"
        centered
        onOk={handleCloseCreateModal}
        onCancel={handleCloseCreateModal}
        afterClose={handleCloseCreateModal}
        footer={false}
        width={900}
        title=""
        open={createModalOpen}
      >
        <CreateBeatPlanning
          setCreateModalOpen={setCreateModalOpen}
          setClearDataRef={setClearDataRef}
          handleCloseCreateModal={handleCloseCreateModal}
        />
      </Modal>
    </section>
  );
};

export default BeatPlanning;
