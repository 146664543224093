import {
  DeleteOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Image } from "antd";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const currentYear = dayjs().year();

// Function to retrieve userData from localStorage
export const getStoredUserData = () => {
  const storedUserData = localStorage.getItem("userData");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored userData:", error);
    }
  }
  return null;
};

// Function to retrieve event from localStorage
export const getEventId = () => {
  const storedUserData = localStorage.getItem("event_id");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored event id:", error);
    }
  }
  return null;
};

export const startTimer = (initialTime, setTimer) => {
  let timer = 59;
  setTimer && setTimer(timer);

  const interval = setInterval(() => {
    timer -= 1;
    setTimer && setTimer(timer);

    if (timer <= 0) {
      clearInterval(interval);
    }
  }, 1000);
  return interval;
};

export const getFormattedTimezone = (offset, name, timezone) => {
  return `(${offset}) ${name}  ( ${timezone} )`;
};

export const getAllKeys = (nodes) => {
  const keys = [];
  nodes.forEach((node) => {
    keys.push(node.key);
    if (node.children) {
      keys.push(...getAllKeys(node.children));
    }
  });
  return keys;
};

export const getFormattedDateTime = (date) => {
  return dayjs(date).format("DD MMM, YYYY HH:mm:ss A");
};

export const getFirstLetterCapital = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function decrypt(encryptedData) {
  const base64 = decodeURIComponent(encryptedData);
  const encrypted = CryptoJS.enc.Base64.parse(base64).toString(
    CryptoJS.enc.Utf8
  );
  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    "lu6^S-l(U!e=6$ho)1^x7gFcoD2C%^6(H)Ke8nwn0.uzGcNh~Z"
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function formatString(input) {
  // Remove all special characters except slashes and replace them with spaces
  let formatted = input?.replace(/[^a-zA-Z0-9\s\/]/g, " ");
  // Convert to lowercase
  formatted = formatted?.toLowerCase();
  // Capitalize the first letter of each word, except after slashes
  formatted = formatted?.replace(/(?:^|[\s\/])\w/g, (char) =>
    char.toUpperCase()
  );
  return formatted?.trim();
}

export const extractFilename = (url) => {
  const startStr = "uploads/";
  const startIndex = url.indexOf(startStr);

  if (startIndex !== -1) {
    const endIndex = url.indexOf("?", startIndex); // Find index of '?' after 'uploads/'
    if (endIndex !== -1) {
      return url.substring(startIndex + startStr.length, endIndex); // Extract substring after 'uploads/' to '?'
    } else {
      return url.substring(startIndex + startStr.length); // If no '?', extract substring after 'uploads/' to end of string
    }
  }
  return "";
};

export const getFullName = (firstName, lastName) => {
  // Check if both firstName and lastName are valid
  const isValidName = (name) =>
    name !== null && name !== undefined && name.trim() !== "";

  // Capitalize first letter of both names
  const formattedFirstName = isValidName(firstName)
    ? getFirstLetterCapital(firstName)
    : "";
  const formattedLastName = isValidName(lastName)
    ? getFirstLetterCapital(lastName)
    : "";

  if (formattedFirstName && formattedLastName) {
    return `${formattedFirstName} ${formattedLastName}`;
  }
  return formattedFirstName || formattedLastName || "-";
};

export const handleApiError = (error) => {
  if (Array.isArray(error)) {
    error.forEach((errMsg) => toast.error(errMsg));
  } else {
    toast.error(error.message || "An unexpected error occurred.");
  }
};

export const ALLOWED_SEARCH_REGEX = /^[a-zA-Z0-9 @.-]*$/;
export const isValidSearchString = (value) => ALLOWED_SEARCH_REGEX.test(value);

export const handleErrorMessage = (error) => {
  const message = error.response?.data?.message;

  if (Array.isArray(message)) {
    toast.error(message[0]);
  } else if (message && message !== null) {
    toast.error(message);
  } else {
    toast.error(error.message);
  }

  throw new Error(error.message);
};

export const formatUrl = (url) => {
  if (!url) return "";
  if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

export const renderFileIcon = (file) => {
  const extractFileName = extractFilename(file);
  if (
    extractFileName.endsWith("png") ||
    extractFileName.endsWith("jpg") ||
    extractFileName.endsWith("jpeg")
  ) {
    return <Image src={file} alt="docs" height={24} width={24} />;
  } else if (extractFileName.endsWith("pdf")) {
    return <FilePdfOutlined style={{ fontSize: "24px", color: "#ff6347" }} />;
  } else if (
    extractFileName.endsWith("doc") ||
    extractFileName.endsWith("docx")
  ) {
    return <FileWordOutlined style={{ fontSize: "24px", color: "#1e90ff" }} />;
  } else {
    return <FileTextOutlined style={{ fontSize: "24px", color: "#1890ff" }} />;
  }
};
