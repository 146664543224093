export const mode = "DEV";
// const mode = "PROD";

console.log(process.env.REACT_APP_ENV_MODE);

export const BASE_URL =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_PROD_BASE_URL;

export const BASE_URL_UPLOAD =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_UPLOAD_BASE_URL
    : process.env.REACT_APP_PROD_UPLOAD_BASE_URL;
