//TODO: Leads Masters Start Here
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

// lead status starts here
//  Action to get All Lead Status
export const actionGetLeadStatusMaster = createAsyncThunk(
  "actionGetLeadStatusMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `leads-status?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead status
export const actionGetSingleLeadStatusMaster = createAsyncThunk(
  "actionGetSingleLeadStatusMaster",
  (id) => {
    return handleGetResponse(`leads-status/${id}`);
  }
);

//add lead status
export const actionAddLeadStatusMaster = createAsyncThunk(
  "actionAddLeadStatusMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/leads-status`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_status_master_id: data?.lead_status_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteLeadStatusMaster = createAsyncThunk(
  "actionDeleteLeadStatusMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`leads-status/${id}`, id, setEditingKey);
  }
);

// update lead status
export const actionEditLeadStatusMaster = createAsyncThunk(
  "actionEditLeadStatusMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/leads-status/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);
// lead status end here

// lead source starts here
//get lead source master
export const actionGetLeadSourceMaster = createAsyncThunk(
  "actionGetLeadSourceMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `lead-source?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead source
export const actionGetSingleLeadSourceMaster = createAsyncThunk(
  "actionGetSingleLeadSourceMaster",
  (id) => {
    return handleGetResponse(`lead-source/${id}`);
  }
);

//add lead source
export const actionAddLeadSourceMaster = createAsyncThunk(
  "actionAddLeadSourceMaster",
  async ({ sourceForm, values, handleCancel, form, oppoForm }) => {
    try {
      const response = await axios.post(`${BASE_URL}/lead-source`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_source_master_id: data?.lead_source_master_id,
          });

        oppoForm &&
          oppoForm.setFieldsValue({
            opportunity_source_id: data?.lead_source_master_id,
          });

        sourceForm &&
          sourceForm.setFieldsValue({
            lead_source_id: data?.lead_source_master_id,
          });

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      /*   toast.error(error?.message, 5);
      return false; */
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteLeadSourceMaster = createAsyncThunk(
  "actionDeleteLeadSourceMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`lead-source/${id}`, id, setEditingKey);
  }
);

//update lead source
export const actionEditLeadSourceMaster = createAsyncThunk(
  "actionEditLeadSourceMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/lead-source/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);
// lead source ends here

// lead rating start here
export const actionGetLeadRatingMaster = createAsyncThunk(
  "actionGetLeadRatingMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `lead-rating?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single lead rating
export const actionGetSingleLeadRatingMaster = createAsyncThunk(
  "actionGetSingleLeadRatingMaster",
  (id) => {
    return handleGetResponse(`lead-rating/${id}`);
  }
);

// delete lead rating
export const actionDeleteLeadRatingMaster = createAsyncThunk(
  "actionDeleteLeadRatingMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`lead-rating/${id}`, id, setEditingKey);
  }
);

//add lead rating
export const actionAddLeadRatingMaster = createAsyncThunk(
  "actionAddLeadRatingMaster",
  async ({ values, handleCancel, form }) => {
    try {
      console.log({ values });
      const response = await axios.post(`${BASE_URL}/lead-rating`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            lead_rating_master_id: data?.lead_rating_master_id,
          });

        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);

//update lead rating
export const actionEditLeadRatingMaster = createAsyncThunk(
  "actionEditLeadRatingMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/lead-rating/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

// industry start here
export const actionGetIndustryMaster = createAsyncThunk(
  "actionGetIndustryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `industry-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddIndustryMaster = createAsyncThunk(
  "actionAddIndustryMaster",
  async ({ values, form, handleCancel }) => {
    try {
      const response = await axios.post(`${BASE_URL}/industry-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({ industry_master_id: data?.industry_master_id });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);
// industry  ends here

//TODO: Leads Masters Ends Here
export const actionGetOpportunityStageMaster = createAsyncThunk(
  "actionGetOpportunityStageMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `opportunity-stage?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionAddOpportunityStageMaster = createAsyncThunk(
  "actionAddOpportunityStageMaster",
  async ({ values, handleCancel, oppoForm }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/opportunity-stage`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        oppoForm &&
          oppoForm.setFieldsValue({
            opportunity_stage_master_id: data?.opportunity_stage_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (err) {
      // toast.error(err?.message);
      handleErrorMessage(err);
    }
  }
);

// delete lead status
export const actionDeleteOpportunityStageMaster = createAsyncThunk(
  "actionDeleteOpportunityStageMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`opportunity-stage/${id}`, id, setEditingKey);
  }
);

export const actionGetSingleOpportunityStageMaster = createAsyncThunk(
  "actionGetSingleOpportunityStageMaster",
  (id) => {
    return handleGetResponse(`opportunity-stage/${id}`);
  }
);

export const actionEditOpportunityStageMaster = createAsyncThunk(
  "actionEditOpportunityStageMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/opportunity-stage/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

// Industry
// delete lead status
export const actionDeleteIndustryMaster = createAsyncThunk(
  "actionDeleteIndustryMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`industry-master/${id}`, id, setEditingKey);
  }
);

export const actionGetSingleIndustryMaster = createAsyncThunk(
  "actionGetSingleIndustryMaster",
  (id) => {
    return handleGetResponse(`industry-master/${id}`);
  }
);

export const actionEditIndustryMaster = createAsyncThunk(
  "actionEditIndustryMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/industry-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

export const actionGetBusinessPartnerTypeMaster = createAsyncThunk(
  "actionGetBusinessPartnerTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `business-partner-type-master?getall=YES${
        search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
        order ? `&order=${order}` : ""
      }`
    );
  }
);

export const actionAddBusinessPartnerTypeMaster = createAsyncThunk(
  "actionAddBusinessPartnerTypeMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/business-partner-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (error) {
      // toast.error(err?.message);
      handleErrorMessage(error);
    }
  }
);

// delete lead status
export const actionDeleteBusinessPartnerTypeMaster = createAsyncThunk(
  "actionDeleteBusinessPartnerTypeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `business-partner-type-master/${id}`,
      id,
      setEditingKey
    );
  }
);

export const actionGetSingleBusinessPartnerTypeMaster = createAsyncThunk(
  "actionGetSingleBusinessPartnerTypeMaster",
  (id) => {
    return handleGetResponse(`business-partner-type-master/${id}`);
  }
);

export const actionEditBusinessPartnerTypeMaster = createAsyncThunk(
  "actionEditBusinessPartnerTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/business-partner-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//get beat grade
export const actionGetBeatGradeMaster = createAsyncThunk(
  "actionGetBeatGradeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `beat-grade-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single beat grade
export const actionGetSingleBeatGradeMaster = createAsyncThunk(
  "actionGetSingleBeatGradeMaster",
  (id) => {
    return handleGetResponse(`beat-grade-master/${id}`);
  }
);

//add beat grade master
export const actionAddBeatGradeMaster = createAsyncThunk(
  "actionAddBeatGradeMaster",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/beat-grade-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (error) {
      // toast.error(err?.message);
      handleErrorMessage(error);
    }
  }
);

// delete beat grade master
export const actionDeleteBeatGradeMaster = createAsyncThunk(
  "actionDeleteBeatGradeMasterMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`beat-grade-master/${id}`, id, setEditingKey);
  }
);

//update beat grade master
export const actionEditBeatGradeMaster = createAsyncThunk(
  "actionEditBeatGradeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/beat-grade-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get Department
export const actionGetDepartment = createAsyncThunk(
  "actionGetDepartment",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `department-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single Department
export const actionGetSingleDepartment = createAsyncThunk(
  "actionGetSingleDepartment",
  (id) => {
    return handleGetResponse(`department-master/${id}`);
  }
);

//add Department
export const actionAddDepartment = createAsyncThunk(
  "actionAddDepartment",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/department-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            department_id: data?.department_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete Department
export const actionDeleteDepartment = createAsyncThunk(
  "actionDeleteDepartment",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`department-master/${id}`, id, setEditingKey);
  }
);

//update Department
export const actionEditDepartment = createAsyncThunk(
  "actionEditDepartment",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/department-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//get all type of company
export const actionGetTypeOfCompanyMaster = createAsyncThunk(
  "actionGetTypeOfCompanyMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `type-of-company-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//Add Type of company
export const actionAddTypeOfCompanyMaster = createAsyncThunk(
  "actionAddTypeOfCompanyMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/type-of-company-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            type_of_company: data?.type_of_company_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);

//delete type of company
export const actionDeleteTypeOfCompanyMaster = createAsyncThunk(
  "actionDeleteTypeOfCompanyMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `type-of-company-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//get single type of company
export const actionGetSingleTypeOfCompanyMaster = createAsyncThunk(
  "actionGetSingleTypeOfCompanyMaster",
  (id) => {
    return handleGetResponse(`type-of-company-master/${id}`);
  }
);

//edit type of comapny
export const actionEditTypeOfCompanyMaster = createAsyncThunk(
  "actionEditTypeOfCompanyMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/type-of-company-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//get all beat master
export const actionGetBeatMaster = createAsyncThunk(
  "actionGetBeatMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `beat-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add beat master
export const actionAddBeatMaster = createAsyncThunk(
  "actionAddBeatMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/beat-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            beat_master_id: data?.beat_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      // return false;
      handleErrorMessage(error);
    }
  }
);

//delete beat master
export const actionDeleteBeatMaster = createAsyncThunk(
  "actionDeleteBeatMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`beat-master/${id}`, id, setEditingKey);
  }
);

//get single beat master
export const actionGetSingleBeatMaster = createAsyncThunk(
  "actionGetSingleBeatMaster",
  (id) => {
    return handleGetResponse(`beat-master/${id}`);
  }
);

//edit beat master
export const actionEditBeatMaster = createAsyncThunk(
  "actionEditBeatMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/beat-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//zone master start here
//get all zone service start
export const actionGetZoneMaster = createAsyncThunk(
  "actionGetZoneMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `zone-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add zone service start
export const actionAddZoneMaster = createAsyncThunk(
  "actionAddZoneMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/zone-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            zone_master_id: data?.zone_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete zone service start
export const actionDeleteZoneMaster = createAsyncThunk(
  "actionDeleteZoneMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`zone-master/${id}`, id, setEditingKey);
  }
);

//get single zone service start
export const actionGetSingleZoneMaster = createAsyncThunk(
  "actionGetSingleZoneMaster",
  (id) => {
    return handleGetResponse(`zone-master/${id}`);
  }
);

//update zone service start here
export const actionEditZoneMasterMaster = createAsyncThunk(
  "actionEditZoneMasterMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/zone-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);

//Area master start here
//get all area service start
export const actionGetAreaMaster = createAsyncThunk(
  "actionGetAreaMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `area-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add area service
export const actionAddAreaMaster = createAsyncThunk(
  "actionAddAreaMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/area-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            area_master_id: data?.area_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete area service start
export const actionDeleteAreaMaster = createAsyncThunk(
  "actionDeleteAreaMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`area-master/${id}`, id, setEditingKey);
  }
);

//get single area service
export const actionGetSingleAreaMaster = createAsyncThunk(
  "actionGetSingleAreaMaster",
  (id) => {
    return handleGetResponse(`area-master/${id}`);
  }
);

//update area start here
export const actionEditAreaMasterMaster = createAsyncThunk(
  "actionEditAreaMasterMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/area-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error?.message, 5);
      handleErrorMessage(error);
    }
  }
);
