import { Col, Layout, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import BeatGrade from "./BeatGrade";
import BusinessPartnerType from "./BusinessPartnerType";
import IndustryMaster from "./IndustryMaster";
import LeadRating from "./LeadRating";
import LeadSource from "./LeadSource";
import LeadStatusMaster from "./LeadStatus";
import OpportunityStage from "./OpportunityStage";
import BeatMaster from "./BeatMaster";
import DepartmentMaster from "./DepartmentMaster";
import TypeOfCompany from "./TypeOfCompany";
import Zone from "./Zone";
import Area from "./Area";
import { useLocation } from "react-router-dom";

const ProductMasters = () => {
  const [selectItem, setSelectItem] = useState("lead-status");
  const location = useLocation();

  const { type } = location.state || {};

  useEffect(() => {
    if (type) {
      setSelectItem(type);
    }
  }, [type]);


  const items = [
    {
      label: <h1 className=" text-[15px]">Lead Staus</h1>,
      key: "lead-status",
    },
    {
      label: <h1 className="text-[15px]">Lead Source</h1>,
      key: "lead-source",
    },
    {
      label: <h1 className="text-[15px]">Lead Rating</h1>,
      key: "lead-rating",
    },
    {
      label: <h1 className="text-[15px]">Industry Master</h1>,
      key: "industry-master",
    },
    {
      label: <h1 className="text-[15px]">Opportunity Stage</h1>,
      key: "opportunity-stage",
    },
    {
      label: <h1 className="text-[15px]">Beat Grade</h1>,
      key: "beat-grade",
    },
    {
      label: <h1 className="text-[15px]">Business Partner type</h1>,
      key: "business-partner",
    },
    {
      label: <h1 className="text-[15px]">Beat Master</h1>,
      key: "beat-master",
    },
    {
      label: <h1 className="text-[15px]">Department</h1>,
      key: "department",
    },
    {
      label: <h1 className="text-[15px]">Type Of Company</h1>,
      key: "type-of-company",
    },
    {
      label: <h1 className="text-[15px]">Zone</h1>,
      key: "zone",
    },
    {
      label: <h1 className="text-[15px]">Area</h1>,
      key: "area",
    },
  ];

  const showEventpage = () => {
    switch (selectItem) {
      case "lead-status":
        return <LeadStatusMaster />;

      case "lead-source":
        return <LeadSource />;

      case "lead-rating":
        return <LeadRating />;

      case "opportunity-stage":
        return <OpportunityStage />;

      case "beat-grade":
        return <BeatGrade />;

      case "business-partner":
        return <BusinessPartnerType />;

      case "industry-master":
        return <IndustryMaster />;

      case "beat-master":
        return <BeatMaster />;

      case "department":
        return <DepartmentMaster />;

      case "type-of-company":
        return <TypeOfCompany />;

      case "zone":
        return <Zone />;

      case "area":
        return <Area />;

      default:
        return;
    }
  };

  return (
    <div className="bg-white border border-full border-[#DDDAE9] h-full">
      {/* EventMaster */}
      <Row className="flex bg-white">
        <Col span={6} className=" border-bColor border-r">
          <Layout className="inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-medium pt-[14px]">
              General Master
            </h1>
            <Menu
              onSelect={({ key }) => setSelectItem(key)}
              className="pt-2 pb-4 product-master-menus"
              theme={"light"}
              mode="inline"
              selectedKeys={[selectItem]}
              items={items}
            />
          </Layout>
        </Col>
        <Col span={18} className="px-[20px]">
          {showEventpage()}
        </Col>
      </Row>
    </div>
  );
};

export default ProductMasters;
