import { Button, Checkbox, Divider, Form, Image, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdMailOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import axios from "axios";
import googleLogo from "../../assets/img/google-logo.svg";
import {
  actionContinueWithGoogle,
  actionLogin,
} from "../../store/services/authService";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loginLoader, googleLoader } = useSelector((state) => state.auth);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [customLoader, setCustomLoader] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem("crmHarborEmail");
    const storedPassword = localStorage.getItem("crmHarborPassowrd");

    if (storedEmail && storedPassword) {
      form.setFieldsValue({
        email: storedEmail,
        password: storedPassword,
        remember_me: true,
      });
      setRememberMeChecked(true);
    }
  }, [form]);

  const handleFinish = (values) => {
    const { remember_me, ...credentials } = values;
    dispatch(actionLogin({ credentials, navigate, remember_me }));
  };

  // Sign in with google new approch
  const googleLogin = useGoogleLogin({
    cancel_on_tap_outside: false,
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      dispatch(
        actionContinueWithGoogle({
          token: tokenResponse.access_token,
          navigate,
          setCustomLoader,
          user: userInfo.data,
        })
      );
    },
    onError: (errorResponse) => {
      setCustomLoader(false);
    },
    onNonOAuthError: () => {
      setCustomLoader(false);
    },
  });

  useGoogleOneTapLogin({
    cancel_on_tap_outside: false,
    onSuccess: (credentialResponse) => {
      const decoded = jwtDecode(credentialResponse.credential);
      dispatch(
        actionContinueWithGoogle({
          token: credentialResponse.credential,
          navigate,
          setCustomLoader,
          user: decoded,
          isIdToken: true,
        })
      );
    },
    onNonOAuthError: () => {
      setCustomLoader(false);
    },
    onError: () => {
      setCustomLoader(false);
      console.log("Login Failed");
    },
  });

  return (
    <Spin spinning={customLoader}>
      <div className="register-container relative">
        <Form
          form={form}
          name="normal_login"
          className="login-form w-full pt-5"
          onFinish={handleFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="indent-5"
              prefix={
                <MdMailOutline
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              className="indent-5"
              prefix={
                <HiOutlineLockClosed
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
            />
          </Form.Item>
          <div className="flex justify-between -mb-2 items-start">
            <Form.Item name="remember_me" valuePropName="checked">
              <Checkbox className="text-sm font-medium">Remember Me</Checkbox>
            </Form.Item>
            <Form.Item className="mt-1">
              <Link
                to="/forgot-password"
                className="text-primary hover:text-primary/90"
              >
                Forgot Password?
              </Link>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              loading={loginLoader}
              type="primary"
              className="filled-s-button mt-2 md:text-base text-sm border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
              block
              htmlType="submit"
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
        <Form.Item>
          <Link to="/sign-otp">
            <Button
              className="outline-button md:text-base text-sm min-h-[45px]"
              block
            >
              Sign In With OTP
            </Button>
          </Link>
        </Form.Item>
        <p className="text-center">
          Don't have an Account ?{" "}
          <Link to="/register" className="text-primary">
            Sign Up
          </Link>
        </p>
        <Divider className="text-[#fff]">Or</Divider>
        <Button
          loading={googleLoader || customLoader}
          onClick={() => {
            setCustomLoader(true);
            googleLogin();
          }}
          type="primary"
          className="google-btn md:text-base text-sm bg-white hover:bg-white text-black border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px] h-full flex items-center justify-center"
          block
          icon={
            <Image
              preview={false}
              height={30}
              width={30}
              src={googleLogo}
              alt="Google Logo"
            />
          }
        >
          Sign in with Google
        </Button>
      </div>
    </Spin>
  );
};

export default Login;
