import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Image,
  Modal,
  Pagination,
  Spin,
  Table,
  Typography,
  Badge,
  Space,
  List,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/img/add-button.svg";
import opportunity from "../../../assets/img/opportunity.svg";
import defaultOpportunityLogo from "../../../assets/img/defaultOpportunityLogo.svg";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import SortIcon from "../../common/SortIcon";
import CustomIconText from "../../common/CustomIconText";
import PageTitle from "../../common/PageTitle";
import downloadIcon from "../../../assets/img/download.svg";
import {
  actionClearAllOpportunities,
  actionDeleteOpportunity,
  actionGetAllOpportunities,
  actionGetOpportunities,
  actionGetOpportunityDetail,
} from "../../../store/services/opportunityService";
import { useDispatch, useSelector } from "react-redux";
import {
  extractFilename,
  getFirstLetterCapital,
  renderFileIcon,
} from "../../helper/common-function";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import {
  actionDownloadFile,
  actionGetAllEventAttendees,
} from "../../../store/services/eventService";
import EventDescriptionCard from "../../common/EventDescriptionCard";
import TooltipWrapper from "../../common/TooltipWrapper";

const Opportunities = () => {
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [OpportunityData, setOpportunityData] = useState(false);
  const [opportunityId, setOpportunityId] = useState("");
  const [viewOpportunityModalOpen, setViewOpportunityModalOpen] =
    useState(false);
  const [deleteOpportunityModalOpen, setDeleteOpportunityModalOpen] =
    useState(false);
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    oportunitiesDataCount,
    oportunitiesData,
    getOportunitiesLoader,
    allOpportunitiesData,
    getAllOpportunitiesLoader,
    opportunityDetailData,
    getOpportunityDetailLoader,
    deleteOpportunityLoader,
  } = useSelector((state) => state.opportunities);

  const { downloadFileLoader } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(
      actionGetOpportunities({
        offset: offsetRef.current,
        search,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (opportunityId && viewOpportunityModalOpen) {
      dispatch(actionGetOpportunityDetail(opportunityId));
    }
  }, [viewOpportunityModalOpen, opportunityId]);

  /*  const opportunitiesDataColumn = [
    {
      title: "Opportunity Name",
      key: "opportunity_name",
      width: 200,
      // ellipsis: true,
      dataIndex: "opportunity_name",
      sorter: (a, b) => a.opportunity_name - b.opportunity_name,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          onClick={() => {
            setOpportunityId(data.opportunity_id);
            setViewOpportunityModalOpen(true);
          }}
          className="flex items-center cursor-pointer"
        >
          <p className="ml-2 text-brand">{text || "-"}</p>
        </div>
      ),
    },
    {
      title: "Opportunity Owner",
      key: "opportunity_owner",
      width: 200,
      // ellipsis: true,
      dataIndex: "opportunity_owner",
      render: (text) => {
        const firstName = text?.first_name ?? "";
        const lastName = text?.last_name ?? "";
        const fullName = `${firstName} ${lastName}`.trim();

        return (
          <span className="ml-2">
            {fullName ? getFirstLetterCapital(fullName) : "-"}
          </span>
        );
      },
    },
    {
      title: "Lead",
      key: "lead",
      width: 200,
      // ellipsis: true,
      dataIndex: "lead",
      render: (text) => {
        const firstName = text?.first_name ?? "";
        const lastName = text?.last_name ?? "";
        const fullName = `${firstName} ${lastName}`.trim();

        return <span className="ml-2">{fullName ? fullName : "-"}</span>;
      },
    },
    {
      title: "Stage",
      key: "opportunity_stage",
      width: 200,
      // ellipsis: true,
      dataIndex: "opportunity_stage",
      render: (text) => <span className="ml-2">{text?.opportunity_stage || "-"}</span>,
    },
    {
      title: "Probability",
      key: "probability",
      // width: "10%",
      width: 200,
      dataIndex: "probability",
      // ellipsis: true,
      sorter: (a, b) => true,      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Business Partner",
      key: "business_partner",
      // width: "10%",
      width: 200,
      dataIndex: "business_partner",
      // ellipsis: true,
      render: (text) => <span className="ml-2">{text?.business_partner_name || "-"}</span>,
    },
    {
      title: "Expected Revenue/Value",
      key: "expected_revenue",
      width: 200,
      dataIndex: "expected_revenue",
      sorter: (a, b) => true,      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Close Date",
      key: "close_date",
      width: 150,
      dataIndex: "close_date",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("MM-DD-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Contact Person",
      key: "contact_person_name",
      // ellipsis: true,
      width: 150,
      sorter: (a, b) => a.contact_person_name - b.contact_person_name,
      sortIcon: () => <SortIcon />,
      dataIndex: "contact_person_name",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Products/Services",
      key: "products_services",
      // ellipsis: true,
      width: 200,
      dataIndex: "products_services",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Competitors",
      key: "competitors",
      // ellipsis: true,
      width: 150,
      dataIndex: "competitors",
      sorter: (a, b) => a.competitors - b.competitors,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Notes/Description",
      key: "notes_description",
      // ellipsis: true,
      Width: 200,
      dataIndex: "notes_description",
      sorter: (a, b) => a.notes_description - b.notes_description,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: true }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Next Steps/Actions",
      key: "next_steps",
      // ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "next_steps",
      sorter: (a, b) => a.next_steps - b.next_steps,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Source",
      key: "opportunity_source",
      // ellipsis: true,
      // width: "10%",
      width: 150,
      dataIndex: "opportunity_source",
      render: (text) => <span className="ml-2">{text?.lead_source || "-"}</span>,
    },
    {
      title: "Tags",
      key: "opportunity_tags",
      // ellipsis: true,
      width: 200,
      dataIndex: "opportunity_tags",
      render: (tags) => (
        <span className="ml-2">
          {Array.isArray(tags) ? tags.join(", ") : "-"}
        </span>
      ),
    },
    {
      title: "Created By",
      key: "created_by",
      // ellipsis: true,
      width: 250,
      dataIndex: "created_by",
      render: (text) => {
        const firstName = text?.first_name ?? "";
        const lastName = text?.last_name ?? "";
        const fullName = `${firstName} ${lastName}`.trim();
        return (
          <div className="flex items-center cursor-pointer">
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={text.profile_pic}
              size="default"
            />
            <p className="ml-2">{fullName}</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-opportunity", {
                       state: { opportunityId: data.opportunity_id },
                    }),
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () => {
                    setDeleteOpportunityModalOpen(true);
                    setOpportunityId(data.opportunity_id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ]; */

  const opportunitiesDataColumn = [
    {
      title: "Opportunity Name",
      key: "opportunity_name",
      dataIndex: "opportunity_name",
      width: 200,
      fixed: "left",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      ellipsis: true,
      render: (text, data) => (
        <div
          onClick={() => {
            setOpportunityId(data.opportunity_id);
            setViewOpportunityModalOpen(true);
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data.logo ? data.logo : defaultOpportunityLogo}
            size="large"
          />
          <p className="ml-2 text-brand">{text || "-"}</p>
        </div>
      ),
    },
    {
      title: "Opportunity Owner",
      key: "opportunity_owner",
      dataIndex: "opportunity_owner",
      width: 200,
      ellipsis: true,
      // render: (text) => {
      //   const firstName = text?.first_name ?? "";
      //   const lastName = text?.last_name ?? "";
      //   const fullName = `${firstName} ${lastName}`.trim();

      //   return (
      //     <span className="ml-2">
      //       {fullName ? getFirstLetterCapital(fullName) : "-"}
      //     </span>
      //   );
      // },
      render: (text) => <span className="ml-2">{text?.full_name || "-"}</span>,
    },
    {
      title: "Lead",
      key: "lead",
      ellipsis: true,
      dataIndex: "lead",
      width: 150,
      render: (text) => {
        const firstName = text?.first_name ?? "";
        const lastName = text?.last_name ?? "";
        const fullName = `${firstName} ${lastName}`.trim();

        return <span className="ml-2">{fullName ? fullName : "-"}</span>;
      },
    },
    {
      title: "Stage",
      key: "opportunity_stage",
      ellipsis: true,
      dataIndex: "opportunity_stage",
      width: 200,
      render: (text) => (
        <span className="ml-2">{text?.opportunity_stage || "-"}</span>
      ),
    },
    {
      title: "Probability",
      key: "probability",
      dataIndex: "probability",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Expected Revenue",
      key: "expected_revenue",
      dataIndex: "expected_revenue",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Close Date",
      key: "close_date",
      dataIndex: "close_date",
      width: 150,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("MM-DD-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Business Partner",
      key: "business_partner",
      dataIndex: "business_partner",
      width: 200,
      render: (text) => (
        <span className="ml-2">{text?.business_partner_name || "-"}</span>
      ),
    },
    {
      title: "Contact Person",
      key: "contact_person_name",
      dataIndex: "contact_person_name",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Products/Services",
      key: "products_services",
      dataIndex: "products_services",
      width: 200,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Competitors",
      key: "competitors",
      dataIndex: "competitors",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Source",
      key: "opportunity_source",
      dataIndex: "opportunity_source",
      width: 200,
      render: (text) => (
        <span className="ml-2">{text?.lead_source || "-"}</span>
      ),
    },
    {
      title: "Notes/Description",
      key: "notes_description",
      dataIndex: "notes_description",
      ellipsis: true,
      width: 200,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Next Steps/Actions",
      key: "next_steps",
      dataIndex: "next_steps",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Tags",
      key: "opportunity_tags",
      dataIndex: "opportunity_tags",
      width: 200,
      render: (tags) => (
        <p className="ml-2">{Array.isArray(tags) ? tags.join(", ") : "-"}</p>
      ),
    },

    // {
    //   title: "Attachments",
    //   key: "opportunity_attachments",
    //   ellipsis: true,
    //   width: 250,
    //   dataIndex: "opportunity_attachments",
    //   render: (text) => {
    //     return (
    //       text && text.length > 0 ? (
    //         <div>
    //           {/* Map PDF attachments */}
    //           {text
    //             .filter((item) => item.toLowerCase().endsWith(".pdf"))
    //             .map((item, index) => (
    //               <div className="mr-5 mt-2" key={index}>
    //                 <a
    //                   style={{ fontSize: 13, whiteSpace: "nowrap" }}
    //                   href={item}
    //                   target="_blank"
    //                   className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
    //                   rel="noopener noreferrer"
    //                   download
    //                 >
    //                   <DownloadOutlined style={{ marginRight: "5px" }} />
    //                   {item.split("/").pop()}
    //                 </a>
    //               </div>
    //             ))}

    //           {/* Map Excel, Word, and other attachments */}
    //           {text
    //             .filter((item) => {
    //               const lowercaseItem = item.toLowerCase();
    //               const fileExtension = lowercaseItem.split(".").pop();
    //               return ["xlsx", "xls", "csv", "doc", "docx"].includes(
    //                 fileExtension
    //               );
    //             })
    //             .map((item, index) => (
    //               <div className="mr-5 mt-2" key={index}>
    //                 <a
    //                   style={{ fontSize: 13, whiteSpace: "nowrap" }}
    //                   href={item}
    //                   target="_blank"
    //                   className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
    //                   rel="noopener noreferrer"
    //                 >
    //                   <DownloadOutlined style={{ marginRight: "5px" }} />

    //                   {item.split("/").pop()}
    //                 </a>
    //               </div>
    //             ))}

    //           {text.filter((item) => {
    //             const lowercaseItem = item.toLowerCase();
    //             const fileExtension = lowercaseItem.split(".").pop();
    //             return ["jpg", "jpeg", "png"].includes(fileExtension);
    //           }).length > 0 && (
    //             <div className="mr-5 mt-2">
    //               <Image.PreviewGroup
    //                 items={
    //                   text &&
    //                   text.filter((item) => {
    //                     const lowercaseItem = item.toLowerCase();
    //                     const fileExtension = lowercaseItem.split(".").pop();
    //                     return ["jpg", "jpeg", "png"].includes(fileExtension);
    //                   })
    //                 }
    //               >
    //                 <Badge
    //                   style={{ cursor: "pointer" }}
    //                   offset={[-0, 5]}
    //                   count={
    //                     text &&
    //                     text.filter((item) => {
    //                       const lowercaseItem = item.toLowerCase();
    //                       const fileExtension = lowercaseItem.split(".").pop();
    //                       return ["jpg", "jpeg", "png"].includes(fileExtension);
    //                     }).length
    //                   }
    //                   color="grey"
    //                   size="medium"
    //                   title="Images"
    //                 >
    //                   <Image
    //                     preview={{ mask: <EyeOutlined /> }}
    //                     width={70}
    //                     height={70}
    //                     style={{
    //                       objectFit: "contain",
    //                       maxWidth: "100%",
    //                       maxHeight: "100%",
    //                     }}
    //                     src={
    //                       text.filter((item) => {
    //                         const lowercaseItem = item.toLowerCase();
    //                         const fileExtension = lowercaseItem
    //                           .split(".")
    //                           .pop();
    //                         return ["jpg", "jpeg", "png"].includes(
    //                           fileExtension
    //                         );
    //                       })[0]
    //                     }
    //                   />
    //                 </Badge>
    //               </Image.PreviewGroup>
    //             </div>
    //           )}
    //         </div>
    //       ) : (
    //         // Display "No attachments available" when no attachments are available
    //         <p>No attachments available</p>
    //       ),
    //     );
    //   },
    // },

    // {
    //   title: "Attachments",
    //   key: "opportunity_attachments",
    //   ellipsis: true,
    //   width: 250,
    //   dataIndex: "opportunity_attachments",
    //   render: (attachments) => {
    //     if (!attachments || attachments.length === 0) {
    //       return <p>No attachments available</p>;
    //     }

    //     const pdfAttachments = attachments.filter((item) =>
    //       item.toLowerCase().endsWith(".pdf")
    //     );

    //     const otherAttachments = attachments.filter((item) => {
    //       const fileExtension = item.toLowerCase().split(".").pop();
    //       return ["xlsx", "xls", "csv", "doc", "docx"].includes(fileExtension);
    //     });

    //     const imageAttachments = attachments.filter((item) => {
    //       const fileExtension = item.toLowerCase().split(".").pop();
    //       return ["jpg", "jpeg", "png"].includes(fileExtension);
    //     });

    //     return (
    //       <div>
    //         {/* Map PDF attachments */}
    //         {pdfAttachments.map((item, index) => (
    //           <div className="mr-5 mt-2" key={index}>
    //             <a
    //               style={{ fontSize: 13, whiteSpace: "nowrap" }}
    //               href={item}
    //               target="_blank"
    //               className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
    //               rel="noopener noreferrer"
    //               download
    //             >
    //               <DownloadOutlined style={{ marginRight: "5px" }} />
    //               {item.split("/").pop()}
    //             </a>
    //           </div>
    //         ))}

    //         {/* Map Excel, Word, and other attachments */}
    //         {otherAttachments.map((item, index) => (
    //           <div className="mr-5 mt-2" key={index}>
    //             <a
    //               style={{ fontSize: 13, whiteSpace: "nowrap" }}
    //               href={item}
    //               target="_blank"
    //               className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
    //               rel="noopener noreferrer"
    //             >
    //               <DownloadOutlined style={{ marginRight: "5px" }} />
    //               {item.split("/").pop()}
    //             </a>
    //           </div>
    //         ))}

    //         {/* Map Image attachments */}
    //         {imageAttachments.length > 0 && (
    //           <div className="mr-5 mt-2">
    //             <Image.PreviewGroup>
    //               <Badge
    //                 style={{ cursor: "pointer" }}
    //                 offset={[-0, 5]}
    //                 count={imageAttachments.length}
    //                 color="grey"
    //                 size="medium"
    //                 title="Images"
    //               >
    //                 <Image
    //                   preview={{ mask: <EyeOutlined /> }}
    //                   width={70}
    //                   height={70}
    //                   style={{
    //                     objectFit: "contain",
    //                     maxWidth: "100%",
    //                     maxHeight: "100%",
    //                   }}
    //                   src={imageAttachments[0]}
    //                 />
    //               </Badge>
    //             </Image.PreviewGroup>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Created By",
      key: "created_by",
      ellipsis: true,
      width: 250,
      dataIndex: "created_by",
      render: (text) => {
        const firstName = text?.first_name ?? "";
        const lastName = text?.last_name ?? "";
        const fullName = `${firstName} ${lastName}`.trim();
        return (
          <div className="flex items-center cursor-pointer">
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={text.profile_pic}
              size="default"
            />
            <p className="ml-2">{fullName}</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-opportunity", {
                      state: { opportunityId: data.opportunity_id },
                    }),
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () => {
                    setDeleteOpportunityModalOpen(true);
                    setOpportunityId(data.opportunity_id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];
  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetOpportunities({
        offset: offsetRef.current,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetOpportunities({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetOpportunities({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };
  const onClickDownloadMenu = () => {
    dispatch(actionGetAllOpportunities());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allOpportunitiesData) &&
      allOpportunitiesData.length > 0 &&
      allOpportunitiesData.map((data) => ({
        "Opportunity Name": data.opportunity_name || "-",
        "Opportunity Owner":
          `${data.opportunity_owner?.first_name || ""} ${
            data.opportunity_owner?.last_name || ""
          }`.trim() || "-",
        Lead:
          `${data.lead?.first_name || ""} ${
            data.lead?.last_name || ""
          }`.trim() || "-",
        Stage: data.opportunity_stage?.opportunity_stage || "-",
        Probability: data.probability || "-",
        "Expected Revenue/Value": data.expected_revenue || "-",
        "Close Date": data.close_date
          ? dayjs(data.close_date).format("MM-DD-YYYY")
          : "-",
        "Business Partner": data.business_partner?.business_partner_name || "-",
        "Contact Person": data.contact_person_name || "-",
        "Products/Services": data.products_services || "-",
        Competitors: data.competitors || "-",
        Source: data.opportunity_source?.lead_source || "-",
        "Notes/Description": data.notes_description || "-",
        "Next Steps/Actions": data.next_steps || "-",
        Tags: Array.isArray(data.opportunity_tags)
          ? data.opportunity_tags.join(", ")
          : "-",
        "Created By":
          `${data.created_by?.first_name || ""} ${
            data.created_by?.last_name || ""
          }`.trim() || "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "opportunities.xlsx");
    dispatch(actionClearAllOpportunities({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allOpportunitiesData && allOpportunitiesData.length > 0) {
      exportAllToExcel();
    }
  }, [allOpportunitiesData]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetOpportunities({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const handleCloseDeleteModal = () => {
    setOpportunityId("");
    setDeleteOpportunityModalOpen(false);
  };

  const handleCancelViewModal = () => {
    setViewOpportunityModalOpen(false);
    setOpportunityId("");
  };

  //download button of all opportunity attachment
  const handleDownloadSelectedFiles = () => {
    opportunityDetailData?.opportunity_attachments &&
      opportunityDetailData?.opportunity_attachments.length > 0 &&
      opportunityDetailData?.opportunity_attachments.forEach((file) => {
        dispatch(actionDownloadFile(file));
      });
  };

  // icon of attachment in view modal
  /* const renderFileIcon = (file) => {
    const extractFileName = extractFilename(file);
    if (
      extractFileName.endsWith("png") ||
      extractFileName.endsWith("jpg") ||
      extractFileName.endsWith("jpeg")
    ) {
      return <Image src={file} alt="docs" height={24} width={24} />;
    } else if (extractFileName.endsWith("pdf")) {
      return <FilePdfOutlined style={{ fontSize: "24px", color: "#ff6347" }} />;
    } else if (
      extractFileName.endsWith("doc") ||
      extractFileName.endsWith("docx")
    ) {
      return (
        <FileWordOutlined style={{ fontSize: "24px", color: "#1e90ff" }} />
      );
    } else {
      return (
        <FileTextOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
      );
    }
  }; */

  return (
    <>
      <section className="main-wrapper">
        <PageTitle
          image={opportunity}
          title="Opportunities"
          buttonText="Create Opportunity"
          buttonLink="/create-opportunity"
        />
        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
          isAllowDownload={
            oportunitiesDataCount !== undefined && oportunitiesDataCount > 0
          }
        />

        <Table
          sticky={true}
          rowKey={(record) => record.opportunity_id}
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={opportunitiesDataColumn}
          dataSource={oportunitiesData}
          loading={getOportunitiesLoader || getAllOpportunitiesLoader}
        />

        <div className="flex justify-end mt-3">
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={oportunitiesDataCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetOpportunities({ offset: offsetRef.current, search })
              );
            }}
          />
        </div>
        <Modal
          width={700}
          loading={getOpportunityDetailLoader}
          centered={true}
          footer={false}
          handleReset={() => {
            setViewOpportunityModalOpen(false);
            handleCancelViewModal();
          }}
          open={viewOpportunityModalOpen}
          title=""
          onCancel={() => {
            setViewOpportunityModalOpen(false);
            setOpportunityData(false);
            handleCancelViewModal();
          }}
          onOk={() => {
            setViewOpportunityModalOpen(false);
            setOpportunityData(false);
            handleCancelViewModal();
          }}
        >
          <Spin spinning={false}>
            {opportunityDetailData && (
              <div>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={opportunity}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="lg:text-lg text-base font-popinsRegular">
                    Opportunity
                  </h1>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center pt-5 gap-x-4">
                    <Image
                      preview={true}
                      height={100}
                      width={100}
                      className="rounded-[10px]"
                      src={
                        opportunityDetailData && opportunityDetailData.logo
                          ? opportunityDetailData.logo
                          : defaultOpportunityLogo
                      }
                      alt="Logo"
                    />
                    <div className="flex justify-start flex-col gap-y-1">
                      <p className="text-sm font-popinsMedium">
                        {" "}
                        {opportunityDetailData?.opportunity_name}
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Opportunity Name:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {opportunityDetailData?.opportunity_name}
                  </p>
                </div> */}
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Opportunity Owner:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.opportunity_owner?.full_name ||
                        "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">Lead:</p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {`${opportunityDetailData.lead?.first_name || ""} ${
                        opportunityDetailData.lead?.last_name || ""
                      }`.trim() || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">Stage:</p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.opportunity_stage
                        ?.opportunity_stage || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Probability:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.probability || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Expected Revenue/Value:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.expected_revenue || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Close Date:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.close_date
                        ? dayjs(opportunityDetailData.close_date).format(
                            "MM-DD-YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Business Partner:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.business_partner
                        ?.business_partner_name || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Contact Person:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.contact_person_name || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Products/Services:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.products_services || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Competitors:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.competitors || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">Source:</p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.opportunity_source?.lead_source ||
                        "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Notes/Description:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.notes_description || "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Next Steps/Actions:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.next_steps || "-"}
                    </p>
                  </div>
                  {/* <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Attachments/Documents:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    Proposal.pdf
                  </p>
                </div> */}
                  <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">Tags:</p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {Array.isArray(opportunityDetailData.opportunity_tags)
                        ? opportunityDetailData.opportunity_tags.join(", ")
                        : "-"}
                    </p>
                  </div>
                  {opportunityDetailData?.opportunity_attachments?.length >
                  0 ? (
                    <List
                      size="large"
                      header={
                        <div className="flex items-center justify-between">
                          <h2 className="text-primary text-center">
                            Attachments
                          </h2>
                          {opportunityDetailData?.opportunity_attachments &&
                            opportunityDetailData?.opportunity_attachments
                              .length > 1 && (
                              <TooltipWrapper title={"click to download all"}>
                                <Button
                                  loading={downloadFileLoader}
                                  type="primary"
                                  onClick={handleDownloadSelectedFiles}
                                  className=" text-white download-btn p-2 text-xs rounded"
                                >
                                  Download All
                                </Button>
                              </TooltipWrapper>
                            )}
                        </div>
                      }
                      bordered
                      dataSource={
                        opportunityDetailData?.opportunity_attachments
                      }
                      renderItem={(item, index) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={renderFileIcon(item)}
                            title={
                              <div className="flex items-center">
                                <p className="w-[95%] text">
                                  {extractFilename(item)}
                                </p>
                                <span className="w-[5%]">
                                  <img
                                    onClick={() =>
                                      dispatch(actionDownloadFile(item))
                                    }
                                    className="cursor-pointer"
                                    src={downloadIcon}
                                    alt="download"
                                  />
                                </span>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <div className="flex gap-x-6 items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Attachments/Documents:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        -
                      </p>
                    </div>
                  )}

                  {/* <div className="flex gap-x-6 items-start gap-y-1">
                    <p className="text-xs text-[#818181] w-[40%]">
                      Attachments:
                    </p>
                    <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                      {opportunityDetailData.opportunity_attachments &&
                      opportunityDetailData.opportunity_attachments.length >
                        0 ? (
                        <div>
                          {opportunityDetailData.opportunity_attachments
                            .filter((item) =>
                              item.toLowerCase().endsWith(".pdf")
                            )
                            .map((item, index) => (
                              <div className="mr-5 mt-2" key={index}>
                                <a
                                  style={{ fontSize: 13 }}
                                  href={item}
                                  target="_blank"
                                  className="link-tag hover:underline text-sm hover:text-black-600"
                                  rel="noopener noreferrer"
                                >
                                  {item.split("/").pop()}
                                </a>
                              </div>
                            ))}

                          {opportunityDetailData.opportunity_attachments
                            .filter((item) => {
                              const lowercaseItem = item.toLowerCase();
                              const fileExtension = lowercaseItem
                                .split(".")
                                .pop();
                              return [
                                "xlsx",
                                "xls",
                                "csv",
                                "doc",
                                "docx",
                              ].includes(fileExtension);
                            })
                            .map((item, index) => (
                              <div key={index}>
                                <a
                                  style={{ fontSize: 13 }}
                                  href={item}
                                  target="_blank"
                                  className="link-tag hover:underline text-sm hover:text-black-600"
                                  rel="noopener noreferrer"
                                >
                                  {item.split("/").pop()}
                                </a>
                              </div>
                            ))}

                          {opportunityDetailData.opportunity_attachments.filter(
                            (item) => {
                              const lowercaseItem = item.toLowerCase();
                              const fileExtension = lowercaseItem
                                .split(".")
                                .pop();
                              return ["jpg", "jpeg", "png"].includes(
                                fileExtension
                              );
                            }
                          ).length > 0 && (
                            <div>
                              <Image.PreviewGroup
                                items={
                                  opportunityDetailData.opportunity_attachments &&
                                  opportunityDetailData.opportunity_attachments.filter(
                                    (item) => {
                                      const lowercaseItem = item.toLowerCase();
                                      const fileExtension = lowercaseItem
                                        .split(".")
                                        .pop();
                                      return ["jpg", "jpeg", "png"].includes(
                                        fileExtension
                                      );
                                    }
                                  )
                                }
                              >
                                <Badge
                                  style={{ cursor: "pointer" }}
                                  offset={[-0, 5]}
                                  count={
                                    opportunityDetailData.opportunity_attachments &&
                                    opportunityDetailData.opportunity_attachments.filter(
                                      (item) => {
                                        const lowercaseItem =
                                          item.toLowerCase();
                                        const fileExtension = lowercaseItem
                                          .split(".")
                                          .pop();
                                        return ["jpg", "jpeg", "png"].includes(
                                          fileExtension
                                        );
                                      }
                                    ).length
                                  }
                                  color="grey"
                                  size="medium"
                                  title="Images"
                                >
                                  <Image
                                    preview={{ mask: <EyeOutlined /> }}
                                    width={150}
                                    height={150}
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      opportunityDetailData.opportunity_attachments.filter(
                                        (item) => {
                                          const lowercaseItem =
                                            item.toLowerCase();
                                          const fileExtension = lowercaseItem
                                            .split(".")
                                            .pop();
                                          return [
                                            "jpg",
                                            "jpeg",
                                            "png",
                                          ].includes(fileExtension);
                                        }
                                      )[0]
                                    }
                                  />
                                </Badge>
                              </Image.PreviewGroup>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>No attachments available</p>
                      )}
                    </p>
                  </div> */}
                </div>
              </div>
            )}
          </Spin>
        </Modal>
        <Modal
          centered={true}
          footer={false}
          handleReset={handleCloseDeleteModal}
          afterClose={handleCloseDeleteModal}
          open={deleteOpportunityModalOpen}
          title=""
          onCancel={handleCloseDeleteModal}
          onOk={handleCloseDeleteModal}
        >
          <p className="text-[19px] text-black font-popinsMedium">
            Are you sure you want to remove this Opportunity ?
          </p>
          <div className=" flex justify-end mt-[30px]">
            <Button
              onClick={handleCloseDeleteModal}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                htmlType="submit"
                loading={deleteOpportunityLoader}
                onClick={() => {
                  dispatch(
                    actionDeleteOpportunity({
                      id: opportunityId,
                      handleCloseDeleteModal,
                    })
                  );
                }}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default Opportunities;
