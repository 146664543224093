import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import productsubcategory from "../../../assets/img/productsubcategory.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddProductSubCategoryMaster,
  actionDeleteProductSubCategoryMaster,
  actionEditProductSubCategoryMaster,
  actionGetProductCategoryMaster,
  actionGetProductSubCategoryMaster,
  actionGetSingleProductSubCategoryMaster,
} from "../../../store/services/productMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";

const ProductSubCategory = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    getProductCategoryLoader,
    productCategoryData,
    productSubCategoryData,
    getProductSubCategoryLoader,
    addProductSubCategoryLoader,
    getSingleProductSubCategoryData,
    getSingleEventTypeLoader,
    getEventTypeLoader,
    addEventTypeLoader,
    deleteProductSubCategoryLoader,
    updateProductSubCategoryLoader,
  } = useSelector((state) => state.productMaster);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [select, setSelect] = useState(false);

  const isEditing = (record) =>
    record.product_sub_category_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetProductSubCategoryMaster({ search }));
  }, [dispatch]);

  const handleAddProductSubCategory = (values) => {
    dispatch(actionAddProductSubCategoryMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetProductCategoryMaster());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (select) {
      dispatch(
        actionGetProductCategoryMaster({
          orderByColumn: "category_name",
          order: "ASC",
        })
      );
    }
  }, [select]);

  const productCateData =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData.map((data) => ({
      label: data.category_name,
      value: data.product_category_master_id,
    }));

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex === "product_category") {
      inputNode = (
        <Select
          className="selected-input"
          options={productCateData}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "status") {
      inputNode = (
        <Select
          className="selected-input"
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Archive",
              label: "Archive",
            },
            {
              value: "Inactive",
              label: "Inactive",
            },
          ]}
          defaultValue="Active"
          style={{ background: "white" }}
        />
      );
    } else {
      inputNode = <Input />;
    }

    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleProductSubCategoryMaster(
        record.product_sub_category_master_id
      )
    );
    setEdited(true);
    setSelect(true);
    setEditingKey(record.product_sub_category_master_id);
  };

  useEffect(() => {
    if (getSingleProductSubCategoryData && edited) {
      editableForm.setFieldsValue({
        product_category:
          getSingleProductSubCategoryData.product_category
            ?.product_category_master_id,
        sub_category_name: getSingleProductSubCategoryData.sub_category_name,
        status: getSingleProductSubCategoryData.status,
      });
    }
  }, [getSingleProductSubCategoryData, edited]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { product_category, sub_category_name, status } = row;
      const req = {
        product_category_id: product_category,
        sub_category_name,
        status,
      };
      dispatch(
        actionEditProductSubCategoryMaster({
          id,
          req,
          setEditingKey,
          setEdited,
        })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "green";
      case "Inactive":
        return "yellow";
      case "Archive":
        return "red";
      default:
        return "black";
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "12%",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Category Name",
      dataIndex: "product_category",
      key: "2",
      width: "24%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.product_category && record.product_category.category_name
            ? record.product_category.category_name
            : "-"}
        </span>
      ),
    },
    {
      title: "Sub Category Name",
      dataIndex: "sub_category_name",
      key: "3",
      width: "24%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: <h1 className="pl-[12px]">Status</h1>,
      dataIndex: "status",
      key: "4",
      width: "21%",
      editable: true,
      ellipsis: true,
      render: (text) => (
        <Tag color={getStatusColor(text)} key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "5",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateProductSubCategoryLoader[
                  record.product_sub_category_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.product_sub_category_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deleteProductSubCategoryLoader[
                      record.product_sub_category_master_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteProductSubCategoryMaster({
                      id: record.product_sub_category_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "Product Sub Category" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetProductSubCategoryMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetProductSubCategoryMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetProductSubCategoryMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Product Sub Category
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Product Sub Category
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <div className="flex">
              <img src={productsubcategory} alt="" />
              <h1 className="text-[#6883FD] text-[20px] ml-[8px]  mt-[5px] font-medium">
                Product Sub Category
              </h1>
            </div>
            <Form
              form={form}
              initialValues={{
                status: "Active",
              }}
              onFinish={handleAddProductSubCategory}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="product_category_id"
                label="Product Category"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select product category!",
                  },
                ]}
              >
                <Select
                  options={productCateData}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={getProductCategoryLoader}
                  disabled={getProductCategoryLoader}
                  style={{ background: "white" }}
                  placeholder="Select product category"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="sub_category_name"
                label="Product Sub Category Name"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input product sub category name!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Product Sub Category Name"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <Form.Item
                name="status"
                label="Status"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select status!",
                  },
                ]}
              >
                <Select
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Active",
                      label: "Active",
                    },
                    {
                      value: "Archive",
                      label: "Archive",
                    },
                    {
                      value: "Inactive",
                      label: "Inactive",
                    },
                  ]}
                  // defaultValue="Active"
                  style={{ background: "white" }}
                  placeholder="Select Status"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addProductSubCategoryLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleBlurAndPressEnter}
          onPressEnter={handleBlurAndPressEnter}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>

      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            scroll={{ y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onChange={handleSortChange}
            loading={getProductSubCategoryLoader}
            bordered
            dataSource={productSubCategoryData}
            rowKey="product_sub_category_master_id"
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </div>
    </div>
  );
};

export default ProductSubCategory;
