import { ArrowLeftOutlined } from "@ant-design/icons"; // import { Button } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInputComponent from "../common/PhoneInputComponent";
// import { isPhoneValid } from "../helper/common-function";
import rightAngle from "../../assets/img/right-angle.svg";
import leftAngle from "../../assets/img/left-angle.svg";
// import { currentYear, getStoredUserData } from "./common-function";
import logo from "../../assets/img/logo.svg";
import { Button, Image } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionChangeMobile } from "../../store/services/authService";
import { getStoredUserData } from "../helper/common-function";

const ChangeMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("crmWebToken");

  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });

  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const data = getStoredUserData();
  const { changeMobileLoader } = useSelector((state) => state.auth);

  const handleChange = (valid) => {
    setIsValid(valid);
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const onFinish = (e) => {
    e.preventDefault();
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    if (valid) {
      setIsValid(true);
      const request = {
        user_id: data && data.id,
        phone: value.phone,
        country_code: value.iso,
      };
      dispatch(
        actionChangeMobile({ request, navigate, phone: value.fullPhoneNumber })
      );
    } else {
      setIsValid(false);
    }
  };

  const width =
    location.pathname === "/otp"
      ? "2xl:max-w-2xl  max-w-xl w-full"
      : location.pathname === "/create-account"
      ? "2xl:max-w-2xl lg:max-w-[100%]  w-full"
      : "2xl:max-w-xl  max-w-lg w-full";

  return (
    <>
      <div className="min-h-screen relative pt-3 flex justify-between flex-col w-full h-full ">
        <img
          src={rightAngle}
          className="absolute z-0 right-0 bottom-0 md:block hidden"
          alt="right-angle"
        />
        <img
          src={leftAngle}
          className="absolute z-0 left-0 top-0 md:block hidden"
          alt="left-angle"
        />

        {/*  Header Start Here */}
        <div className="flex  lg:pt-10 pt-3  flex-col items-center justify-center">
          <Image
            className="text-center mx-auto "
            preview={false}
            height={50}
            src={logo}
            alt="Logo"
          />
          <p className="lg:text-2xl md:text-xl text-base lg:py-2 md:py-2 py-2 text-[#676767]">
            Begin your free account today.
          </p>
        </div>
        {/*  Header Ends Here */}

        {/* Content Start Here */}
        <section
          className={`flex-1 relative lg:py-12 md:py-10 py-5 h-100vh flex flex-col    px-4 ${width} mx-auto`}
        >
          <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2 ">
            <p className="xl:text-[30px] text-secondary font-popinsSemiBold  lg:text-[26px]  md:text-2xl text-xl">
              Change Mobile no
            </p>
            <p className="text-[#676767] lg:text-xl mb-3 md:text-base text-sm">
              Change your Mobile Number
            </p>
          </div>
          <form
            name="normal_login"
            className="login-form w-full pt-5"
            onSubmit={onFinish}
          >
            <PhoneInputComponent
              value={value.fullPhoneNumber}
              onChange={handleChange}
              setIsValid={setIsValid}
              isValid={isValid}
              setValue={setValue}
            />

            <Button
              type="primary"
              className="filled-s-button mt-8 md:text-base text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
              block
              htmlType="submit"
              loading={changeMobileLoader}
            >
              Save
            </Button>
          </form>
          <div className="mt-2 text-center flex justify-center">
            <ArrowLeftOutlined className="text-primary/90 mr-2" />
            <p
              onClick={() => navigate("/otp", { state: { page: true } })}
              className="text-primary hover:text-primary/90 md:text-[18px] text-[15px]"
            >
              Back
            </p>
          </div>
        </section>

        {/* Content ends Here */}

        {/* Footer Start Here */}
        <footer className="lg:text-base  h-[30%] py-10 pb-5  text-center md:text-sm text-sm whitespace-nowrap  ">
          {/* © {currentYear}, CRM Harbor Pvt. Ltd. All Rights Reserved. */}
        </footer>
        {/* Footer ends Here */}
      </div>
    </>
  );
};

export default ChangeMobile;
