import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
  Row,
  Col,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import plusSvg from "../../../assets/img/plus.svg";
import beatmaster from "../../../assets/img/beatmaster.svg";
import editevent from "../../../assets/img/editevent.svg";
import SortIcon from "../../common/SortIcon";
import {
  actionGetBeatMaster,
  actionGetBeatGradeMaster,
  actionAddBeatMaster,
  actionDeleteBeatMaster,
  actionGetSingleBeatMaster,
  actionEditBeatMaster,
  actionGetAreaMaster,
  actionGetZoneMaster,
  actionAddZoneMaster,
  actionAddAreaMaster,
  actionAddBeatGradeMaster,
} from "../../../store/services/generalMasterService";
import { isValidSearchString } from "../../helper/common-function";

const BeatMaster = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const [zoneMasterModalForm] = Form.useForm();
  const [areaMasterModalForm] = Form.useForm();
  const [beatGradeModalForm] = Form.useForm();
  const dispatch = useDispatch();
  const {
    BeatMasterData,
    beatGradeMasterData,
    addBeatMasterLoader,
    deleteBeatMasterLoader,
    getBeatMasterLoader,
    getSingleBeatMasterData,
    updateBeatMasterLoader,
    zoneMasterData,
    getAreaMasterLoader,
    getZoneMasterLoader,
    addZoneMasterLoader,
    addAreaMasterLoader,
    addBeatGradeMasterLoader,
    getBeatGradeMasterLoader,
    areaMasterData,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [data, setData] = useState([]);
  const [select, setSelect] = useState(false);
  const [isZoneModalOpen, setIsZoneModalOpen] = useState(false);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isBeatGradeModalOpen, setIsBeatGradeModalOpen] = useState(false);

  const isEditing = (record) => record.beat_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetBeatMaster({ search }));
  }, [dispatch]);

  const handleAddBeatMaster = (values) => {
    dispatch(actionAddBeatMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetAreaMaster());
    dispatch(actionGetZoneMaster());
    dispatch(actionGetBeatGradeMaster());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (select) {
      dispatch(actionGetBeatGradeMaster());
      dispatch(actionGetAreaMaster());
      dispatch(actionGetZoneMaster());
    }
  }, [select]);

  const beatGradeData =
    beatGradeMasterData &&
    beatGradeMasterData.length > 0 &&
    beatGradeMasterData.map((data) => ({
      label: data.beat_grade,
      value: data.beat_grade_master_id,
    }));

  const area =
    areaMasterData &&
    areaMasterData.length > 0 &&
    areaMasterData.map(({ area_name, area_master_id }) => ({
      label: area_name,
      value: area_master_id,
    }));

  const zone =
    zoneMasterData &&
    zoneMasterData.length > 0 &&
    zoneMasterData.map(({ zone_name, zone_master_id }) => ({
      label: zone_name,
      value: zone_master_id,
    }));

  // add zone
  const handleAddZone = (values) => {
    dispatch(actionAddZoneMaster({ values, handleCancel: cancelZoneModal }));
  };

  // add area
  const handleAddArea = (values) => {
    dispatch(
      actionAddAreaMaster({
        values,
        handleCancel: cancelAreaModal,
      })
    );
  };

  //add beat grade
  const handleAdd = (values) => {
    dispatch(
      actionAddBeatGradeMaster({ values, handleCancel: cancelBeatGradeModal })
    );
  };

  //zone
  const showZoneModalOpen = () => {
    setIsZoneModalOpen(true);
  };

  const cancelZoneModal = () => {
    setIsZoneModalOpen(false);
    zoneMasterModalForm.resetFields();
  };

  //area
  const showAreaModalOpen = () => {
    setIsAreaModalOpen(true);
  };

  const cancelAreaModal = () => {
    setIsAreaModalOpen(false);
    areaMasterModalForm.resetFields();
  };

  //beat grade
  const showBeatGradeModalOpen = () => {
    setIsBeatGradeModalOpen(true);
  };

  const cancelBeatGradeModal = () => {
    setIsBeatGradeModalOpen(false);
    beatGradeModalForm.resetFields();
  };

  //zone
  const zoneMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={zoneMasterModalForm}
          onFinish={handleAddZone}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="zone_name"
            label="Zone"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input zone!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Zone"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelZoneModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addZoneMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //area
  const areaMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={areaMasterModalForm}
          onFinish={handleAddArea}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="area_name"
            label="Area"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input area!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Area"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelAreaModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAreaMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //beat grade
  const beatGradePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={beatGradeModalForm}
          onFinish={handleAdd}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="beat_grade"
            label="Beat Grade"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input beat grade!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Beat Grade"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelBeatGradeModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBeatGradeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    if (dataIndex === "beat_grade") {
      inputNode = (
        <Select
          className="selected-input"
          options={beatGradeData}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "zone") {
      inputNode = (
        <Select
          className="selected-input"
          options={zone}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "area") {
      inputNode = (
        <Select
          className="selected-input"
          options={area}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else {
      inputNode = <Input />;
    }

    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                // message: `Please enter ${title}`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  //edit case
  const edit = (record) => {
    dispatch(actionGetSingleBeatMaster(record.beat_master_id));
    setEdited(true);
    setSelect(true);
    setEditingKey(record.beat_master_id);
  };

  useEffect(() => {
    if (getSingleBeatMasterData && edited) {
      editableForm.setFieldsValue({
        beat_grade: getSingleBeatMasterData.beat_grade?.beat_grade_master_id,
        area: getSingleBeatMasterData.area?.area_master_id,
        zone: getSingleBeatMasterData.zone?.zone_master_id,
        beat_name: getSingleBeatMasterData.beat_name,
        city: getSingleBeatMasterData.city,
      });
      // setEditingKey(getSingleBeatMasterData.beat_master_id);
    }
  }, [getSingleBeatMasterData, edited]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { beat_grade, area, zone, beat_name, city } = row;
      const req = {
        beat_grade_id: beat_grade,
        area_id: area,
        zone_id: zone,
        beat_name,
        city,
      };
      dispatch(
        actionEditBeatMaster({
          id,
          req,
          setEditingKey,
          setEdited,
        })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetBeatMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetBeatMaster({
          search: e.target.value,
        })
      );
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Beat Name",
      dataIndex: "beat_name",
      key: "2",
      width: 100,
      editable: true,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Beat Grade",
      dataIndex: "beat_grade",
      key: "2",
      width: 200,
      editable: true,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.beat_grade && record.beat_grade.beat_grade
            ? record.beat_grade.beat_grade
            : "-"}
        </span>
      ),
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "2",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.zone && record.zone.zone_name ? record.zone.zone_name : "-"}
        </span>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "3",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "4",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.area && record.area.area_name ? record.area.area_name : "-"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: 150,
      className: "bg-color",
      key: "5",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateBeatMasterLoader[record.beat_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link onClick={() => save(record.beat_master_id)}>
                    <img src={editedevent} alt="Edited Event" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="Edit" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteBeatMasterLoader[record.beat_master_id],
                }}
                title="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteBeatMaster({
                      id: record.beat_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="Delete" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "beat_name" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetBeatMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">Beat Master</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Add Beat Master
          </Button>
          <Modal
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            visible={isModalOpen}
          >
            <div className="flex items-center">
              <img src={beatmaster} alt="Beat Master" />
              <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                Add Beat Master
              </h1>
            </div>
            <Form
              form={form}
              onFinish={handleAddBeatMaster}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="beat_name"
                label="Beat Name"
                className="mb-[12px]"
                rules={[
                  {
                    required: true,
                    message: "Please input beat name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Beat name"
                  className="rounded-[10px]"
                />
              </Form.Item>

              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Popover
                    placement="bottomRight"
                    content={beatGradePopover}
                    trigger="click"
                    open={isBeatGradeModalOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsBeatGradeModalOpen(false);
                        beatGradeModalForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showBeatGradeModalOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Beat Grade
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    name="beat_grade_id"
                    label="Beat Grade"
                    className=""
                    rules={[
                      { required: true, message: "Please select beat grade!" },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Beat Grade"
                      optionFilterProp="children"
                      className=""
                      loading={getBeatGradeMasterLoader}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={beatGradeData}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Popover
                    placement="bottomRight"
                    content={zoneMasterPopover}
                    trigger="click"
                    open={isZoneModalOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsZoneModalOpen(false);
                        zoneMasterModalForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showZoneModalOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Zone
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    name="zone_id"
                    label="Zone"
                    className=""
                    rules={[{ required: true, message: "Please select zone!" }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Zone"
                      optionFilterProp="children"
                      className=""
                      loading={getZoneMasterLoader}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={zone}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="city"
                label="City"
                className="mb-[12px]"
                rules={[
                  {
                    required: true,
                    message: "Please input city!",
                  },
                ]}
              >
                <Input placeholder="Enter City" className="rounded-[10px]" />
              </Form.Item>

              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Popover
                    placement="bottomRight"
                    content={areaMasterPopover}
                    trigger="click"
                    open={isAreaModalOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsAreaModalOpen(false);
                        areaMasterModalForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showAreaModalOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Area
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    name="area_id"
                    label="Area"
                    className=""
                    rules={[{ required: true, message: "Please select area!" }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      // onSelect={handleGetState}
                      placeholder="Select Area"
                      optionFilterProp="children"
                      className=""
                      loading={getAreaMasterLoader}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={area}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      loading={addBeatMasterLoader}
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleBlurAndPressEnter}
          onPressEnter={handleBlurAndPressEnter}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>
      <div className="pt-5 pb-[75px]">
        <Form form={editableForm} component={false}>
          <Table
            scroll={{ x: "max-content", y: 500 }}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onChange={handleSortChange}
            loading={getBeatMasterLoader}
            bordered
            dataSource={BeatMasterData}
            rowKey="beat_master_id"
            columns={mergedColumns}
            rowClassName="editable-row beat-master-table"
          />
        </Form>
      </div>
    </div>
  );
};

export default BeatMaster;
