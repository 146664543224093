import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { handleGetResponse, handlePostResponse } from "./commonService";
import { toast } from "sonner";
import { extractFilename } from "../../components/helper/common-function";

// action add opportunity
export const actionAddOpportunity = createAsyncThunk(
  "actionAddOpportunity",
  async ({ req, navigate }) => {
    try {
      const res = await axios.post(`${BASE_URL}/opportunity`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/opportunity-management");
        return data;
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Action to get opportunities
export const actionGetOpportunities = createAsyncThunk(
  "actionGetOpportunities",
  async ({ offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/opportunity?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Action to clear all opportunities
export const actionClearAllOpportunities = createAsyncThunk(
  "actionClearAllOpportunities",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

// Action to get All Opportunities
export const actionGetAllOpportunities = createAsyncThunk(
  "actionGetAllOpportunities",
  async () => {
    return handleGetResponse(`opportunity?getall=YES`); // Modify endpoint as needed
  }
);

// Action to get Single Opportunity data
export const actionGetOpportunityDetail = createAsyncThunk(
  "actionOpportunityDetail",
  async (id) => {
    return handleGetResponse(`opportunity/${id}`); // Modify endpoint as needed
  }
);

// Action to delete Opportunity
export const actionDeleteOpportunity = createAsyncThunk(
  "actionDeleteOpportunity",
  async ({ id, handleCloseDeleteModal }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/opportunity/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return false;
    }
  }
);

// Action to Update Opportunity
export const actionUpdateOpportunity = createAsyncThunk(
  "actionUpdateOpportunity",
  async ({ id, req, navigate }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/opportunity/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/opportunity-management");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return false;
    }
  }
);
