import { SearchOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Popconfirm, Space } from "antd"; // Import necessary Ant Design components
import React from "react";
import { IoIosRefresh } from "react-icons/io";
import { RiDownloadLine } from "react-icons/ri";
import TooltipWrapper from "./TooltipWrapper"; // Import the TooltipWrapper component

const CustomInputWithButtons = ({
  checkedList,
  icons = true,
  setCheckedList,
  columns,
  setColumns,
  handleFilter,
  handleRefresh,
  handleDownload,
  handleSearch,
  handleBlurAndPressEnter,
  searchValue,
  isDeactivate,
  onClickDeactivate,
  items,
  filterValues,
  onClickDownloadMenu,
  isAllowDownload = true,
}) => {

  const regex = /^[a-zA-Z0-9 @.-]*$/;

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      handleBlurAndPressEnter(e);
    }
  };


  // const newCheckedList = checkedList.filter((checked) => checked !== "action");
  return (
    <section className="main-section">
      <Space.Compact className="w-full custom-input-sec relative flex mb-5">
        <Input
          value={searchValue}
          onChange={handleSearchChange}
          onBlur={handleBlurAndEnter}
          onPressEnter={handleBlurAndEnter}
          size="large"
          style={{ borderRight: "none" }}
          className=" hover:border-gray-400 bg-transparent"
          id="none-border"
          allowClear
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          addonAfter={
            icons ? (
              <div className="flex h-full items-center ">
                {/* <Popover
                  content={() => (
                    <SortableContainer
                      setCheckedList={setCheckedList}
                      checkedList={newCheckedList}
                      setColumns={setColumns}
                      columns={columns}
                    />
                  )}
                  title="Columns"
                  trigger="click"
                >
                  <div className="h-full cursor-pointer flex items-center icon-btn justify-center">
                    <TooltipWrapper title={"Show Columns"}>
                      <Tag
                        color="#6883FD"
                        className="bg-traparent mx-3   text-primary"
                      >
                        {(newCheckedList && newCheckedList.length) || 0}
                      </Tag>
                    </TooltipWrapper>
                  </div>
                </Popover> */}
                {isDeactivate && (
                  <TooltipWrapper title={"Deactivate"}>
                    <Popconfirm
                      placement="topLeft"
                      title="Deactivate"
                      description="Are you sure to deactivate All users?"
                      onConfirm={onClickDeactivate}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        className="h-full  default-button bg-white  icon-btn"
                      >
                        <div>
                          <StopOutlined size={17} style={{ color: "red" }} />
                        </div>
                      </Button>
                    </Popconfirm>
                  </TooltipWrapper>
                )}

                {/* <TooltipWrapper title={"Filter"}>
                  <Button
                    style={{ backgroundColor: "white" }}
                    onClick={handleFilter}
                    className="h-full  default-button bg-white  icon-btn"
                  >
                    <Badge
                      dot={filterValues && Object.keys(filterValues).length}
                      overflowCount={9}
                    >
                      <CiFilter size={17} color="#6883fd" />
                    </Badge>
                  </Button>
                </TooltipWrapper> */}
                <TooltipWrapper title="Refresh">
                  <Button
                    style={{ backgroundColor: "white" }}
                    className="h-full  default-button  icon-btn"
                    onClick={handleRefresh}
                  >
                    <IoIosRefresh size={17} color="#6883fd" />
                  </Button>
                </TooltipWrapper>
                <TooltipWrapper title="Download">
                  {items ? (
                    <Dropdown
                      menu={{
                        items,
                        onClick: onClickDownloadMenu,
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        onClick={handleDownload}
                        className="h-full  default-button  icon-btn"
                      >
                        <RiDownloadLine size={17} color="#6883fd" />
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button
                      disabled={!isAllowDownload}
                      style={{ backgroundColor: "white" }}
                      onClick={onClickDownloadMenu}
                      className="h-full  default-button  icon-btn"
                    >
                      <RiDownloadLine size={17} color="#6883fd" />
                    </Button>
                  )}
                </TooltipWrapper>
              </div>
            ) : (
              ""
            )
          }
          placeholder="Search here..."
        />
      </Space.Compact>
    </section>
  );
};

export default CustomInputWithButtons;
