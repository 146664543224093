import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { Button, Checkbox, Divider, Form, Image, Input, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdMailOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import google from "../../assets/img/google-logo.svg";
import {
  actionContinueWithGoogle,
  actionRegister,
} from "../../store/services/authService";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { getStoredUserData } from "../helper/common-function";
import { jwtDecode } from "jwt-decode";

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const data = getStoredUserData();
  const [customLoader, setCustomLoader] = useState(false);
  const [token, setToken] = useState("");
  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });

  useEffect(() => {
    if (state && state.wrongDetails && data) {
      form.setFieldsValue({
        email: data?.email,
      });
      setValue({
        ...value,
        fullPhoneNumber: data?.phone_country_code + data?.phone,
      });
    }
  }, [state && state.wrongDetails]);

  const [isValid, setIsValid] = useState(true);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { registerLoader } = useSelector((state) => state.auth);

  const handleChange = (valid) => {
    setIsValid(valid);
  };

  const onFinish = (values) => {
    const { terms_and_condition, ...data } = values;
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    if (!checked) {
      setError("You must agree to the Terms of Service and Privacy Policy.");
      return false;
    }

    setError("");

    if (valid) {
      setIsValid(true);
      const req = {
        ...data,
        phone: value.phone,
        country_code: value.iso,
      };
      dispatch(actionRegister({ values: req, navigate }));
    } else {
      setIsValid(false);
    }
  };


  const onFinishFailed = ({ values, errorFields }) => {
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    if (!checked) {
      setError("You must agree to the Terms of Service and Privacy Policy.");
      return false;
    }

    setError("");

    if (valid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  // Sign in with google new approch
  const googleLogin = useGoogleLogin({
    cancel_on_tap_outside: false,
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      dispatch(
        actionContinueWithGoogle({
          token: tokenResponse.access_token,
          navigate,
          setCustomLoader,
          user: userInfo.data,
        })
      );
    },
    onError: (errorResponse) => {
      setCustomLoader(false);
    },
    onNonOAuthError: () => {
      setCustomLoader(false);
    },
  });

  useGoogleOneTapLogin({
    cancel_on_tap_outside: false,
    onSuccess: (credentialResponse) => {
      const decoded = jwtDecode(credentialResponse.credential);
      dispatch(
        actionContinueWithGoogle({
          token: credentialResponse.credential,
          navigate,
          setCustomLoader,
          user: decoded,
          isIdToken: true,
        })
      );
    },
    onError: () => {
      console.log("Login Failed");
    },
    onNonOAuthError: () => {
      setCustomLoader(false);
    },
  });

  return (
    <Spin spinning={customLoader}>
      <div className="register-container  relative">
        <Form
          form={form}
          name="normal_login"
          className="login-form w-full pt-5"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="indent-5"
              prefix={
                <MdMailOutline
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value.length < 8) {
                    return Promise.reject(
                      "Please enter a valid 8-digit password!"
                    );
                  }

                  // You can add additional conditions if needed

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Password"
              className="indent-5"
              prefix={
                <HiOutlineLockClosed
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
              type={"text"}
            />
          </Form.Item>

          <PhoneInputComponent
            value={value.fullPhoneNumber}
            onChange={handleChange}
            setIsValid={setIsValid}
            isValid={isValid}
            setValue={setValue}
          />

          <div className={`flex items-center ${!error && "mb-2"}`}>
            <Checkbox
              checked={checked}
              onChange={(e) => {
                setChecked(!checked);
                e.target.checked
                  ? setError("")
                  : setError(
                      "You must agree to the Terms of Service and Privacy Policy."
                    );
              }}
              className="text-sm font-medium"
            >
              I agree to the
            </Checkbox>
            <Link
              to="/terms-and-condition"
              className="mx-1 ml-0 text-primary hover:text-primary/90"
              target="_blank"
            >
              Terms of Service{" "}
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy-policy"
              className="mx-2 text-primary hover:text-primary/90"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </div>

          <div
            className={`flex flex-nowrap mb-2  ${error ? "block " : "hidden"}`}
          >
            <div
              id="normal_login_password_help"
              className="ant-form-item-explain text-[14px] ant-form-item-explain-connected css-dev-only-do-not-override-g9yw9c"
              role="alert"
            >
              <div className="ant-form-item-explain-error text-error">
                {error}
              </div>
            </div>
          </div>
          {/* </Form.Item> */}
          <Form.Item>
            <Button
              loading={registerLoader}
              type="primary"
              className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px]  min-h-[45px]"
              block
              htmlType="submit"
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>

        <p className="text-center">
          Already have an Account?{" "}
          <Link to="/login" className="text-primary">
            Sign in
          </Link>
        </p>

        <Divider className="text-[#fff]">Or</Divider>

        <Button
          loading={customLoader}
          onClick={() => {
            setCustomLoader(true);
            googleLogin();
          }}
          type="primary"
          className="google-btn md:text-base text-sm bg-white hover:bg-white text-black border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px] h-full flex items-center justify-center"
          block
          htmlType="submit"
          icon={
            <Image
              preview={false}
              height={30}
              width={30}
              src={google}
              alt="Google Logo"
            />
          }
        >
          Sign in with Google
        </Button>
      </div>
    </Spin>
  );
};

export default Register;
