import React, { useEffect, useState } from "react";
import { Menu, Layout, Row, Col } from "antd";
// import EventType from "./EventType";
import EventCategory from "./EventCategory";
import EventOnlinePlatforms from "./EventOnlinePlatforms";
import AttendeeStatus from "./AttendeeStatus";
import AttendeeType from "./AttendeeType";
import ExhibitionCategory from "./ExhibitionCategory";
import EventProducts from "./EventProducts";
import EventType from "./EventType";
import { useLocation } from "react-router-dom";

const EventMaster = () => {
  const [selectItem, setSelectItem] = useState("event-type");
  const location = useLocation();
  const { type } = location.state || {};

  useEffect(() => {
    if (type) {
      setSelectItem(type);
    }
  }, [type]);


  const items = [
    {
      label: <h1 className=" text-[15px]">Event Type</h1>,
      key: "event-type",
    },
    {
      label: <h1 className="text-[15px]">Event Category</h1>,
      key: "event-category",
    },
    {
      label: <h1 className="text-[15px]">Event Online Platforms</h1>,
      key: "event-online-platforms",
    },
    {
      label: <h1 className="text-[15px]">Attendee Status</h1>,
      key: "attendee-status",
    },
    {
      label: <h1 className="text-[15px]">Attendee Type</h1>,
      key: "attendee-type",
    },
    {
      label: <h1 className=" text-[15px]">Exhibition Category</h1>,
      key: "exhibition-category",
    },
    {
      label: <h1 className="text-[15px]">Event Products</h1>,
      key: "event-products",
    },
  ];

  const showEventpage = () => {
    switch (selectItem) {
      case "event-type":
        return <EventType />;
      // return <EventType />;

      case "event-category":
        return <EventCategory />;

      case "event-online-platforms":
        return <EventOnlinePlatforms />;

      case "attendee-status":
        return <AttendeeStatus />;

      case "attendee-type":
        return <AttendeeType />;

      case "exhibition-category":
        return <ExhibitionCategory />;

      case "event-products":
        return <EventProducts />;

      default:
        return <h1>This is Default Selected</h1>;
    }
  };
  return (
    <div className="bg-white border border-full border-[#DDDAE9] h-full">
      {/* EventMaster */}
      <Row className="flex bg-white">
        <Col span={5} className=" border-bColor border-r">
          <Layout className="inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-medium pt-[14px]">
              Event Masters
            </h1>
            <Menu
              onSelect={({ key }) => setSelectItem(key)}
              className="pt-2 pb-4 event-master-menus"
              theme={"light"}
              mode="inline"
              selectedKeys={[selectItem]}
              items={items}
            />
          </Layout>
        </Col>
        <Col span={19} className="px-[20px]">
          {showEventpage()}
        </Col>
      </Row>
    </div>
  );
};

export default EventMaster;
