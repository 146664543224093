import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { handleGetResponse, handlePostResponse } from "./commonService";
import { toast } from "sonner";
import {
  extractFilename,
  handleErrorMessage,
} from "../../components/helper/common-function";

// action add Contact
export const actionAddContact = createAsyncThunk(
  "actionAddContact",
  async ({ req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/contact`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/contacts");
        return data;
      } else {
        toast.error(customMsg, 5);
        rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to get Contacts
export const actionGetContacts = createAsyncThunk(
  "actionGetContacts",
  async ({ offset, search, orderByColumn, order }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/contact?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to clear all Contacts
export const actionClearAllContacts = createAsyncThunk(
  "actionClearAllContacts",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

// Action to get All Contacts
export const actionGetAllContacts = createAsyncThunk(
  "actionGetAllContacts",
  async () => {
    return handleGetResponse(`contact?getall=YES`);
  }
);

// Action to get Single Contact data
export const actionGetContactDetail = createAsyncThunk(
  "actionContactDetail",
  async (id) => {
    return handleGetResponse(`contact/${id}`); 
  }
);

// Action to delete Contact
export const actionDeleteContact = createAsyncThunk(
  "actionDeleteContact",
  async ({ id, handleCloseDeleteModal }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/contact/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to Update Contact
export const actionUpdateContact = createAsyncThunk(
  "actionUpdateContact",
  async ({ id, req, navigate }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/contact/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/contacts");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
      return false;
    }
  }
);
